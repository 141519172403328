<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="flex items-center justify-between flex-wrap">
			<div class="title flex items-center">
				<h1>Documents</h1>
			</div>
			<div class="flex items-center justify-center space-x-2">
				<div class="flex justify-end" v-if="viewMode === 'list'">
					<div>
						<button class="flex" :class="documentsDataJSON.length === 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
							Filters
							<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
						</button>
					</div>
				</div>
				<div class="flex flex-end parent-toggle-border rounded p-px">
					<button :class="{ 'active-tab': viewMode === 'tiles', 'inactive-tab': viewMode !== 'tiles' }" class="flex items-center justify-center rounded font-opensans font-bold text-sm leading-3 cursor-pointer w-28 h-8" @click="ClickedMethod('Tiles')">
						<div v-if="viewMode === 'tiles'"><img src="@/assets/TilesWhite.svg" class="mr-1" /></div>
						<div v-else>
							<img src="@/assets/TilesPrimary.svg" class="mr-1" />
						</div>
						Tiles
					</button>
					<button :class="{ 'active-tab': viewMode === 'list', 'inactive-tab': viewMode !== 'list' }" class="flex items-center justify-center rounded font-opensans font-bold text-sm leading-3 cursor-pointer w-28 h-8" @click="ClickedMethod('List')">
						<div v-if="viewMode === 'list'"><img src="@/assets/ListWhite.svg" class="mr-1" /></div>
						<div v-else>
							<img src="@/assets/ListPrimary.svg" class="mr-1" />
						</div>
						List
					</button>
				</div>
			</div>
		</div>
		<p class="headerText mt-3">
			Document management, often referred to as Document Management Systems (DMS), is the use of a computer system and software to store, manage, and track electronic documents and electronic images of paper-based information captured through the use of a document scanner. Document management
			is how your organization stores, manages, and tracks its electronic documents. According to ISO 12651-2, a document is "recorded information or object which can be treated as a unit."
		</p>

		<div v-if="viewMode === 'tiles'" class="my-2">
			<div class="relative">
				<input type="text" placeholder="Search folders and documents" class="inputboxstyling" v-model="searchText" @keyup="findFoldersAndFiles(searchText)" :class="{ 'bg-icon': !searchText }" />
			</div>
		</div>
		<div class="mt-5 bg-white rounded">
			<div v-if="viewMode === 'tiles'">
				<div v-if="dotsLoading" class="mt-2">
					<div class="flex items-center justify-center w-full h-16 mt-4 tableCornerRadius bg-white">
						<dots-loader />
					</div>
				</div>
				<div v-else>
					<div v-if="searchText === '' || searchText === null">
						<div>
							<div v-if="publishedFiles.length !== 0" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
								<div v-for="document in publishedFiles" :key="document" class="p-4 flex flex-col items-center justify-center">
									<div class="relative cursor-pointer" @click="routeToFilesList(document)">
										<!-- <img src="@/assets/FolderIcon.svg" alt="folderImage" /> -->

										<svg width="120" height="97" viewBox="0 0 120 97" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M108 12H54L42 0H12C5.4 0 1.40625e-07 5.4 0 12V36H120V24C120 17.4 114.6 12 108 12Z" fill="#FFA000" />
											<path d="M108 12.0117H12C5.4 12.0117 1.40625e-07 17.4117 0 24.0117V84.0117C0 90.6117 5.4 96.0117 12 96.0117H108C114.6 96.0117 120 90.6117 120 84.0117V24.0117C120 17.4117 114.6 12.0117 108 12.0117Z" fill="#FFCA28" />
											<rect x="88" y="74" width="24" height="14.1667" rx="2" fill="white" />
										</svg>

										<div class="absolute box-container-position white-box z-index-icons">
											<div class="flex items-center justify-center">
												<p class="text-size-ten font-semibold yellow-text text-center">
													{{ document.numberOfFiles }}
												</p>
											</div>
										</div>
									</div>
									<div class="title-container" @mouseenter="toolTipTrigger(document._id, $event)" @mouseleave="pop = false">
										<div class="h-10 w-full text-sm font-semibold m-2 ellipsis-multiline-2 cursor-pointer">
											<p>
												{{ document.title }}
											</p>
										</div>
									</div>

									<div v-if="pop && document._id === hoveredIndex">
										<div class="bg-white absolute z-50 group-hover:opacity-100 rounded tooltipwidth border-lightgrey border pointer-events-none">
											<img src="../assets/tooltip.svg" alt="" class="absolute text-black bottom-full left-0 -mb-1 h-3.5 w-3" />
											<span class="text-black text-center text-xs"></span>
											<div class="px-2">
												{{ document.title }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="h-28 rounded flex items-center justify-center text-xs" v-else>No Records Found</div>
						</div>
					</div>
					<div v-else>
						<div class="search-results" v-if="filteredDocumentsDataJSON.length !== 0">
							<div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
								<div v-for="(item, index) in filteredDocumentsDataJSON" :key="index">
									<!-- <div v-if="item.type === 'file'" class="p-4 flex flex-col items-center justify-center" @click="fileClicked(item)">
									<img src="../assets/fFile.svg" alt="file" class="cursor-pointer" />
									<div class="h-10 w-full text-sm font-semibold text-center m-2 ellipsis-multiline-2 cursor-pointer">
										<p>
											{{ item.name }}
										</p>
									</div>
								</div> -->
									<div class="p-4 flex flex-col items-center justify-center" @click="item.type == 'folder' ? folderClicked(item) : fileClicked(item)">
										<img v-if="item.type === 'folder'" src="../assets/FolderIconPrimary.svg" alt="folder" class="cursor-pointer" />
										<img v-else-if="item.type === 'file'" src="../assets/File.svg" alt="file" class="cursor-pointer" />
										<div class="h-10 w-full text-sm font-semibold text-center m-2 ellipsis-multiline-2 cursor-pointer">
											<p>
												{{ item.name }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="h-28 rounded flex items-center justify-center text-xs" v-else>No Records Found</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="mt-4">
					<newTable :tableRowData="publishedFiles" :headersData="documentDataHeaders" :navigate="true" :documentNavigateId="10001" :fileNavigateId="10002" :loadData="dataLoading"></newTable>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.active-tab {
	background-color: #152a43;
	color: white;
	fill: white !important;
}

.inactive-tab {
	color: black;
	fill: #152a43 !important;
	/* border: 1px solid #e9e9e9; */
}
.parent-toggle-border {
	border: 1px solid #e9e9e9;
}
.text-size-ten {
	font-size: 10px;
	letter-spacing: 1px;
}
.rounded-px {
	border-radius: 1px;
}

.yellow-text {
	color: #ffca28;
}

.white-box {
	width: 20px;
	height: 15px;
}

.z-index-icons {
	z-index: 10;
}
.box-container-position {
	right: 9px;
	bottom: 7.5px;
}

input::placeholder {
	padding-left: 16px;
}

/* Conditional background image styles */
.bg-icon {
	background-image: url('../assets/SearchIcon.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 10px 10px;
	background-position-x: 10px;
}
</style>
<script lang="ts">
import newTable from '../components/newTable.vue';
import dotsLoader from '../components/dotsLoader.vue';
import { defineComponent } from 'vue';
import moment from 'moment';
import { emitter } from '@/main';
import { mapGetters } from 'vuex';

export default defineComponent({
	data(): any {
		return {
			documentsDataJSON: [],
			groupBydocumentsJSON: [],
			filteredDocumentsDataJSON: [],
			documentDataHeaders: ['documentImage', 'title', 'createdDate', 'numberOfFiles'],
			viewMode: 'tiles',
			pop: false,
			hoveredIndex: null,
			searchText: '',
			publishedFiles: [],
			allDocumentsData: [],
			showFilters: false,
			dataLoading:true,
		};
	},
	computed: {
		...mapGetters({ dotsLoading: 'dotsLoading', userInfo: 'userInfo' })
	},
	components: {
		newTable,
		dotsLoader
	},
	async mounted() {
		this.dataLoading = true;
		// this.$store.dispatch('updateDotsLoading', true);
		await this.getAllDocumentsList();
		await this.getPublishedFiles();
		this.dataLoading = false;
	},
	methods: {
		ClickedMethod(data: any) {
            if (data == 'Tiles') {
                this.viewMode = 'tiles';
                this.showFilters = false;
                this.searchText=''
 
            }
            if(data=='List'){
                this.viewMode = 'list'
                this.showFilters = false
                this.searchText=''
            }
        },
		toolTipTrigger(id: any, elementEvent: any) {
			this.hoveredIndex = id;
			this.handleTextTooltip(elementEvent);
		},
		isTextOverflowed(element: any) {
			return element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight;
		},
		handleTextTooltip(e: any) {
			let container = e.currentTarget.querySelector('.ellipsis-multiline-2'); // Target the container with ellipsis class
			if (container && this.isTextOverflowed(container)) {
				this.pop = true;
			} else {
				this.pop = false;
			}
		},
		routeToFilesList(data: any) {
			this.$store.state.filterText = this.searchText;
			// emitter.emit('searchTextForDocuments',this.searchText);
			localStorage.setItem('DOCUMENT_DATA', JSON.stringify(data));
			this.$router.push({ name: 'fileslist' });
		},
		folderClicked(data: any) {
			let docJsonData = this.publishedFiles.find((json: any) => {
				return json.title == data.name;
			});
			this.$store.state.filterText = this.searchText;
			// emitter.emit('searchTextForDocuments',this.searchText);
			localStorage.setItem('DOCUMENT_DATA', JSON.stringify(docJsonData));
			this.$router.push({ name: 'fileslist' });
		},
		fileClicked(data: any) {
			this.$router.push({ name: 'editor', params: { documentId: data.id, type: 'fileslist' } });
		},
		findFoldersAndFiles(searchText: string) {
			if (!searchText.trim()) {
				this.filteredDocumentsDataJSON = [];
				return;
			}
			const lowerCaseSearch = searchText.trim().toLowerCase();

			let matchedFolders: any = [];
			let matchedFiles: any = [];
			this.publishedFiles.forEach((folder: any) => {
				const folderMatch = folder.title.toLowerCase().includes(lowerCaseSearch);
				const filteredFiles = folder.files.filter((file: any) => file.documentName.toLowerCase().includes(lowerCaseSearch));

				if (folderMatch) {
					matchedFolders.push(folder);
				}
				if (filteredFiles.length > 0) {
					matchedFiles.push(...filteredFiles);
				}
			});
			// Initialize an empty array to store formatted items
			let formattedItems: any[] = [];

			// Process matched folders

			matchedFolders
				.sort((a: any, b: any) => {
					// Parse dates using moment.js to compare
					const dateA: any = moment(a.date, 'DD-MM-YYYY');
					const dateB: any = moment(b.date, 'DD-MM-YYYY');

					// Compare the dates
					if (dateA.isBefore(dateB)) {
						return -1;
					} else if (dateA.isAfter(dateB)) {
						return 1;
					} else {
						return 0;
					}
				})
				.forEach((folder: any) => {
					const date = moment(folder.createdDate, 'DD-MM-YYYY').format('DD-MM-YYYY'); // Format date using Moment.js
					formattedItems.push({
						type: 'folder',
						// id: folder.id,
						name: folder.title,
						date: date
					});
				});

			// Process matched files
			matchedFiles
				.sort((a: any, b: any) => {
					// Parse dates using moment.js to compare
					const dateA: any = moment(a.date, 'DD-MM-YYYY');
					const dateB: any = moment(b.date, 'DD-MM-YYYY');

					// Compare the dates (sorted in descending order)
					if (dateA.isBefore(dateB)) {
						return -1;
					} else if (dateA.isAfter(dateB)) {
						return 1;
					} else {
						return 0;
					}
				})
				.forEach((file: any) => {
					const date = moment(file.publishedDate, 'DD-MM-YYYY').format('DD-MM-YYYY'); // Format date using Moment.js
					formattedItems.push({
						id: file._id,
						type: 'file',
						name: file.documentName,
						date: date // Store formatted date
					});
				});

			this.filteredDocumentsDataJSON = formattedItems;
		},
		changeDateFormat(date: any) {
			if (date.includes('-')) {
				let dateParts;
				if (date.includes('T')) {
					dateParts = date.split('T')[0].split('-');
				} else {
					dateParts = date.split('-');
				}
				
				if (dateParts.length === 6) {
					let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
					let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
					return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
					return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},

		groupBy(arr: any[], key: string | number) {
			return arr.reduce((group: any, product: any) => {
				const { folder } = product;
				group[folder] = group[folder] ?? [];
				group[folder].push(product);
				return group;
			}, {});
		},
		async getPublishedFiles() {
			this.documentsDataJSON.filter((data: any) => {
				if (data.files[0].statusCode != 10412) {
					data.createdDate = this.changeDateFormat(data.createdDate)
					this.publishedFiles.push(data);
				}
			});
		},
		async getAllDocumentsList() {
			this.dataLoading = true;
			this.allDocumentsData = [];
			try {
				const res = await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/publisheddocuments/getAll`);
				// const filteredDocs = res.data.filter((doc: any) => 
				// this.userInfo.currentRole === 10207 || 
				// (this.userInfo.currentRole === 10208 && doc.author === this.userInfo._id)
				// );

				let filteredDocs :any = []
				res.data.forEach((eachDoc: any) => {
					if (this.userInfo.currentRole == 10207 && eachDoc.statusCode != 10412) {
						filteredDocs.push(eachDoc);
					} else if (this.userInfo.currentRole == 10208 && eachDoc.author == this.userInfo._id && eachDoc.statusCode != 10412) {
						filteredDocs.push(eachDoc);
					}
				});
				this.allDocumentsData.push(...filteredDocs);

				const groupedDocuments = this.groupBy(this.allDocumentsData);
				this.groupBydocumentsJSON = groupedDocuments;

				this.documentsDataJSON = Object.values(groupedDocuments).map((eachArr: any) => {
					const { folder, createdAt } = eachArr[0];
					return {
						title: folder,
						numberOfFiles: eachArr.length,
						createdDate: createdAt,
						files: eachArr.map(({ folder, ...fileObj }: any) => fileObj)
					};
				});
				
				this.dataLoading = false;
				// this.$store.dispatch('updateDotsLoading', false);
			} catch (error) {
				this.dataLoading = false;
				// this.$store.dispatch('updateDotsLoading', false);
			}
			},
		// async getAllDocumentsList() {
		// 	this.allDocumentsData = [];
		// 	await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/documents/getAll`).then((res: any) => {
		// 		res.data.forEach((eachDoc: any) => {
		// 			if (this.userInfo.currentRole == 10207) {
		// 				this.allDocumentsData.push(eachDoc);
		// 			} else if (this.userInfo.currentRole == 10208 && eachDoc.author == this.userInfo._id) {
		// 				this.allDocumentsData.push(eachDoc);
		// 			}
		// 		});
		// 		const groupedDocuments = this.groupBy(this.allDocumentsData);
		// 		this.groupBydocumentsJSON = groupedDocuments;

		// 		const documentsArrays = Object.values(groupedDocuments);
		// 		console.table(groupedDocuments);
		// 		let finalArray: any = [];
		// 		documentsArrays.forEach((eachArr: any) => {
		// 			let obj: any = {};
		// 			eachArr.map((eachObj: any) => {
		// 				console.log("eachObj1111111111111111111111", eachObj)
		// 				obj = {
		// 					title: eachObj.folder,
		// 					numberOfFiles: eachArr.length,
		// 					createdDate: eachObj.createdAt,
		// 					files: eachArr.map((eachObj: any) => {
		// 						// Exclude folder name from each file object
		// 						let fileObj: any = { ...eachObj }; // Copy eachObj
		// 						delete fileObj.folder;
		// 						return fileObj;
		// 					})
		// 				};
		// 			});
		// 			finalArray.push(obj);
		// 		});
		// 		console.table(finalArray);
		// 		this.documentsDataJSON = finalArray;
		// 		this.$store.dispatch('updateDotsLoading', false);
		// 		console.table(this.documentsDataJSON);
		// 	});
		// },
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		}
	}
});
</script>
