import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4" }
const _hoisted_2 = { class: "w-4/5" }
const _hoisted_3 = { class: "card mt-4 m-4" }
const _hoisted_4 = { class: "mx-2.5 mb-6" }
const _hoisted_5 = { class: "mt-5 mx-5 pt-5 flex flex-row borderBottom overflow-hidden justify-between cursor-pointer font-semibold" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabHead, (tab, tabIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "pb-1 adjust",
                key: tabIndex,
                onClick: ($event: any) => (_ctx.toggleTabs(tab))
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    ref_for: true,
                    ref: "actualIndex"
                  }, _toDisplayString(tab.displayName), 513),
                  _createElementVNode("div", {
                    class: _normalizeClass([{ progress: _ctx.selectedTabIndex == tab.id }, "h-1 p-0.75"])
                  }, null, 2)
                ])
              ], 8, _hoisted_6))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.selectedTabObj.content), 1)
      ])
    ])
  ]))
}