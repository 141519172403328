import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "../src/index.css";
import Vue from "vue"
import mitt from 'mitt'
import axios from "axios";

// import "../node_modules/@vueform/multiselect/themes/default.css"
import "vue-multiselect/dist/vue-multiselect.css"
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";


import { useToast } from "vue-toastification";
export const toast = useToast();
export const emitter = mitt();

const instance = axios.create({
    withCredentials: true,
});
  
instance.interceptors.request.use(function(config:any) {
    const token = localStorage.getItem('AUTH_ID');
    console.log('token available setting in header');
    if(token) {
        // config.headers.common["Authorization"] = 'Bearer ' + token;  
        config.headers.Authorization  = 'Bearer ' + token;  
    }
    return config;
}, function(err:any) {
    return Promise.reject(err);
});
const app = createApp(App)
app.config.globalProperties.$http = instance;
app.use(store).use(router).use(Toast).mount("#app");
