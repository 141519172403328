<template>
	<div class="rootContainer" v-if="!loading">
		<component v-bind:is="layout">
	 <router-view />
		</component>
	</div>
	<div v-else class="fixed left-2/4 top-2/4" >
		<div>
		<!-- <spinner/> -->
		<dots-loader/>
		</div>
	</div>
</template>
<style  scoped>
.rootContainer {
	background-color: #F9F9FB;
	height: 100vh;
	overflow-y: hidden;
}
</style>
<script lang="ts">
import emptylayout from '@/layouts/emptyLayout.vue';
import dotsLoader from '@/components/dotsLoader.vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		emptylayout,
		dotsLoader
	},
	computed: {
		layout() {
			return this.$route.meta.layout || emptylayout;
		},
		...mapGetters({ loading: 'loading' })
	}
});
</script>