<template>
  <div>
    <div ref="editorContainer" class="editor-container mb-2"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { emitter } from '../main';

export default defineComponent({
  name: 'QuillEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: ''
    }
  },
  data() {
    return {
      quill: null,
      content: '',
      dummyContent: ''
    };
  },
  mounted() {
    this.initializeQuill();
    this.insertPdf(this.data);
  },
  methods: {
    disableStatus(){
        let disableStatusCondition;
        if (this.content == this.dummyContent) {
            disableStatusCondition = false;
        } else {
            disableStatusCondition = true;
        }
        return disableStatusCondition;
    },
    initializeQuill() {
      this.quill = new Quill(this.$refs.editorContainer, {
        theme: 'snow',
        readOnly: true,
        modules: {
          toolbar: false
        }
      });
      
      this.quill.on('text-change', () => {
        this.content = this.quill.root.innerHTML;
        this.$emit('input', this.content);
      });
    },
    saveQuillData(){
      let addedComments = {
            content: this.content
        }
        emitter.emit('addedComments', addedComments);
    },
    insertPdf(val) {
      if(val && val.content){
        this.quill.root.innerHTML = val.content
        this.dummyContent = val.content
      } else {
        this.quill.root.innerHTML = `<p style="display:flex; justify-content:center">No Data found</p>`
      }
    }
  },
  watch: {
    value(val) {
      if (val !== this.quill.root.innerHTML) {
        this.quill.root.innerHTML = val;
      }
    },
    data(val) {
      this.insertPdf(val);
    }
  }
});
</script>

<style scoped>
.editor-container {
  position: relative;
  /* width: 90px !important; */
}
object .middleDivWidth{
  width: 100px !important;
}
.pdf {
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* .middleDivWidth {
	width: 70%;
} */
.editor-container {
	width: 100%;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #E9E9E9;
	height: calc(98vh - 150px);
}
.borderBottomStyle {
	border-bottom: 1px solid #e9e9e9;
}
.rounded {
  border-radius: 8px;
}
</style>
