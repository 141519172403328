<template>
  <div class="flex justify-end pl-4 py-1.5 border-b borderBottomStyle mb-2">
    <button :disabled="clickOnButton"  class="btnprimary cursor-pointer" :class="disableStatus() ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="saveQuillData()">Save</button>
  </div>
  <div>
    <!-- Display Quill editor and PDF viewer -->
    <div ref="editorContainer" class="editor-container mb-2"></div>
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { emitter } from '../main';

export default defineComponent({
  name: 'QuillEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    data: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      quill: null,
      content: '',
      dummyContent: '',
      clickOnButton : false
    };
  },
  mounted() {
    this.initializeQuill();
    this.insertPdf(this.data);
  },
  methods: {
    disableStatus(){
        let disableStatusCondition;
        if (this.content == this.dummyContent) {
            disableStatusCondition = false;
        } else {
            disableStatusCondition = true;
        }
        return disableStatusCondition;
    },
    initializeQuill() {
      this.quill = new Quill(this.$refs.editorContainer, {
        theme: 'snow',
        placeholder: 'Compose an epic...',
        modules: {
          toolbar: [
            [{ 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        }
      });
      
      this.quill.on('text-change', () => {
        this.content = this.quill.root.innerHTML;
        this.$emit('input', this.content);
      });
    },
    async saveQuillData(){
      this.clickOnButton = true
      try {
        let addedComments = {
            content: this.content
        }
        emitter.emit('addedComments', addedComments);
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.clickOnButton = false
      } catch (error) {
        this.clickOnButton = false;
        console.error('Error saving data:', error);
      }
    },
    insertPdf(val) {
      if (val) {
        // console.log("val", val)
        // this.quill.root.innerHTML = val[val.length - 1].content
        // this.dummyContent = val[val.length - 1].content
        this.quill.root.innerHTML = val
        this.dummyContent = val
        
      } else{
        this.quill.root.innerHTML = '<p><br></p>'
      }
    }
  },
  watch: {
    value(val) {
      if (val !== this.quill.root.innerHTML) {
        this.quill.root.innerHTML = val;
      }
    },
    data(val) {
      // console.log("vallllllllllllllllllllllll", val)
      
      this.insertPdf(val);
    }
  }
});
</script>

<style scoped>
.editor-container {
  position: relative;
  cursor: pointer;
  /* width: 90px !important; */
}
object .middleDivWidth{
  width: 100px !important;
}
.pdf {
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* .middleDivWidth {
	width: 70%;
} */
 .editor-container {
	width: 100%;
	height: 50vh;
}
.borderBottomStyle {
	border-bottom: 1px solid #e9e9e9;
}
.rounded {
  border-radius: 8px;
}
</style>
