<template>
	<div class="pl-5 pt-3 pr-5">
		<div class="flex justify-between items-center h-8">
			<div class="title flex items-center">
				<h1>Archive Documents</h1>
			</div>
			<div class="flex items-center">
				<button class="flex" :class="getAllArchiveDocumentsJson.length === 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<p class="mt-3 headerText">
			Document management. Often referred too as Document Management Systems (DMS), is the use Of a computer system and software to store. manage and track electronic documents and electronic images of paper-based information captured through the use of a document scanner. Document management
			is how your organization stores. manages, and tracks its electronic documents. According to ISO 12651-2, a document is "recorded information or object which can be treated as a unit."
		</p>

		<newTable :tableRowData="getAllArchiveDocumentsJson" :headersData="documentHeaders" :openPopup="true" :loadData="dataLoading"></newTable>
	</div>
</template>

<script lang="ts">
import newTable from '@/components/newTable.vue';
import { defineComponent } from 'vue';
import { emitter } from '@/main';
import { mapGetters } from 'vuex';

export default defineComponent({
	data(): any {
		return {
			documentHeaders: ['documentId', 'documentName', 'folder','version', 'classification', 'type'],
			showFilters: false,
			rowData: '',
			getAllArchiveDocumentsJson: [],
			placeholder: 'Select a File',
			showPopUp: false,
			dataLoading: true,
			newDocumentObj: {
				documentId: '',
				documentName: '',
				classification: '',
				version: '',
				type: '',
				statusCode: '',
				documentOwner: '',
				file: null,
				folder: '',
				reviewPeriod: '',
				author: ''
			},
			dummy: {
				documentId: '',
				documentName: '',
				classification: '',
				version: '',
				type: '',
				statusCode: '',
				documentOwner: '',
				file: null,
				folder: '',
				reviewPeriod: '',
				author: ''
			},
			reviewPeriodSelect: [
				{
					id: 1,
					status: 'Quarterly'
				},
				{
					id: 2,
					status: 'Half Yearly'
				},
				{
					id: 3,
					status: 'Yearly'
				}
			],
			docTypeData: [
				{
					id: 1,
					value: 'Policies'
				},
				{
					id: 2,
					value: 'Procedures'
				},
				{
					id: 3,
					value: 'Standards'
				},
				{
					id: 4,
					value: 'Guidelines'
				}
			],
			classificationData: [
				{
					id: 1,
					value: 'Public'
				},
				{
					id: 2,
					value: 'Internal'
				},
				{
					id: 3,
					value: 'Confidential'
				},
				{
					id: 4,
					value: 'Highly Confidential'
				},
				{
					id: 5,
					value: 'Sensitive'
				}
			],
			optionsUserArray: [],
			foldersDataArray: []
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	components: {
		newTable
	},
	async mounted() {
		this.dataLoading = true;
		// this.$store.dispatch('updateDotsLoading', true);
		await this.getAllArchiveDocumentsList();
		this.dataLoading = false;
		emitter.off('showData123');
		emitter.on('showData123', (data: any) => {
			let comingId = data.entityData._id;
			this.rowData = data;
			this.popupMethod(comingId);
		});
	},
	methods: {
		filteredCategories(filterColumn: any, status: any) {
			this.foldersDataArray = [
				...new Set(
					Object.values(this.allDocumentsData).map((x: any) => {
						x[status];
						return x[status];
					})
				)
			].filter((category: any) => {
				return category.toLowerCase().includes(filterColumn.toLowerCase());
			});
		},
		getName(options: any, status: any) {
			let s = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
			this.filteredCategories(options, s);
			this.setState(options, s);
		},
		async getallTeamMembers() {
			this.allUserArray = [];
			this.optionsUserArray = [];
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
				this.orgUsersList = this.orgUsersList.filter((user: any) => {
					return user.isActive == true;
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
					let obj: any = { label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id };
					if (this.orgUsersList[i]._id !== this.userInfo._id) {
						this.optionsUserArray.push(obj);
					}
					this.allUserArray.push(obj);
				}
			});
		},
		popupMethod(dataId: any) {
			this.$router.push({ name: 'editor', params: { documentId: dataId, type: 'archivedocuments' } });
		},

		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		onCancelButtonAddPopUp(status: any) {
			if (status == 'close') {
				delete this.newDocumentObj._id;
				delete this.dummy._id;
				this.showPopUp = false;
				Object.keys(this.newDocumentObj).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.newDocumentObj[prop]) ? (this.newDocumentObj[prop] = []) : (this.newDocumentObj[prop] = '');
					}
				});
				Object.keys(this.dummy).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.dummy[prop]) ? (this.dummy[prop] = []) : (this.dummy[prop] = '');
					}
				});
			} else if (status == 'edit') {
				this.newDocumentObj = { ...this.dummy };
			} else if (status == 'add') {
				delete this.newDocumentObj._id;
				delete this.dummy._id;
				Object.keys(this.newDocumentObj).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.newDocumentObj[prop]) ? (this.newDocumentObj[prop] = []) : (this.newDocumentObj[prop] = '');
					}
				});
				Object.keys(this.dummy).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.dummy[prop]) ? (this.dummy[prop] = []) : (this.dummy[prop] = '');
					}
				});
				// this.dummy = {};
			}
		},
		async getAllArchiveDocumentsList() {
			this.dataLoading = true;
			this.getAllArchiveDocumentsJson = [];
            await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/archivedocuments/getall`).then((res: any) => {
                // this.getAllArchiveDocumentsJson = res.data;
                res.data.forEach((eachDoc: any)=>{
                    console.log("userInfo", this.userInfo)
                    if(this.userInfo.currentRole == 10207){
                        console.log("this.userInfo -------- IN")
                        this.getAllArchiveDocumentsJson.push(eachDoc);
                    } else if(this.userInfo.currentRole == 10208 && eachDoc.author == this.userInfo._id){
                        console.log("this.userInfo -------- OUT")
                        this.getAllArchiveDocumentsJson.push(eachDoc);
                    }
                })
                this.$store.dispatch('updateDotsLoading', false);
				this.dataLoading = false;
            });

		}
	}
});
</script>
<style scoped>
.btnprimary1 {
	cursor: pointer;
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-family: Open Sans;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.placeholder-styles {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
}
</style>