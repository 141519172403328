<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="flex flex-col">
			<div>
				<div class="mb-2">
					<breadCrumb :routesArray="routesArray" />
				</div>
				<div>
					<div class="flex justify-between items-center mt-2">
						<div class="flex items-center gap-5">
							<div class="font-bold" :class="titleLength > 40? 'w-72 table-ellipsis':''">{{ presentDocumentData.documentName }}</div>
							<div class="flex items-center gap-4">
								<!-- <div v-if="presentDocumentData.classification">
                                    <div>
                                        {{
                                            classificationData.find((l) => {
                                                return l.id == presentDocumentData.classification;
                                            }).value
                                        }}
                                    </div>
                                </div> -->
								<div v-if="presentDocumentData.classification">
									<div class="flex items-center justify-center relative rounded w-24 h-5 regular" :class="colorTextClasificationStatus(presentDocumentData.classification)">
										<div class="w-full h-full absolute opacity-10 rounded" :class="colorBgClasificationStatus(presentDocumentData.classification)"></div>
										<div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
											<div :class="presentDocumentData.classification == 4 ? 'highlyConfidentialStyling' : 'text-xs'">
												{{
													classificationData.find((l) => {
														return l.id == presentDocumentData.classification;
													}).value
												}}
											</div>
										</div>
									</div>
								</div>
								<div v-if="presentDocumentData.statusCode && $route.params.type != 'archivedocuments' && $route.params.type != 'fileslist'">
									<div class="flex items-center justify-center relative rounded w-24 px-2.5 h-5 regular" :class="colorTextRequestStatus(presentDocumentData.statusCode)">
										<div class="w-full h-full absolute opacity-10 rounded" :class="colorBgRequestStatus(presentDocumentData.statusCode)"></div>
										<div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
											<div class="text-xs">
												{{
													docStatusValues.find((l) => {
														return l.id == presentDocumentData.statusCode;
													}).value
												}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex">
							<div v-if="routeRoleBased">
								<button class="rounded font-opensans font-bold text-sm px-2.5 py-2.5 leading-3 cursor-pointer text-white mr-2" :class="approveRejectBtns == true ? 'approvebtn' : 'btndisabled pointer-events-none'" @click="approveOrReject(10408)">Approve</button>
								<button class="rounded font-opensans font-bold text-sm px-2.5 py-2.5 leading-3 cursor-pointer text-white" :class="approveRejectBtns == true ? 'rejectbtn' : 'btndisabled pointer-events-none'" @click="approveOrReject(10410)">Reject</button>
							</div>
							<button v-if="$route.params.type == 'myDocuments' && authorRoleBased == true && (presentDocumentData.statusCode === 10408 || presentDocumentData.statusCode === 10413)" class="btnprimary ml-2 flex items-center" @click="showPopupByReversion">Reversion</button>
							<button
								v-if="authorRoleBased == true && $route.params.type === 'myDocuments' && presentDocumentData.statusCode === 10408"
								class="ml-4"
								:class="publish ? 'btnprimary rounded font-opensans font-bold text-sm px-2.5 py-2.5 leading-3 cursor-pointer text-white' : 'btndisabled pointer-events-none'"
								@click="publishAction()"
							>
								Publish
							</button>
						</div>
					</div>
					
				</div>
				<div class="headerText mt-3">
					Document management, often referred to as Document Management Systems (DMS), is the use of a computer system and software to store, manage and track electronic documents and electronic images of paper-based information captured through the use of a document
				</div>
			</div>

			
            <popup v-if="approveAndRejectPopUp" :popUp="approveAndRejectPopUp">
                <template v-slot:header>
                    <div class="flex items-center justify-between py-4 px-2 rounded">
                        <h1 class="pl-2 w-72" :class="titleLength > 40? 'table-ellipsis':''">{{ presentDocumentData.documentName }}</h1>
                        <button class="float-right text-3xl" @click="CancelButtonPermissionPopUp('close')">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>
                <fieldset>
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label class="controllabel text-xs">Justification by Approver</label>
                                            <textarea class="inputboxstyling h-50 mt-2" v-model.trim="justification" placeholder="Justification"  @input ="restrictCharacters('approval')"  :class="{'is-invalid': v$.justification.$error || displayErrorMessage == true }"/>
											<!-- <div v-if="v$.justification.$invalid" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.justification.maxLength.$invalid">Comments length exceeded</div>
											</div> -->
											<div v-if="displayErrorMessage == true">
												<div class="text-red-500 text-xs">Character Limit is exceeded</div>
											</div>
                                        </div>
                                    </div>
                                </div>
 
                                <div class="formcontainer flex flex-col">
                                    <div class="buttonposition flex justify-center items-center mt-4 mx-0">
                                        <button :disabled="clickOnButton" class="btnprimary mr-1.5" :class="{ 'btndisabled pointer-events-none': justification === '' || justification === null }" @click="onCancelbtn()">Cancel</button>
                                        <button :disabled="clickOnButton" class="btnprimary" :class="{ 'btndisabled pointer-events-none': justification === '' || justification === null }" @click="saveApproveAndRejectData(statusCode)">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </popup>
			<div class="w-full">
				<div class="centerContainer flex flex-row justify-between space-x-2 mt-5">
					<div :class="!showComments && !showControls && !showDocumentInfo && !showWorkFlow ? 'w-full' : 'middleDivWidth'" class="px-2 text-sm middleDiv relative">
						<!-- <br /> -->
						<div class="group-buttons mt-2.5" v-if="isSmallScreen && !($route.params.type == 'fileslist' || $route.params.type == 'archivedocuments')">
							<div class="absolute left-0 cursor-pointer top-3" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
								<img src="@/assets/leftArrowFilled.svg" class="arrow left-0" style="width: 16px;margin-left: 3px;top: 13px;"/>
							</div>
							<div class="absolute right-0 cursor-pointer top-3" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === tabHead.length || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
								<img src="@/assets/rightArrowFilled.svg" class="arrow right-0"  style="width: 16px;margin-right: 3px;top: 13px;"/>
							</div>
						</div>
						<div class="group-buttons mt-2.5" v-else-if="!($route.params.type == 'fileslist' || $route.params.type == 'archivedocuments')">
							<!-- Scroll buttons for larger screens -->
							<div class="absolute left-0 cursor-pointer top-3" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
								<img src="@/assets/leftArrowFilled.svg" class="arrow left-0"  style="width: 16px;margin-left: 3px;top: 13px;"/>
							</div>
							<div class="absolute right-0 cursor-pointer top-3" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
								<img src="@/assets/rightArrowFilled.svg" class="arrow right-0"  style="width: 16px;margin-right: 3px;top: 13px;"/>
							</div>
						</div>
						<div>
							<div v-if="$route.params.type == 'fileslist' || $route.params.type == 'archivedocuments'" class="pb-1 adjust"></div>
							<div v-else class="mx-4 flex flex-row borderBottom justify-between cursor-pointer tab-container" ref="tabContainer">
								<div class="pb-1"  :class="selectedTabIndex == tab.id ? 'adjust font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in visibleTabs" :key="tabIndex" @click="toggleTabs(tab)">
									<div>
										<div :class="{ 'text-blue': selectedTabIndex === tab.id }" ref="actualIndex">
											{{ tab.displayName }}
										</div>
										<div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75" style="z-index: 1"></div>
									</div>
								</div>
							</div>
							<div v-if="$route.params.type != 'fileslist' && $route.params.type != 'archivedocuments'" class="progressbarB rounded-sm ml-5 mr-5 relative h-1 mx-2.5"></div>
						</div>
						<div>
							<div v-if="selectedTabIndex == 1">
								<div v-if="renderUrl != ''" class="flex justify-center">
									<div class="w-full mt-2 px-2" :class="docPerviewStyles() ? 'fileMiddleDivScroll': 'docMiddleDivScroll'">
										<object class="pdf middleDivWidth rounded" :data="renderUrl"></object>
									</div>
								</div>
								<div class="p-2" v-else>
									<div :class="showFullToolbar == true ? '': 'card px-4 heightOfInnerdiv overflow-scroll no-scrollbar'">
										<div class="approvalHeader justify-between flex items-center">
											<div class="flex justify-center w-full">
												<div class="w-full" :class="docPerviewStyles() ? 'fileMiddleDivScroll px-2': 'docMiddleDivScroll'">
													<!-- <docmentEditor :data='renderUrl'/> -->
													<readOnlyDocumentEditor v-if="showFullToolbar == true" :data='currentDocument'/>
													<docmentEditor v-else :data='currentDocument.content'/>												
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="selectedTabIndex == 2" class="mx-2">
								<div class="card previousDocStyle w-full mt-2 px-4 heightOfInnerdiv overflow-scroll no-scrollbar">
									<div v-if="previousDocuments != [] && previousDocuments.length == 0" class="absolute top-2/4 alignmentDocument">
										<p class="text-black">No documents found</p>
									</div>
									<div v-else class="flex mt-4 gap-4 flex-wrap cursor-pointer">
										<div v-for="doc in previousDocuments" :key="doc">
											<div :class="doc.url || doc.content ? 'pdf w-full rounded-sm' : ''">
												<div v-if="doc.url">
													<a @click="clickedFileUrl(doc.url, doc.versionId, 'clickHere')" download="document" class="flex w-full flex-row items-center" >
														<img src="@/assets/pdfs.svg" alt=""/>
														<p class="text-xs ml-1">{{ doc.docVersion || 'Document' }}</p>
													</a>
												</div>
												<div v-else-if="doc.content">
													<div class="flex w-full flex-row items-center" @click="downloadHtmlAsPdf(doc.content, doc.docVersion, 'base64')">
														<img src="@/assets/pdfs.svg" alt=""/>
														<p class="text-xs ml-1">{{ doc.docVersion || 'Document' }}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- <div v-if="renderUrl != ''" class="flex justify-center">
									<div class="w-full mt-2 px-2" :class="docPerviewStyles() ? 'fileMiddleDivScroll': 'docMiddleDivScroll'">
										<object class="pdf middleDivWidth rounded" :data="renderUrl"></object>
									</div>
								</div>
								<div class="p-2" v-else>
									<div class="card">
										<div class="approvalHeader justify-between flex items-center">
											<div class="flex justify-center w-full">
												<div class="w-full px-2" :class="docPerviewStyles() ? 'fileMiddleDivScroll mt-2': 'docMiddleDivScroll'">
													<docmentEditor :data='renderUrl'/>
													<readOnlyDocumentEditor v-if="showFullToolbar == true" :data='presentDocumentData.documentUrl[this.presentDocumentData.length - 1].content'/>
													<docmentEditor v-else :data='presentDocumentData.documentUrl'/>
												</div>
											</div>
										</div>
									</div>
								</div> -->
							</div>
							
							<div v-if="selectedTabIndex == 3">
								<div class="p-2">
									<div class="card heightOfInnerdiv overflow-scroll no-scrollbar">
										<div>
											<div class="approvalHeader justify-between flex items-center border-b mx-2 borderBottomStyle">
												<!-- <div class="font-bold py-2"><b class="mr-6">Document ID</b><b>Name Of The Document</b></div> -->
												<div></div>
												<div class="flex justify-end pl-4 py-1.5">
													<div>
														<button :class="presentDocumentData.statusCode != 10411 || authorRoleBased === false ? 'btndisabled disabledTaskEditButtons pointer-events-none' : 'btnprimary taskEditButtons'" class="btnprimary cursor-pointer" @click="showApproval()">Send Approval</button>
													</div>
													<popup v-if="showApprovalPopUp" :popUp="showApprovalPopUp">
														<template v-slot:header>
															<div class="flex items-center justify-between py-4 px-2 rounded">
																<h1 class="pl-2">For Approval</h1>
																<button class="float-right text-3xl" @click="onCancelButtonAddPopUp('close')">
																	<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
																</button>
															</div>
														</template>
														<fieldset>
															<div class="p-4">
																<div class="w-full pb-2">
																	<label class="controllabel">Owner</label>
																	<div class="mt-2">
																	<Multiselect
																		v-model="approvalComment.owner"
																		:options="optionsUsersList"
																		:multiple="false"
																		:close-on-select="true"
																		:clear-on-select="true"
																		label="label"
																		track-by="value"
																		:searchable="true"
																		:hideArrow="false"
																		placeholder="Owner"
																		deselectLabel=""
																		selectLabel=""
																		class="custom-multiselect"
																		:class="{
																			'has-options': optionsUsersList && optionsUsersList.length > 0,
																			'no-options':  !optionsUsersList.length
																		}"
																	>
																		<template v-slot:noResult>
																			<span class="text-xs">No Results Found</span>
																		</template>
																	</Multiselect>
																</div>
																	<!-- <Multiselect
																		v-model="approvalComment.owner"
																		:options="optionsUserArray"
																		:searchable="true"
																		:minChars="1"
																		:filterResults="true"
																		class="multiselect-custom, inputboxstyling"
																		:classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
																		:canClear="false"
																		:caret="false"
																		placeholder="Owner"
																	></Multiselect> -->
																	<!-- <dropdown :dataprops="optionsUserArray" :inputValue="approvalComment.owner" :placeholderValue="'Owner'" @name="getName" ></dropdown> -->
																</div>
																<div class="w-full py-2">
																	<label class="controllabel">Approval Comment</label>
																	<textarea class="inputboxstyling mt-2" v-model.trim="approvalComment.comments" placeholder="Comment" @input="restrictCharacters('request')" :class="{'is-invalid': v$.approvalComment.comments.$error || displayErrorMessage == true}"></textarea>
																	<!-- <div v-if="v$.approvalComment.comments.$invalid" class="text-red-500"> -->
																		<!-- <div class="error-text text-center text-xs" v-if="v$.approvalComment.comments.required.$invalid">Required</div> -->
																		<!-- <div class="error-text text-xs" v-if="v$.approvalComment.comments.maxLength.$invalid">Comments length exceeded</div> -->
																	<!-- </div> -->
																	<div v-if="displayErrorMessage == true">
																		<div class="text-red-500 text-xs">Character Limit is exceeded</div>
																	</div>
																</div>
															</div>
														</fieldset>
														<div class="flex justify-center items-between mt-4">
															<!-- <button @click="resetComments()" >Reset</button>
                        									<button @click="saveComments()" >Save</button> -->
															<button :disabled="clickOnButton"  @click="cancelComments" class="mr-1.5" :class="approvalComment.comments == '' && approvalComment.owner == '' ? 'btndisabled pointer-events-none' : 'btnprimary'">Cancel</button>
															<button :disabled="clickOnButton"  @click="saveComments" class="btnprimary" :class="approvalComment.comments == '' && approvalComment.owner == '' ? 'btndisabled pointer-events-none' : 'btnprimary'">Save</button>
														</div>
													</popup>
												</div>
											</div>
										</div>

										<div class="mx-3 approvalScroll">
											<div v-for="(item, index) in Approvals" :key="index" class="py-4 px-2 borderBottomStyle">
												<div class="flex">
													<div class="rounded-full h-8 w-8 flex items-center justify-center cursor-pointer bgColorAndColor">
														<span class="font-bold text-sm">{{ getInitials(item.createdby) }}</span>
													</div>
													<div class="flex pl-1 pt-1.5 pb-1">
														<div class="font-bold cursor-pointer px-2 text-xs">{{ getUserName(item.createdby) }}</div>
														<div v-if="item.statusCode == 10415" class='text-xs'>{{ 'raised a request for approval at' }}</div>
														<div v-else-if="item.statusCode == 10408" class='text-xs'>{{ 'accepted the approval at' }}</div>
														<!-- <div v-else-if="item.previousStatusCode == 10408 && item.statusCode == 10411">{{ 'rejected the approval at' }}</div> -->
														<div v-else-if="item.statusCode == 10413" class='text-xs'>{{ 'published the document on' }}</div>
														<!-- <div class="font-bold px-2">{{ formatTime(item.createdAt) }}</div> -->

														<div class="font-bold cursor-pointer px-2 text-xs">{{ formatTime(item.createdAt) }}</div>
													</div>
												</div>
												<div class="flex px-11 pt-2.5">
													<div v-if="Object.keys(item).includes('previousStatusCode')" class="flex items-center justify-center rounded h-5 w-24 text-xs" :class="workflow_color(item.previousStatusCode)">
														<div :class="workflow_text(item.previousStatusCode)">
															<b>
																{{
																	statusValue.find((l) => {
																		return l.id == item.previousStatusCode;
																	}).status
																}}
															</b>
														</div>
													</div>
													<div v-if="!Object.keys(item).includes('previousStatusCode')" class="flex items-center justify-center rounded h-5 w-24 text-xs" :class="workflow_color(10411)">
														<div :class="workflow_text(10411)">
															<b>{{ 'Draft' }}</b>
														</div>
													</div>
													<div class="px-8 pt-1"><img src="@/assets/arrowRight.svg" /></div>
													<div class="flex items-center justify-center rounded h-5 w-24 text-xs" :class="workflow_color(item.statusCode)">
														<div :class="workflow_text(item.statusCode)">
															<b>
																{{
																	statusValue.find((l) => {
																		return l.id == item.statusCode;
																	}).status
																}}
															</b>
														</div>
													</div>
												</div>
												<div class="flex pl-11 pt-2.5" v-if="item.comments && item.comments !== ''">
													<div class="max-w-20 flex font-semibold justify-between">
														<span class='text-xs pt-0.5'>Comments</span><span class="ml-2">{{ ':' }}</span>
													</div>
													<div class="flex justify-center items-center text-xs ml-2">{{ item.comments }}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="selectedTabIndex == 4">
								<div class="card m-2 heightOfInnerdiv overflow-scroll no-scrollbar">
									<div class="document-container">
										<div class="justify-between flex items-center border-b mx-2 mt-2 pb-2 borderBottomStyle" style="justify-content: end">
											<!-- <p class="font-bold"><b class="mr-6">Document ID</b><b>Name Of The Document</b></p> -->
											<div class="flex">
												<div class="flex borderStyle border rounded-sm">
													<button
														:class="{ 'active-tab': activeButton === 'revision', 'inactive-tab': activeButton !== 'revision' }"
														class="flex items-center font-semibold fontSizing justify-center rounded p-px leading-3 cursor-pointer w-28 h-7"
														@click="showRevisionHistory"
													>
														<div v-if="activeButton === 'revision'"></div>
														Revision History
													</button>
													<button :class="{ 'active-tab': activeButton === 'details', 'inactive-tab': activeButton !== 'details' }" class="flex items-center fontSizing font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-7" @click="showDetails">
														<div v-if="activeButton === 'details'"></div>
														Details
													</button>
												</div>
												<button v-if="sortOrder" class="p-2 w-24 h-7 borderStyle rounded-sm flex items-center justify-center p-2 ml-3.5 font-semibold" @click="showNewest">
													<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180"/>
													Oldest
												</button>
												<button v-else class="p-2 w-24 h-7 border rounded-sm flex items-center justify-center p-2 ml-3.5 borderStyle font-semibold" @click="showNewest">
													<img src="@/assets/sortBy.svg" class="h-3 mr-2.5"/>
													Newest
												</button>
											</div>
										</div>
									</div>
									<div class="workHistoryScroller">
										<div v-if="revisionList" class="mx-3">
											<div v-for="(item, index) in currentHistoryArray" :key="index" class="py-4 px-2 borderBottomStyle">
												<div class="initials-container">
													<div class="rounded-full cursor-pointer h-8 w-8 flex items-center justify-center mr-2 bgColorAndColor">
														<span class="font-bold text-sm">{{ getInitialsForHistory(item, 'createdBy', 'updatedBy') }}</span>
													</div>
												</div>
												<div class="toppadding px-11 logContainer">
													<div class="flex">
														<p v-if="Object.keys(item).includes('createdBy')" class="font-semibold cursor-pointer text-xs">
															{{ getUserName(item.createdBy) }}
														</p>
														<p v-if="Object.keys(item).includes('updatedBy')" class="font-semibold cursor-pointer text-xs">
															{{ getUserName(item.updatedBy) }}
														</p>
														
														<p v-if="Object.keys(item).includes('createdBy')" class="mx-2 text-xs">{{ 'Created the Document on' }}</p>
														<p v-if="Object.keys(item).includes('updatedBy')" class="mx-2 text-xs">{{ 'Updated the Document on' }}</p>
														<p v-if="Object.keys(item).includes('updatedAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(item.updatedAt) }}</p>
														<p v-if="Object.keys(item).includes('createdAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(item.createdAt) }}</p>
													</div>
													<div class="mt-1">
														<div class="flex mt-1">
															<div class="min-w-20 flex justify-between">
																<span class="text-xs pt-0.5">Version</span><span class="ml-2">{{ ':' }}</span>
															</div>
															<div class="flex justify-center items-center font-semibold text-xs ml-2">{{ item.docVersion }}</div>
														</div>
														<div class="flex mt-1">
															<div class="min-w-20 flex justify-between">
																<span class="text-xs pt-0.5">Author</span><span class="ml-2">{{ ':' }}</span>
															</div>
															<div class="flex justify-center items-center font-semibold text-xs ml-2">{{ getUserName(presentDocumentData.createdBy) }}</div>
														</div>
														<!-- <div class="flex mt-1">
																<div class="min-w-20 flex justify-between">
																	<span class="text-xs">Status</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="ml-2">
																	<div class="flex items-center justify-center rounded h-5 w-24 text-xs" :class="workflow_color(item.statusCode)">
																		<div :class="workflow_text(item.statusCode)" class="text-xs">
																			<b>{{
																				statusValue.find((l) => {
																					return l.id == item.statusCode;
																				}).status
																			}}</b>
																		</div>
																	</div>
																</div>
															</div> -->
														<div class="flex mt-1">
															<div class="min-w-20 flex justify-between" v-if="item.statusCode == 10413">
																<span class="text-xs">File</span><span class="ml-2">{{ ':' }}</span>
															</div>
															<div class="flex justify-center items-center ml-2" v-if="item.statusCode == 10413">
																<div v-if="item.url"
																	class="mr-2 cursor-pointer fileUrlClick"
																	@click="clickedFileUrl(item.url, item.versionId, 'clickHere')"
																>
																	{{ 'Click Here' }}
																</div>
																<div v-else-if="item.content"
																	class="mr-2 cursor-pointer fileUrlClick"
																	@click="downloadHtmlAsPdf(item.content, item.docVersion, 'workHistory')"
																>
																	{{ 'Click Here' }}
																</div>
																<p class="text-xs">{{ 'for the Document' }}</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div v-if="showArray" class="mx-3">
											<div v-for="(item, index) in currentHistoryArray" :key="index" class="py-4 px-2 borderBottomStyle">
												<div class="initials-container">
													<div class="rounded-full cursor-pointer h-8 w-8 flex items-center justify-center mr-2 bgColorAndColor">
														<span class="font-bold text-sm">{{ getInitialsForHistory(item, 'createdBy', 'updatedBy') }}</span>
													</div>
												</div>
												<div class="toppadding px-11 logContainer">
													<div v-if="item.actionEntity == 10301 && item.statusCode == 10411">
														<div class="flex">
															<p v-if="Object.keys(item).includes('createdBy') && item.actionEntity == 10301" class="font-semibold cursor-pointer text-xs">
																{{ getUserName(item.createdBy) }}
															</p>
															<p v-if="!Object.keys(item).includes('previousStatus') && item.actionEntity == 10301" class="mx-2 text-xs">{{ 'Created the Document on' }}</p>
															<p v-if="Object.keys(item).includes('createdAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(item.createdAt) }}</p>
														</div>
														<div class="mt-1">
															<div class="flex mt-1">
																<div class="min-w-20 flex justify-between">
																	<span class="text-xs pt-0.5">Version</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="flex justify-center items-center font-semibold text-xs ml-2">{{ presentDocumentData.version }}</div>
															</div>
															<div class="flex mt-1">
																<div class="min-w-20 flex justify-between">
																	<span class="text-xs pt-0.5">Author</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="flex justify-center items-center font-semibold text-xs ml-2">{{ getUserName(presentDocumentData.createdBy) }}</div>
															</div>
															<div class="flex mt-1">
																<div class="min-w-20 flex justify-between" v-if="presentDocumentData.statusCode == 10413">
																	<span class="text-xs">File</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="flex justify-center items-center ml-2" v-if="presentDocumentData.statusCode == 10413">
																	<div v-if="presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].url"
																		class="mr-2 cursor-pointer fileUrlClick"
																		@click="clickedFileUrl(presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].url, presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].versionId, 'clickHere')"
																	>
																		{{ 'Click Here' }}
																	</div>
																	<div v-else-if="presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].content"
																		class="mr-2 cursor-pointer fileUrlClick"
																		@click="downloadHtmlAsPdf(presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].content, presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].docVersion, 'workHistory')"
																	>
																		{{ 'Click Here' }}
																	</div>
																	<p class="text-xs">{{ 'for the Document' }}</p>
																</div>
															</div>
														</div>
													</div>
													<div v-else-if="item.actionEntity == 10302 && item.statusCode == 10411">
														<div class="flex">
															<p v-if="Object.keys(item).includes('updatedBy') && item.actionEntity == 10302" class="font-semibold cursor-pointer text-xs">
																{{ getUserName(item.updatedBy) }}
															</p>
															<p v-if="!Object.keys(item).includes('previousStatus') && item.actionEntity == 10302" class="mx-2 text-xs">{{ 'Updated the Document on' }}</p>
															<p v-if="Object.keys(item).includes('updatedAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(item.updatedAt) }}</p>
														</div>
														<div class="mt-1">
															<div class="flex mt-1">
																<div class="min-w-20 flex justify-between">
																	<span class='text-xs'>Version</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="flex justify-center items-center font-semibold text-xs ml-2">{{ presentDocumentData.version }}</div>
															</div>
															<div class="flex mt-1">
																<div class="min-w-20 flex justify-between">
																	<span class="text-xs">Author</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="flex justify-center items-center font-semibold text-xs ml-2">{{ getUserName(presentDocumentData.createdBy) }}</div>
															</div>
															<div class="flex mt-1">
																<div class="min-w-20 flex justify-between">
																	<span class="text-xs">Status</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="ml-2">
																	<div class="flex items-center justify-center rounded h-5 w-24 text-xs" :class="workflow_color(10411)">
																		<div :class="workflow_text(10411)" class="text-xs">
																			<b>{{
																				statusValue.find((l) => {
																					return l.id == 10411;
																				}).status
																			}}</b>
																		</div>
																	</div>
																</div>
															</div>
															<div class="flex mt-1">
																<div class="min-w-20 flex justify-between" v-if="presentDocumentData.statusCode == 10413">
																	<span>File</span><span class="ml-2">{{ ':' }}</span>
																</div>
																<div class="flex justify-center items-center ml-2" v-if="presentDocumentData.statusCode == 10413">
																	<div v-if="presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].url"
																		class="mr-2 cursor-pointer fileUrlClick"
																		@click="clickedFileUrl(presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].url, presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].versionId, 'clickHere')"
																	>
																		{{ 'Click Here' }}
																	</div>
																	<div v-else-if="presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].content"
																		class="mr-2 cursor-pointer fileUrlClick"
																		@click="downloadHtmlAsPdf(presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].content, presentDocumentData.documentUrl[presentDocumentData.documentUrl.length - 1].docVersion, 'workHistory')"
																	>
																		{{ 'Click Here' }}
																	</div>
																	<p class="text-xs">{{ 'for the Document' }}</p>
																</div>
															</div>
														</div>
													</div>
													<div v-else>
														<div class="flex">
															<p v-if="Object.keys(item).includes('updatedBy')" class="font-semibold cursor-pointer text-xs">
																{{ getUserName(item.updatedBy) }}
															</p>
															<p v-if="Object.keys(item).includes('createdBy')" class="font-semibold cursor-pointer text-xs">
																{{ getUserName(item.createdBy) }}
															</p>

															<p v-if="item.statusCode == 10415" class="mx-2 text-xs">{{ 'Sent the Document for Approval on' }}</p>
															<p v-else-if="item.statusCode == 10408" class="mx-2 text-xs">{{ 'Approved the Document on' }}</p>
															<p v-else-if="item.statusCode == 10410" class="mx-2 text-xs">{{ 'Rejected the Document on' }}</p>
															<p v-else-if="item.statusCode == 10413" class="mx-2 text-xs">{{ 'Published the Document on' }}</p>
															<p v-else class="mx-2 text-xs">{{ 'Updated the Document on' }}</p>

															<p v-if="Object.keys(item).includes('updatedAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(item.updatedAt) }}</p>
															<p v-if="Object.keys(item).includes('createdAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(item.createdAt) }}</p>
														</div>
														<div v-if="Object.keys(item).includes('previousStatusCode')" class="flex pt-4">
															<!-- <div class="flex items-center justify-center relative rounded w-24 px-2.5 h-5 regular" :class="colorTextRequestStatus(item.previousStatusCode)">
                                                                <div class="w-full h-full absolute opacity-10 rounded" :class="colorBgRequestStatus(item.previousStatusCode)"></div>
                                                                <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
                                                                    <div class="text-xs">
                                                                        {{
                                                                            statusValue.find((l) => {
                                                                                return l.id == item.previousStatusCode;
                                                                            }).status
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div> -->
															<div class="flex items-center justify-center rounded h-5 w-24 text-xs" :class="workflow_color(item.previousStatusCode)">
																<div :class="workflow_text(item.previousStatusCode)" class="text-xs">
																	<b>{{
																		statusValue.find((l) => {
																			return l.id == item.previousStatusCode;
																		}).status
																	}}</b>
																</div>
															</div>

															<div v-if="Object.keys(item).includes('statusCode')" class="px-8 pt-1"><img src="@/assets/arrowRight.svg" /></div>

															<div v-if="Object.keys(item).includes('statusCode')" class="flex items-center justify-center rounded h-5 w-24 text-xs" :class="workflow_color(item.statusCode)">
																<div :class="workflow_text(item.statusCode)" class="text-xs">
																	<b>
																		{{
																			statusValue.find((l) => {
																				return l.id == item.statusCode;
																			}).status
																		}}</b
																	>
																</div>
															</div>

															<!-- <div v-if="Object.keys(item).includes('statusCode')" class="flex items-center justify-center relative rounded w-24 px-2.5 h-5 regular" :class="colorTextRequestStatus(item.previousStatusCode)">
                                                                <div class="w-full h-full absolute opacity-10 rounded" :class="colorBgRequestStatus(item.previousStatusCode)"></div>
                                                                <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
                                                                    <div class="text-xs">
                                                                        {{
                                                                            statusValue.find((l) => {
                                                                                return l.id == item.statusCode;
                                                                            }).status
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div> -->
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="iconContainer h-auto" :class="!showComments && !showControls && !showDocumentInfo && !showWorkFlow ? 'flex justify-end' : 'iconContainerWidth'" >
						<div class="flex flex-row w-full h-full">
							<div v-if="showControls" class="editorIconsStyle pt-3 w-full h-full">
								<div class="borderBottomStyle mx-2 flex flex-row justify-between mb-2.5 pb-3">
									<div class="font-semibold">
										<h5>Controls</h5>
									</div>
									<div class="crossIcon h-5 w-5 pt-1 pl-1.5">
										<img src="@/assets/cancel.svg" class="h-4 w-4 cursor-pointer" @click="toggleControls" />
									</div>
								</div>
								<!-- <div class="ControlsAndSaveIcon w-full flex-col justify-between overflow-y-scroll controlsInnerDiv h-3/4">
									<div :class="{ 'controlsInnerDiv w-full px-2': true, 'pointer-events-none': presentDocumentData.statusCode !== 10411 }">
										<div class="flex my-2" v-for="control in allControlsData" :key="control._id">
											<div class="flex justify-center items-center">
												<input class="checkbox mr-2" :id="control._id" :value="control._id" type="checkbox" v-model="mappedControlsForDocument" :disabled="presentDocumentData.statusCode !== 10411" />
											</div>
											<div class="flex items-center cursor-pointer text-xs">
												<label :for="control._id">{{ control.controlTitle }}</label>
											</div>
										</div>
									</div>
								</div> -->



								<div class="ControlsAndSaveIcon w-full flex-col justify-between overflow-y-scroll controlsInnerDiv heightStyles" v-if="authorRoleBased === true">
								<!-- v-if="authorRoleBased === true && $route.params.type === 'myDocuments' && presentDocumentData.statusCode === 10411" -->
									<div class="controlsInnerDiv w-full px-2" v-if="allControlsData.length != 0 && presentDocumentData.statusCode === 10411 && $route.params.type === 'myDocuments'">
										<div class="flex my-2" v-for="control in allControlsData" :key="control._id">
											<div class="flex justify-center items-center">
												<input class="checkbox mr-2" :id="control._id" :value="control._id" type="checkbox" v-model="mappedControlsForDocument" />
											</div>
											<div class="flex items-center cursor-pointer text-xs">
												<label :for="control._id">{{ control.controlTitle }}</label>
											</div>
										</div>
									</div>
									<div class="controlsInnerDiv w-full px-2" v-else-if="controlsDataArray.length != 0 && presentDocumentData.statusCode != 10411">
										<div class="flex my-2" v-for="control in controlsDataArray" :key="control._id">
											<div class="flex justify-center items-center">
												<input class="checkbox mr-2" :id="control._id" :value="control._id" type="checkbox" checked disabled />
											</div>
											<div class="flex items-center cursor-pointer text-xs">
												<label :for="control._id">{{ control.controlTitle }}</label>
											</div>
										</div>
									</div>
									<!-- <div v-else-if="allControlsData.length == 0 && presentDocumentData.statusCode === 10411 && $route.params.type === 'myDocuments'" class="flex justify-center items-end">
										<p>No controls data</p>
									</div> -->
									<div v-else class="flex justify-center items-end">
										<p>No records found</p>
									</div>
								</div>
								<div class="ControlsAndSaveIcon w-full flex-col justify-between overflow-y-auto controlsInnerDiv h-3/4" v-else-if="authorRoleBased === false">
								<!-- v-else-if="authorRoleBased === false && $route.params.type != 'myDocuments' && presentDocumentData.statusCode != 10411" -->
									<div class="controlsInnerDiv w-full px-2" v-if="controlsDataArray.length != 0">
										<div class="flex my-2" v-for="control in controlsDataArray" :key="control._id">
											<div class="flex justify-center items-center">
												<input class="checkbox mr-2" :id="control._id" :value="control._id" type="checkbox" checked disabled />
											</div>
											<div class="flex items-center cursor-pointer text-xs">
												<label :for="control._id">{{ control.controlTitle }}</label>
											</div>
										</div>
									</div>
									<div v-else class="flex justify-center items-end">
										<p>No records found</p>
									</div>
								</div>
								<!-- <div v-else class="flex justify-center items-end">
									<p>No records found</p>
								</div> -->





								<!-- controlsFixedIcon -->
								<!-- v-if="authorRoleBased === true && $route.params.type === 'myDocuments' && presentDocumentData.statusCode === 10411 && allControlsData.length != 0" -->
								<div class="flex controlsFixedIcon fixed items-center justify-center bg-white" v-if="authorRoleBased === true && $route.params.type === 'myDocuments' && presentDocumentData.statusCode === 10411 && allControlsData.length != 0" >
									<button :disabled="clickOnButton" class="btnprimary mr-2" @click="cancelButtonForControls()" :class="disableContolsBtn ? 'btndisabled pointer-events-none' : 'btnprimary'">
										Cancel
									</button>
									<!-- JSON.stringify(mappedControlsForDocument) == JSON.stringify(presentDocumentData.controls) || mappedControlsForDocument.length == 0 -->
									<button :disabled="clickOnButton" class="btnprimary ml-2" @click="mapControlsForDocument()" :class="disableContolsBtn ? 'btndisabled pointer-events-none' : 'btnprimary'">
										Save
									</button>
								</div>
							</div>
							<div v-if="showDocumentInfo" class="editorIconsStyle pt-3 p-2 w-full">
								<div class="borderBottomStyle flex flex-row justify-between mb-2.5 pb-3">
									<div class="font-semibold">
										<h5>Properties</h5>
									</div>
									<div class="crossIcon h-5 w-5 pt-1 pl-1.5">
										<img src="@/assets/cancel.svg" class="h-4 w-4 cursor-pointer" @click="toggleDocumentInfo" />
									</div>
								</div>
								
                                <div class="InnerDiv h-full" v-if="$route.params.type == 'fileslist'">
                                    <div>
                                        <div>
                                            <label class="controllabel text-xs font-semibold">Approved By</label>
                                            <br />
                                            <div class="text-sm mt-2">
                                                {{ presentDocApprovedBy }}
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <label class="controllabel text-xs font-semibold">Status</label>
                                            <!-- {{ documentDropdown.statusCode }} -->
                                            <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    statusValue.find((l) => {
                                                        return l.id == documentDropdown.statusCode;
                                                    }).status
                                                }}
                                            </div>
 
                                            <!-- <select class="inputboxstyling" v-model="documentDropdown.statusCode" disabled>
                                                <option value="" selected disabled hidden>Please select one</option>
 
                                                <option v-for="i in statusValue" :value="i.id" :key="i">
                                                    {{ i.status }}
                                                </option>
                                            </select> -->
                                        </div>
 
                                        <div class="mt-3">
                                            <label class="controllabel text-xs font-semibold">Review Cycle/Time-Period</label>
                                            <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    reviewPeriodSelect.find((l) => {
                                                        return l.id == documentDropdown.reviewPeriod;
                                                    }).status
                                                }}
                                            </div>
 
                                            <!-- <select
                                                class="inputboxstyling"
                                                v-model="documentDropdown.reviewPeriod"
                                                :disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411"
                                                :class="{ 'is-invalid': v$.documentDropdown.reviewPeriod.$error }"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>
                                                <option v-for="i in reviewPeriodSelect" :value="i.id" :key="i">
                                                    {{ i.status }}
                                                </option>
                                            </select> -->
                                        </div>
 
                                        <div class="mt-3">
                                            <label class="controllabel text-xs font-semibold">Type</label>
 
                                            <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    typeSelect.find((l) => {
                                                        return l.id == documentDropdown.type;
                                                    }).status
                                                }}
                                            </div>
 
                                            <!-- <select class="inputboxstyling" v-model="documentDropdown.type" :disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411" :class="{ 'is-invalid': v$.documentDropdown.type.$error }">
                                                <option value="" selected disabled hidden>Please select one</option>
 
                                                <option v-for="i in typeSelect" :value="i.id" :key="i">
                                                    {{ i.status }}
                                                </option>
                                            </select> -->
                                        </div>
 
                                        <div class="mt-3">
                                            <label class="controllabel text-xs font-semibold">Classification</label>
                                            <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    classificationSelect.find((l) => {
                                                        return l.id == documentDropdown.classification;
                                                    }).status
                                                }}
                                            </div>
 
                                            <!-- <select
                                                class="inputboxstyling"
                                                v-model="documentDropdown.classification"
                                                :disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411"
                                                :class="{ 'is-invalid': v$.documentDropdown.classification.$error }"
                                            >
                                                <option value="" selected disabled hidden>Please select one</option>
 
                                                <option v-for="i in classificationSelect" :value="i.id" :key="i">
                                                    {{ i.status }}
                                                </option>
                                            </select> -->
                                        </div>
 
                                        <div class="mt-3">
                                            <label class="controllabel text-xs font-semibold">Version</label>
                                            <br />
                                            <div class="text-sm mt-2">
                                                {{ documentDropdown.version }}
                                            </div>
 
                                            <!-- <input
                                                type="text"
                                                v-model="documentDropdown.version"
                                                class="inputboxstyling"
                                                :class="{ 'is-invalid': v$.documentDropdown.version.$error, 'disabled-input': presentDocumentData.statusCode != 10411 || $route.params.type == 'fileslist' }"
                                                :disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411"
                                            /> -->
                                        </div>
 
                                        <div v-if="presentDocumentData.statusCode == 10411 && $route.params.type == 'myDocuments' && authorRoleBased === true" class="flex justify-center align-center pt-5">
                                            <button @click="cancelDocumentProperties()" class="mr-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Cancel</button>
                                            <button @click="saveDocumentProperties()" class="ml-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                                        </div>
                                    </div>
                                </div>
								<div v-else-if="$route.params.type=='archivedocuments'">
									<div>
										<div class="mt-3">
											<label class="controllabel text-xs font-semibold">Status</label>
											 <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    statusValue.find((l) => {
                                                        return l.id == documentDropdown.statusCode;
                                                    }).status
                                                }}
                                            </div>
					
										</div>

										<div class="mt-3">
											<label class="controllabel text-xs font-semibold">Review Cycle/Time-Period</label>
											 <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    reviewPeriodSelect.find((l) => {
                                                        return l.id == documentDropdown.reviewPeriod;
                                                    }).status
                                                }}
                                            </div>
											
										</div>

										<div class="mt-3">
											<label class="controllabel text-xs font-semibold">Type</label>
											 <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    typeSelect.find((l) => {
                                                        return l.id == documentDropdown.type;
                                                    }).status
                                                }}
                                            </div>
										</div>

										<div class="mt-3">
											<label class="controllabel text-xs font-semibold">Classification</label>
											   <br />
                                            <div class="text-sm mt-2">
                                                {{
                                                    classificationSelect.find((l) => {
                                                        return l.id == documentDropdown.classification;
                                                    }).status
                                                }}
                                            </div>
										
										</div>

										<div class="mt-3">
											<label class="controllabel text-xs font-semibold">Version</label>
											<p class="text-sm mt-2">{{documentDropdown.version}}</p>
										</div>

										<div v-if="presentDocumentData.statusCode == 10411 && $route.params.type == 'myDocuments' && authorRoleBased === true" class="flex justify-center align-center pt-5">
											<button @click="cancelDocumentProperties()" class="mr-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Cancel</button>
											<button @click="saveDocumentProperties()" class="ml-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
										</div>
									</div>


								</div>
								<div v-else class="InnerDiv h-full">
									<div>
										<!-- <div>
											<label class="controllabel text-xs font-semibold">Document Owner</label>
											<Multiselect
												v-model="documentDropdown.documentOwner"
												:options="optionsUserArray"
												class="multiselect-custom"
												:disabled="presentDocumentData.statusCode != 10411"
												:classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
												:canClear="false"
												:class="{ 'is-invalid redBorder': v$.documentDropdown.documentOwner.$error}"
												:caret="false"
												placeholder="Name"
											></Multiselect>
											<div v-if="v$.documentDropdown.documentOwner.$error" class="text-red-500">
												<div class="error-text" v-if="v$.documentDropdown.documentOwner.required.$invalid">Required</div>
											</div>
										</div> -->

										<div>
											<label class="controllabel text-xs font-semibold">Status</label>
											<div :class="changeBackground(documentDropdown.statusCode)" class="inputboxstyling1 mt-2">
												<div class="text-white text-sm">
													{{ statusValue.find((obj)=>{
														return obj.id == documentDropdown.statusCode
														}).status
													}}
												</div>
											</div>
											<!-- <select class="inputboxstyling mt-2" v-model="documentDropdown.statusCode" :class="changeBackground(documentDropdown.statusCode)" disabled>
												<option value="" selected disabled hidden>Please select one</option>

												<option v-for="i in statusValue" :value="i.id" :key="i">
													{{ i.status }}
												</option>
											</select> -->
											<!-- <div v-if="v$.documentDropdown.statusCode.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.documentDropdown.statusCode.required.$invalid">Required</div>
											</div> -->
										</div>

										<div class="upperspacing">
											<label class="controllabel text-xs font-semibold">Review Cycle/Time-Period</label>
											<div class="text-sm mt-2">
												{{ 
													reviewPeriodSelect.find((obj)=>{
														return obj.id == documentDropdown.reviewPeriod
													}).status
												}}
											</div>
											<!-- <select
												class="inputboxstyling mt-2"
												v-model="documentDropdown.reviewPeriod"
												:disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411"
												:class="{ 'is-invalid': v$.documentDropdown.reviewPeriod.$error }"
											>
												<option value="" selected disabled hidden>Please select one</option>
												<option v-for="i in reviewPeriodSelect" :value="i.id" :key="i">
													{{ i.status }}
												</option>
											</select>
											<div v-if="v$.documentDropdown.reviewPeriod.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.documentDropdown.reviewPeriod.required.$invalid">Required</div>
											</div> -->
										</div>

										<div class="upperspacing">
											<label class="controllabel text-xs font-semibold">Type</label>
											<div class="text-sm mt-2">
												{{ 
													typeSelect.find((obj)=>{
														return obj.id == documentDropdown.type
													}).status
												}}
											</div>
											<!-- <select class="inputboxstyling mt-2" v-model="documentDropdown.type" :disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411" :class="{ 'is-invalid': v$.documentDropdown.type.$error }">
												<option value="" selected disabled hidden>Please select one</option>

												<option v-for="i in typeSelect" :value="i.id" :key="i">
													{{ i.status }}
												</option>
											</select>
											<div v-if="v$.documentDropdown.type.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.documentDropdown.type.required.$invalid">Required</div>
											</div> -->
										</div>

										<div class="upperspacing">
											<label class="controllabel text-xs font-semibold">Classification</label>
											<div class="text-sm mt-2">
												{{ 
													classificationSelect.find((obj)=>{
														return obj.id == documentDropdown.classification
													}).status
												}}
											</div>
											<!-- <select
												class="inputboxstyling mt-2"
												v-model="documentDropdown.classification"
												:disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411"
												:class="{ 'is-invalid': v$.documentDropdown.classification.$error }"
											>
												<option value="" selected disabled hidden>Please select one</option>

												<option v-for="i in classificationSelect" :value="i.id" :key="i">
													{{ i.status }}
												</option>
											</select>
											<div v-if="v$.documentDropdown.classification.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.documentDropdown.classification.required.$invalid">Required</div>
											</div> -->
										</div>

										<div class="upperspacing">
											<label class="controllabel text-xs font-semibold">Version</label>
											<div class="text-sm mt-2">
												{{documentDropdown.version}}
											</div>
											<!-- <input
												type="text"
												v-model="documentDropdown.version"
												class="inputboxstyling mt-2"
												:class="{ 'is-invalid': v$.documentDropdown.version.$error, 'disabled-input': presentDocumentData.statusCode != 10411 || $route.params.type == 'fileslist' }"
												:disabled="authorRoleBased === false || $route.params.type !== 'myDocuments' || presentDocumentData.statusCode !== 10411"
											/>
											<div v-if="v$.documentDropdown.version.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.documentDropdown.version.required.$invalid">Required</div>
											</div> -->
										</div>

										<!-- <div v-if="presentDocumentData.statusCode == 10411 && $route.params.type == 'myDocuments' && authorRoleBased === true" class="flex justify-center align-center pt-5">
											<button @click="cancelDocumentProperties()" class="mr-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Cancel</button>
											<button @click="saveDocumentProperties()" class="ml-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
										</div> -->
									</div>
								</div>
							</div>
							<div v-if="showComments" class="editorIconsStyle pt-3 w-full">
								<div class="px-2">
									<div class="borderBottomStyle flex flex-row justify-between mb-2.5 pb-3 relative">
										<div class="font-semibold">
											<h5>Comments</h5>
										</div>
										<div class="crossIcon h-5 w-5 pt-1 pl-1.5 absolute top-0 right-0">
											<img src="@/assets/cancel.svg" class="h-4 w-4 cursor-pointer" @click="toggleComments"/>
										</div>
									</div>
								</div>
								<div class="h-full flex flex-col justify-items-stretch gap-6">
									<div class="commentscrollable-container pb-2 mb-8">
										<div class="addedMessage px-2">
											<ul class="pr-1 relative">
												<li v-for="(savedComment, index) in savedComments" :key="index" class="border border-gray-300 rounded-sm p-2 mb-2 relative pr-1 comment-item left-2.5 commentSectionWidth">
													<div class="flex justify-between items-center mb-0.5">
														<div class="flex items-center">
															<div class="nk-icon transform translate-x-1/2 translate-y-1/2 absolute fontSizing rounded-full active w-5 h-5 flex justify-center items-center mr-2">{{ getInitials(savedComment.createdBy) }}</div>
															<div class="flex items-center">
																<div class="comment-name ml-1.5 text-sm font-bold mr-auto">{{ getUserName(savedComment.createdBy) }}</div>
															</div>
														</div>
														<!-- <div class="flex items-center"> -->
														<!-- <img src="@/assets/Update.svg" class="w-4 h-4 cursor-pointer mr-2" @click="editComment(index)" /> -->
														<!-- <img src="@/assets/deleteIcon.svg" class="w-4 h-4 cursor-pointer" @click="deleteComment(index)" /> -->
														<!-- </div> -->
													</div>
													<div class="flex items-center">
														<!-- <div v-if="savedComment.isEdited" class="editedMessage mt-px text-xs">Edited .</div> -->
														<div class="comment-date ml-1.5 text-xs text-gray-500">{{ formatDate(savedComment.createdAt) }}</div>
													</div>
													<div class="text-xs mt-1.5">
														<safeHtml :content="savedComment.comment"/>
													</div>
												</li>
											</ul>
										</div>
									</div>
									<div class="sticky bottomGap">
										<!-- <div class="commentsContainer w-full border rounded p-0.5 bg-white" :style="{ border: divBorder }" @click="toggleBorderColor">
											<textarea v-model="comment.text" class="w-full resize-none outline-none text-xs pl-2.5 pt-2.5 pb-14" placeholder="Add a Comment.."></textarea>
											<div class="block2 flex justify-end p-2">
												<img src="@/assets/Send.svg" style="width: 20px" class="w-4 h-4 cursor-pointer mb-0.5 mr-2.5s" :class="{ 'cursor-not-allowed disabled': comment.text.trim() === '' }" @click="saveComment" />
											</div>
										</div> -->
										<div>
                                           <commentsEditor/>
                                        </div>
									</div>
								</div>
							</div>
							<div v-if="showWorkFlow" class="editorIconsStyle pt-3 pl-2 pb-2 w-full">
								<div class="borderBottomStyle flex flex-row justify-between mb-2.5 pb-3 relative">
									<div class="font-semibold">
										<h5>Work Flow</h5>
									</div>
									<div class="crossIcon h-5 w-5 pt-1 pl-1.5 absolute top-0 right-0">
										<img src="@/assets/cancel.svg" class="h-4 w-4 cursor-pointer" @click="toggleWorkFlow" />
									</div>
								</div>
								<div class="workFlowScroll" style="height: 72%">
									<div class="steps steps-vertical">
										<div v-for="(step, index) in documentFlow" :key="index" :class="{ step: true, 'step-primary': checkFlow(step.id, index) }" data-content="">
											<div :class="step.details == null ? 'disablestep' : 'status_box'">
												<div>
													<div class="flex items-center justify-center rounded h-5 w-24" :class="step.details == null ? workflow_color(1) : workflow_color(step.id)">
														<div :class="step.details == null ? workflow_text('noValue') : workflow_text(step.id)" class="text-xs">
															<b>
																{{
																	documentFlow.find((l) => {
																		return l.id == step.id;
																	}).name
																}}
															</b>
														</div>
													</div>
												</div>
												<div v-if="step.details" class="text-xs text-start">
													<div class="flex">
														<div class="workflow">
															<div>
																<b v-if="step.details.createdBy"> {{ getUserName(step.details.createdBy) }}</b>
																<b v-else-if="step.details.updatedBy"> {{ getUserName(step.details.updatedBy) }}</b>
																{{
																	documentFlow.find((l) => {
																		return l.id == step.id;
																	}).statement
																}}
																<!-- <div v-if="step.details.statusCode == 10411">{{ 'created/Updated the document.' }}</div>
                                                                <p v-if="step.details.statusCode == 10415">{{ 'sent the document for Approval.' }}</p>
                                                                <p v-if="step.details.statusCode == 10408">{{ 'approved the document.' }}</p>
                                                                <p v-if="step.details.statusCode == 10413">{{ 'published the document.' }}</p> -->
															</div>
															<!-- <div v-else>
																{{step.details.updatedBy}}
																<div v-if="step.details.updatedBy">
																	<b> {{ getUserName(step.details.updatedBy) }}</b>
																	{{
																		documentFlow.find((l) => {
																			return l.id == step.id;
																		}).statement
																	}}
																</div>
															</div> -->
														</div>
													</div>
													<div>
														<p v-if="step.details.createdAt" class="cursor-pointer date_settings">{{ formatTime(step.details.createdAt) }}</p>
														<p v-else-if="step.details.updatedAt" class="cursor-pointer date_settings">{{ formatTime(step.details.updatedAt) }}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <div v-if="showWorkFlow" class="editorIconsStyle pt-3 p-2 w-full">
                                 <div class="borderBottomStyle flex flex-row justify-between mb-2.5 pb-3 relative">
                                    <div class="font-semibold">
                                        <h5>Work Flow</h5>
                                    </div>
                                    <div class="crossIcon h-5 w-5 pt-1 pl-1.5 absolute top-0 right-0">
                                        <img src="@/assets/cancel.svg" class="h-4 w-4 cursor-pointer" @click="toggleWorkFlow" />
                                    </div>
                                </div>
                                <div class="workFlowScroll" style="height: 90%">
                                    <div class="steps steps-vertical">
                                        <div v-for="(step, index) in documentFlow" :key="index" :class="{ step: true, 'step-primary': checkFlow(step.id, index) }" data-content="">
                                            <div>
                                                <div class="flex items-center justify-center rounded h-5 w-24" :class="colorLabelBg(step.id)">
                                                    <div :class="colorLabelText(step.id)">
                                                        {{
                                                            documentFlow.find((l) => {
                                                                return l.id == step.id;
                                                            }).name
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-if="step.details" class="flex">
                                                    <p v-if="!(step.details.statusCode == 10415)">
                                                        {{ getUserName(step.details.createdBy) }}
                                                    </p>
                                                    <p v-else>
                                                        {{ getUserName(presentDocumentData.documentOwner) }}
                                                    </p>
                                                    <p v-if="step.details.statusCode == 10411" class="mx-2 text-sm">{{ 'Created/Updated the document on' }}</p>
                                                    <p v-if="step.details.statusCode == 10415" class="mx-2 text-sm">{{ 'Sent the document for Approval on' }}</p>
                                                    <p v-if="step.details.statusCode == 10408" class="mx-2 text-sm">{{ 'Approved the document on' }}</p>
                                                    <p v-if="step.details.statusCode == 10413" class="mx-2 text-sm">{{ 'Published the document on' }}</p>
                                                    <p class="font-semibold cursor-pointer text-sm">{{ formatTime(step.details.createdAt) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
							<div class="rightSideBar borderStyle h-full borderBottomStyle">
								<div v-for="obj in iconsObj" :key="obj" @click="clickOnIcon(obj.id)">
									<div v-if="obj.id == 1" class="container relative inline-block hover:bg-lightgrey" :style="{ 'background-color': isHoveredInfo || showDocumentInfo ? '#e9e9e9' : '' }" @mouseenter="rightSidebar.properties = true" @mouseleave="rightSidebar.properties = false">
										<div class="flex justify-center items-center mt-2 mb-2 cursor-pointer">
											<!-- <div @mouseenter="rightSidebar.properties = true" @mouseleave="rightSidebar.properties = false"> -->
												<img src="@/assets/InfoDefault.svg" class="h-5" />
											<!-- </div> -->
											<div v-if="rightSidebar.properties" class="bg-tooltip absolute z-50 group-hover:opacity-100 rounded tooltipwidth border pointer-events-none transform-y-top right-11 whitespace-nowrap">
												<div class="px-2 text-white bg-primary">Properties</div>
											</div>
										</div>
									</div>
									<div
										v-else-if="(obj.id == 2 && $route.params.type == 'myDocuments') || (obj.id == 2 && $route.params.type == 'approvals')"
										class="container relative inline-block hover:bg-lightgrey"
										:style="{ 'background-color': isHoveredComments || showComments ? '#e9e9e9' : '' }"
										@mouseenter="rightSidebar.comments = true" @mouseleave="rightSidebar.comments = false"
									>
										<div class="flex justify-center items-center mt-2 mb-2 cursor-pointer">
											<!-- <div @mouseenter="rightSidebar.comments = true" @mouseleave="rightSidebar.comments = false"> -->
												<img src="@/assets/CommentsDefault.svg" class="h-5" />
											<!-- </div> -->
											<div v-if="rightSidebar.comments" class="bg-tooltip absolute z-50 group-hover:opacity-100 rounded tooltipwidth border pointer-events-none transform-y-top right-11 whitespace-nowrap">
												<div class="px-2 text-white bg-primary">Comments</div>
											</div>
										</div>
									</div>
									<div v-else-if="(obj.id == 3 && $route.params.type == 'myDocuments') || (obj.id == 3 && $route.params.type == 'approvals')" class="container relative inline-block hover:bg-lightgrey" :style="{ 'background-color': isHoveredFlow || showWorkFlow ? '#e9e9e9' : '' }" @mouseenter="rightSidebar.workFlow = true" @mouseleave="rightSidebar.workFlow = false">
										<div class="flex justify-center items-center mt-2 mb-2 cursor-pointer">
											<!-- <div @mouseenter="rightSidebar.workFlow = true" @mouseleave="rightSidebar.workFlow = false"> -->
												<img src="@/assets/Flow.svg" class="h-5" />
											<!-- </div> -->
											<div v-if="rightSidebar.workFlow" class="bg-tooltip absolute z-50 group-hover:opacity-100 rounded tooltipwidth border pointer-events-none transform-y-top right-11 whitespace-nowrap">
												<div class="px-2 text-white bg-primary">Work Flow</div>
											</div>
										</div>
									</div>
									<div v-if="obj.id == 4" class="container relative inline-block hover:bg-lightgrey" :style="{ 'background-color': isHoveredControls || showControls ? '#e9e9e9' : '' }" @mouseenter="rightSidebar.controls = true" @mouseleave="rightSidebar.controls = false">
										<div class="flex justify-center items-center mt-2 mb-2 cursor-pointer">
											<!-- <div @mouseenter="rightSidebar.controls = true" @mouseleave="rightSidebar.controls = false"> -->
												<img src="@/assets/ControlsDefault.svg" class="h-5" />
											<!-- </div> -->
											<div v-if="rightSidebar.controls" class="bg-tooltip absolute z-50 group-hover:opacity-100 rounded tooltipwidth border pointer-events-none transform-y-top right-11 whitespace-nowrap">
												<div class="px-2 text-white bg-primary">Controls</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<popup v-if="showPopUp" :popUp="showPopUp">
            <template v-slot:header>
                <div class="flex items-center justify-between py-4 px-2 rounded">
                    <h1 class="pl-2 w-72" :class="titleLength > 40? 'table-ellipsis':''">{{ newDocumentObj.documentName }}</h1>
                    <button class="float-right text-3xl" @click="onCancelButtonAddPopUp('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="w-full">
                                    <div>
                                        <label class="controllabel">Document Name</label>
										<div class="inputboxstyling mt-2">{{ newDocumentObj.documentName }}</div>
                                        <!-- <input
                                            type="text"
                                            class="inputboxstyling mt-2"
                                            v-model="newDocumentObj.documentName"
                                            placeholder="Document Name"
                                            disabled
                                            :class="{
                                                'is-invalid': v$.newDocumentObj.documentName.$error
                                            }"
                                        />
                                        <div v-if="v$.newDocumentObj.documentName.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.documentName.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Document ID</label>
										<div class="inputboxstyling mt-2">{{ newDocumentObj.documentId }}</div>
                                        <!-- <input type="text" class="inputboxstyling mt-2" v-model="newDocumentObj.documentId" disabled placeholder="Auto-generated" /> -->
                                        <!--
                                                :class="{
                                                    'is-invalid': v$.newDocumentObj.documentId.$error
                                                }"
                                                <div v-if="v$.newDocumentObj.documentId.$error" class="text-red-500">
                                                <div class="error-text" v-if="v$.newDocumentObj.documentId.required.$invalid">Required</div>
                                            </div> -->
                                    </div>
                                
                                <!-- <div class="row flex flex-row w-full upperspacing"> -->
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Owner</label>
										<div class="inputboxstyling mt-2">{{ newDocumentObj.documentOwner[0].label}}</div>
										<div class="mt-2">
                                        <!-- <Multiselect
                                            v-model="newDocumentObj.documentOwner"
                                            :options="optionsUsersList"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
											disabled
                                            label="label"
                                            track-by="value"
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Owner"
                                            deselectLabel=""
                                            selectLabel=""
                                            class="custom-multiselect"
											:class="{
												'is-invalid redBorder': v$.newDocumentObj.documentOwner.$error,
												'has-options': optionsUsersList && optionsUsersList.length > 0,
												'no-options':  !optionsUsersList.length
											}"
                                        > -->
											<!-- <template v-slot:noResult>
												<span class="text-xs">No Results Found</span>
											</template>
                                        </Multiselect> -->
									</div>
                                        <!-- <div v-if="v$.newDocumentObj.documentOwner.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.documentOwner.required.$invalid">Required</div>
                                        </div> -->
                                        <!-- <Multiselect
                                            v-model="newDocumentObj.documentOwner"
                                            :options="optionsUserArray"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :class="{ 'is-invalid redBorder': v$.newDocumentObj.documentOwner.$error }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Owner"
                                        ></Multiselect>
                                        <div v-if="v$.newDocumentObj.documentOwner.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.documentOwner.required.$invalid">Required</div>
                                        </div> -->
                                    </div></div>
                                <!-- </div> -->
                            </div>
                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Classification</label>
                                        <select
                                            class="inputboxstyling mt-2"
                                            v-model="newDocumentObj.classification"
											disabled
                                            :class="{
                                                'is-invalid': v$.newDocumentObj.classification.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
 
                                            <option v-for="option in classificationData" :key="option.id" :value="option.id">
                                                {{ option.value }}
                                            </option>
                                        </select>
                                        <div v-if="v$.newDocumentObj.classification.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.classification.required.$invalid">Required</div>
                                        </div>
                                    </div>
 
                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Version</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling mt-2"
                                            v-model.trim="newDocumentObj.version"
                                            placeholder="Version"
											@input="checkVersion()"
                                            :class="{
                                                'is-invalid': v$.newDocumentObj.version.$error || validateVersion
                                            }"
                                        />
                                        <div v-if="v$.newDocumentObj.version.$error || validateVersion" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.version.required.$invalid">Required</div>
                                            <div class="error-text text-xs" v-if="!v$.newDocumentObj.version.required.$invalid && validateVersion">Version should not be the same</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Type</label>
                                        <select
                                            class="inputboxstyling mt-2"
                                            v-model="newDocumentObj.type"
											disabled
                                            :class="{
                                                'is-invalid': v$.newDocumentObj.type.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
 
                                            <option v-for="i in typeSelect" :value="i.id" :key="i">
                                                {{ i.status }}
                                            </option>
                                        </select>
                                        <div v-if="v$.newDocumentObj.type.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.type.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Folder</label>
										<div class="mt-2">
										<Multiselect
											v-model="newDocumentObj.folder"
											:options="searchFoldersList"
											@search-change="asyncFind"
											:multiple="false"
											disabled
											:searchable="true"
											:hide-selected="true"
											open-direction="bottom"
											:close-on-select="true"
											:show-labels="false"
											placeholder="Folder"
											class="custom-multiselect"
											:class="{
												'is-invalid': v$.newDocumentObj.folder.$error,
												'has-options': searchFoldersList && searchFoldersList.length > 0,
												'no-options':  !searchFoldersList.length
											}"
										>
											<template v-slot:noResult>
												<span class="text-xs">No Results Found</span>
											</template>
										</Multiselect>
									</div>
                                        <!-- <dropdown :dataprops="foldersDataArray" :inputValue="newDocumentObj.folder" :placeholderValue="'Folder'" @name="getName" :error="v$.newDocumentObj.folder.$error"></dropdown> -->
                                        <div v-if="v$.newDocumentObj.folder.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.folder.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Review Period</label>
                                        <select
                                            class="inputboxstyling mt-2"
                                            v-model="newDocumentObj.reviewPeriod"
											disabled
                                            :class="{
                                                'is-invalid': v$.newDocumentObj.reviewPeriod.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
 
                                            <option v-for="option in reviewPeriodSelect" :key="option.id" :value="option.id">
                                                {{ option.status }}
                                            </option>
                                        </select>
                                        <div v-if="v$.newDocumentObj.reviewPeriod.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.reviewPeriod.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Author</label>
										<div class="mt-2">
                                        <Multiselect
                                            v-model="newDocumentObj.author"
                                            :options="allUserArray"
                                            :multiple="false"
                                            :caret="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            disabled
                                            label="label"
                                            track-by="value"
                                            :searchable="true"
                                            :hideArrow="true"
                                            placeholder="Author"
                                            deselectLabel=""
                                            selectLabel=""
                                            class="custom-multiselect"
											:class="{
												'has-options': allUserArray && allUserArray.length > 0,
												'no-options':  !allUserArray.length
											}"
                                        >
											<template v-slot:noResult>
												<span class="text-xs">No Results Found</span>
											</template>
                                        </Multiselect>
									</div>
                                        <!-- <Multiselect
                                            v-model="newDocumentObj.author"
                                            :options="allUserArray"
                                            :searchable="true"
                                            :minChars="1"
                                            :disabled="true"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :class="{ 'is-invalid redBorder': v$.newDocumentObj.author.$error }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Author"
                                        ></Multiselect>
                                        <div v-if="v$.newDocumentObj.author.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.newDocumentObj.author.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
 
                                <div>
                                    <div class="upperspacing">
                                        <label class="controllabel">Upload File</label>
                                        <div
                                            class="w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2"
                                            
                                        >
                                            <input type="file" accept="application/pdf, .docx, .doc" ref="fileInput" class="hidden" id="upload" @change.stop="updateFileName($event)" />
                                            <label for="upload" class="float-right text-white btnprimary1 p-2">Browse File</label>
                                            <span id="fileNameSpan" class="placeholder-styles items-center justify-between flex" v-if="!newDocumentObj._id && !newDocumentObj.file">{{ newDocumentObj.file ? newDocumentObj.file.name : placeholder }}</span>
                                            <span id="fileNameSpan" class="placeholder-styles items-center justify-between flex" v-else-if="!newDocumentObj.file || !newDocumentObj.file.name">{{ newDocumentObj.file ? newDocumentObj.file : placeholder }}</span>
                                            <span id="fileNameSpan" class="placeholder-styles items-center justify-between flex" v-else>{{ newDocumentObj.file ? newDocumentObj.file.name : placeholder }}</span>
                                        </div>
										<!-- :class="{
                                                'is-invalid': v$.newDocumentObj.file.$error
                                            }"
                                        <div v-if="v$.newDocumentObj.file.$error" class="text-red-500">
                                            <div class="error-text mx-2" v-if="v$.newDocumentObj.file.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button :disabled="clickOnButton" class="mr-1.5" :class="JSON.stringify(newDocumentObj) !== JSON.stringify(dummyReviewJson) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelButtonAddPopUpInEditor()">Cancel</button>
                        <button :disabled="clickOnButton" :class="JSON.stringify(newDocumentObj) !== JSON.stringify(dummyReviewJson) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="updateReviewData(newDocumentObj)">Update</button>
                    </div>
                </div>
            </fieldset>
        </popup>
	</div>
</template>
 
<script lang="ts">
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import breadCrumb from '../components/breadCrumb.vue';
import { required,maxLength, requiredIf } from '@vuelidate/validators';
// import Multiselect from '@vueform/multiselect';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { toast } from '@/main';
import { times } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import popup from '@/components/popup.vue';
import { mapGetters } from 'vuex';
import { flatMap } from 'lodash';
import { emitter } from '../main';
import jsPDF from 'jspdf';
import docmentEditor from '@/components/documentsEditor.vue'
import readOnlyDocumentEditor from '@/components/readOnlyDocumentEditor.vue';
import commentsEditor from '@/components/commentsEditor.vue';
import safeHtml from '@/components/safeHtml.vue';

// import dropdown from '@/components/dropdown.vue';

import { includes } from 'lodash';
export default defineComponent({
	data(): any {
		return {
			revisionHistory: [],
			approvalComment: {
				comments: '',
				owner: ''
			},
			showPopUp: false,
			approveAndRejectPopUp: false,
			showApprovalPopUp: false,
			details: [],
			routesArray:[],
			validateVersion: false,
			titleLength: 0,
			showArray: false,
			revisionList: false,
			currentHistoryArray: [],
			activeButton: null,
			dynamicPresignedUrl: '',
			tabHead: [
				{ displayName: 'Current Document', name: 'Current Document', id: 1, content: 'content of Tab 1' },

				{ displayName: 'Previous Documents', name: 'Previous Documents', id: 2, content: 'content of Tab 2' },

				{ displayName: 'Approvals', name: 'Approvals', id: 3, content: 'content of Tab 3' },

				{ displayName: 'Work History', name: 'Work History', id: 4, content: 'content of Tab 4' }
			],
			dynamicWidth: false,
			selectedTabIndex: null,
			selectedTabObj: { displayName: 'Current Document', name: 'Current Document', id: 1, content: 'content of Tab 1' },
			RevisionHistory: [],
			Approvals: [],
			sortOrder: true,
			isHoveredControls: false,
			isHoveredFlow: false,
			isHoveredComments: false,
			routeRoleBased: false,
			isHoveredInfo: false,
			showComments: false,
			showControls: false,
			showDocumentInfo: false,
			rightSidebar: { properties: false, comments: false, workFlow: false, controls: false },
			// uploadedContent: null,
			// renderedContent: '',
			clicked: false,
            clickOnButton: false,
			iconsObj: [
				{ name: 'documentInfo', id: 1 },
				{ name: 'controls', id: 2 },
				{ name: 'comments', id: 3 },
				{ name: 'workflow', id: 4 }
			],
			selectedIconId: '',
			divBorder: '1px solid #e9e9e9',
			v$: useVuelidate(),
			documentDropdown: {
				documentOwner: '',
				statusCode: '',
				reviewPeriod: '',
				type: '',
				classification: '',
				version: ''
			},
			classificationData: [
				{
					id: 1,
					value: 'Public'
				},
				{
					id: 2,
					value: 'Internal'
				},
				{
					id: 3,
					value: 'Confidential'
				},
				{
					id: 4,
					value: 'Highly Confidential'
				},
				{
					id: 5,
					value: 'Sensitive'
				}
			],
			statusValue: [
				{
					id: 10411,
					status: 'Draft'
				},
				{
					id: 10415,
					status: 'In Review'
				},
				{
					id: 10408,
					status: 'Approved'
				},
				{
					id: 10410,
					status: 'Rejected'
				},
				{
					id: 10413,
					status: 'Published'
				},
				{
					id: 10412,
					status: 'Archive'
				}
			],
			documentOwnerSelect: [
				{
					id: 1,
					status: 'option1'
				},
				{
					id: 2,
					status: 'option2'
				},
				{
					id: 3,
					status: 'option3'
				}
			],
			reviewPeriodSelect: [
				{
					id: 1,
					status: 'Quarterly'
				},
				{
					id: 2,
					status: 'Half Yearly'
				},
				{
					id: 3,
					status: 'Yearly'
				}
			],
			docStatusValues: [
				{
					id: 10411,
					value: 'Draft'
				},
				{
					id: 10415,
					value: 'In Review'
				},
				{
					id: 10408,
					value: 'Approved'
				},
				{
					id: 10410,
					value: 'Rejected'
				},
				{
					id: 10412,
					value: 'Archive'
				},
				{
					id: 10413,
					value: 'Published'
				}
			],
			typeSelect: [
				{
					id: 1,
					status: 'Policies'
				},
				{
					id: 2,
					status: 'Procedures'
				},
				{
					id: 3,
					status: 'Standards'
				},
				{
					id: 4,
					status: 'Guidelines'
				}
			],
			classificationSelect: [
				{
					id: 1,
					status: 'Public'
				},
				{
					id: 2,
					status: 'Internal'
				},
				{
					id: 3,
					status: 'Confidential'
				},
				{
					id: 4,
					status: 'Highly Confidential'
				},
				{
					id: 5,
					status: 'Sensitive'
				}
			],
			versionSelect: [
				{
					id: 1,
					status: 'Option1'
				},
				{
					id: 2,
					status: 'Option2'
				},
				{
					id: 3,
					status: 'Option3'
				}
			],
			statusCode: '',
			comment: { text: '' },
			visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
			displayErrorMessage : false,
            totalGroups: 0 ,
			savedComments: [],
			editedIndex: 0,
			allDocumentsData: [],
			allControlsData: [],
			mappedControlsForDocument: [],
			presentDocumentData: {},
			dummyPresentDocumentData: {},
			authorRoleBased: false,
			presentDocumentHistoryObj: {},
			historyArray: [],
			users: [],
			optionsUserArray: [],
			optionsUsersList: [],
			id: 1,
			publish: false,
			approveRejectBtns: false,
			justification: '',
			renderUrl: '',
			showWorkFlow: false,
			documentFlow: [
				{
					id: 10411,
					name: 'Draft',
					statement: 'Created/Updated the document.'
				},
				{
					id: 10415,
					name: 'In Review',
					statement: 'sent the document for Approval.'
				},
				{
					id: 10408,
					name: 'Approved',
					statement: 'approved the document.'
				},
				{
					id: 10413,
					name: 'Published',
					statement: 'published the document.'
				}
			],
			folder: [
				{
					id: 1,
					name: 'folder1'
				},
				{
					id: 2,
					name: 'folder2'
				}
			],
			allUserArray: [],
			placeholder: 'Select a File',
			showFullToolbar: false,
			selectedFile: null,
			previousDocuments: [],
			currentDocument: [],
			foldersDataArray: [],
			searchFoldersList: [],
			popupDocumentName: '',
			dummyReviewJson: {},
			newDocumentObj: {
				documentId: '',
				documentName: '',
				classification: '',
				version: '',
				// publishedDate: '',
				type: '',
				statusCode: '',
				documentOwner: '',
				file: null,
				folder: null,
				reviewPeriod: '',
				author: []
			},
			uploadedDocumentUrl: '',
			dummy: {
				documentId: '',
				documentName: '',
				classification: '',
				version: '',
				// publishedDate: '',
				type: '',
				statusCode: '',
				documentOwner: '',
				file: null,
				folder: null,
				reviewPeriod: '',
				author: ''
			},
			autoSuggestion: false,
			fileChanged: false,
			// buttonDisable: false,
			controlsDataArray: [],
            presentDoc: [],
            presentDocArray: [],
            presentDocApprovedBy: ''
		};
	},
	components: {
		breadCrumb,
		Multiselect,
		popup,
		docmentEditor,
		readOnlyDocumentEditor,
		commentsEditor,
        safeHtml
		// dropdown
	},
    watch: {
        scrollDoneValue(newValue) {
            this.$el.style.setProperty('--scroll-done-value', `${newValue}px`);
        }
    },
	async created() {
		await this.getAllUsers();
		await this.getAllDocumentsList();
		this.savedComments = this.presentDocumentData.comments;
		if (this.presentDocumentData.author == this.userInfo._id) {
			this.authorRoleBased = true;
		}
		if(this.$route.path.includes('/edit/myDocuments')){
			this.routesArray = [
				{ name: 'My Documents', routeName: 'myDocuments' },
				{ name: this.presentDocumentData.documentId, routeName: 'editor' },
			]
		}
		else if(this.$route.path.includes('/edit/approvals')){
			this.routesArray = [
				{ name: 'Approvals', routeName: 'approvals' },
				{ name: this.presentDocumentData.documentId, routeName: 'editor' },
			]
		} else if(this.$route.path.includes('/edit/archivedocuments')){
			this.routesArray = [
				{ name: 'Archive Documents', routeName: 'archivedocuments' },
				{ name: this.presentDocumentData.documentId, routeName: 'editor' },
			]
		} else if(this.$route.path.includes('/edit/fileslist')){
			this.routesArray = [
				{ name: 'Documents List', routeName: 'documentslist' },
				{ name: this.presentDocumentData.folder, routeName: 'fileslist' },
				{ name: this.presentDocumentData.documentName, routeName: 'editor' },
			]
		}
		await this.getAllControls();
		// await this.getPresentDocumentHistoryType2();
		// await this.getPresentDocumentHistoryType1();
		await this.renderPDF();
		const savedTabKey = sessionStorage.getItem('selectedTab');
		if (savedTabKey) {
		const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
		if (savedTab) {
				this.toggleTabs(savedTab);
		}
		else{
			this.selectedTabObj = { displayName: 'Current Document', name: 'Current Document', id: 1, content: 'content of Tab 1' };
			this.selectedTabIndex = 1;
		}
		}
		else{
			this.selectedTabObj = { displayName: 'Current Document', name: 'Current Document', id: 1, content: 'content of Tab 1' };
			this.selectedTabIndex = 1;
		}
		this.newDocumentObj = { ...this.documentDropdown };
		this.approvedByMethod();
		this.titleLength = this.presentDocumentData.documentName ? this.presentDocumentData.documentName.length:0
		
		// this.dummy = {...this.newDocumentObj}
	},
	async mounted() {
		this.updateScrollValue();
		this.visibleTabs = [...this.tabHead];
 
        this.handleResize(); // Initial setup
        window.addEventListener('resize', this.handleResize);
        this.updateButtonStates();
		emitter.off('showData123');
		emitter.on('showData123', (isData: any) => {
			let comingId = isData.entityData._id;
			let showObj = this.allDocumentsData.find((obj: any) => {
				return obj._id === comingId;
			});
			this.showInfoEditPopUp(showObj);
		});
		emitter.off('addedComments');
		emitter.on('addedComments', (data: any) => {
			console.log("addedComments", data)
			this.saveQuillData(data);
		});
		emitter.off('commentUpdate');
            emitter.on('commentUpdate',(data: any)=>{
                console.log('H',data)
                this.comment.text = data.comments
                this.addComment()
            })
		if (this.presentDocumentData.controls) {
			this.mappedControlsForDocument = this.presentDocumentData.controls;
		} else {
			this.mappedControlsForDocument = [];
		}
		if (this.$route.params.type == 'approvals') {
			this.routeRoleBased = true;
		}
		document.addEventListener('click', this.handleClickOutside);
		// this.showRevisionHistory();
		await this.getPresentDocumentHistoryType1();
	},
	beforeUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab')
	},
	validations() {
		return {
			comment: {
				text: { required }
			},
			documentDropdown: {
				documentOwner: { required },
				statusCode: { required },
				reviewPeriod: { required },
				type: { required },
				classification: { required },
				version: { required }
			},
			approvalComment: {
				comments: { maxLength: maxLength(308) },
				owner: { required }
			},
			justification: { maxLength: maxLength(308) },
			newDocumentObj: {
				documentName: { required },
				classification: { required },
				version: { required },
				type: { required },
				folder: { required },
				documentOwner: { required },
				// file: {
				// 	required: requiredIf(() => {
				// 		return !this.newDocumentObj._id;
				// 	})
				// },
				author: { required },
				reviewPeriod: { required }
			}
		};
	},
	computed: {
		// breadcrumbs(): { name: string; routeName: string }[] {
		// 	let defaultName = '';
		// 	let passingRouteName = '';
		// 	if (this.$route.params.type == 'myDocuments') {
		// 		defaultName = 'My Documents';
		// 		passingRouteName = 'myDocuments';
		// 	} else if (this.$route.params.type == 'fileslist') {
		// 		defaultName = 'Files list';
		// 		passingRouteName = 'fileslist';
		// 	} else if (this.$route.params.type == 'approvals') {
		// 		defaultName = 'Approvals';
		// 		passingRouteName = 'approvals';
		// 	} else if (this.$route.params.type == 'archivedocuments') {
		// 		defaultName = 'Archive Documents';
		// 		passingRouteName = 'archivedocuments';
		// 	}
		// 	const defaultBreadcrumb = { name: defaultName, routeName: passingRouteName };
		// 	const selectedTab = this.tabHead.find((tab: any) => tab.id === this.selectedTabIndex);
		// 	if (selectedTab) {
		// 		const selectedBreadcrumb = { name: selectedTab.displayName, routeName: selectedTab.name };
		// 		return [defaultBreadcrumb];
		// 	} else {
		// 		return [defaultBreadcrumb];
		// 	}
		// },
		disableContolsBtn: function (): any {
			if ((((this.presentDocumentData.controls && this.presentDocumentData.controls.length !=0) || this.mappedControlsForDocument != 0) && JSON.stringify(this.mappedControlsForDocument) == JSON.stringify(this.presentDocumentData.controls)) || ((this.presentDocumentData.controls && this.presentDocumentData.controls.length ==0) && this.mappedControlsForDocument.length == 0) || (!this.presentDocumentData.controls && this.mappedControlsForDocument.length == 0)) {
				return true;
			} else {
				return false;
			}
		},
		disableStatus: function (): any {
			let disableStatusCondition;
			if (this.presentDocumentData) {
				if (JSON.stringify(this.documentDropdown) == JSON.stringify(this.dummyPresentDocumentData)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			} else {
				let val: any = [];
				Object.values(this.documentDropdown).forEach((value: any) => {
					val.push(value);
				});
				for (let num of val) {
					if (num != '') {
						disableStatusCondition = true;
					}
				}
			}
			return disableStatusCondition;
		},
		...mapGetters({ userInfo: 'userInfo',scrollDoneValue:'scrollDoneValue' })
	},

	methods: {
		checkVersion(){
			if(this.presentDocumentData && this.presentDocumentData.documentUrl){
				let versionsArr: any = []
				let version = this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].docVersion
				console.log("docVersion", version)
				this.presentDocumentData.documentUrl.forEach((doc:any)=>{
					if(version != doc.docVersion){
						versionsArr.push(doc.docVersion)
					}
				})
					// console.log("doc.docVersion", doc.docVersion)
					// console.log("this.newDocumentObj.version", this.newDocumentObj.version)
					if(versionsArr.includes(this.newDocumentObj.version)){
						this.validateVersion = true
					} else{
						this.validateVersion = false
					}
				
			}
		},
		updateScrollValue() {
            this.$el.style.setProperty('--scroll-done-value', `${this.scrollDoneValue}px`);
        },
		updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
       
        scrollTabs(direction:any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
				console.log("2222this.currentGroupIndex",this.currentGroupIndex)
				console.log("222this.totalGroups",this.totalGroups-1)
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs('tab');
        },
 
        handleResize() {
            const width = window.innerWidth;
            if (width <= 427) {
                this.tabsPerScreen = 1;
            } else if (width <= 569) {  
                this.tabsPerScreen = 2;
            } else {
                this.tabsPerScreen = this.tabHead.length; // Show all tabs for larger screens
            }

            this.isSmallScreen = (width <= 1024); // For logic buttons ref

            // Calculate total groups
            this.totalGroups = Math.ceil(this.tabHead.length / this.tabsPerScreen);

            // Determine the group containing the selected tab
            this.updateCurrentGroupIndex();
            this.updateVisibleTabs('normal');
            this.updateButtonStates();
        },

        updateCurrentGroupIndex() {
            const selectedTabIndex = this.tabHead.findIndex((tab:any) => tab.id === this.selectedTabIndex);
            if (selectedTabIndex >= 0) {
                this.currentGroupIndex = Math.floor(selectedTabIndex / this.tabsPerScreen);
            } else {
                this.currentGroupIndex = 0; // Reset to first group if selected tab not found
            }
        },

        updateVisibleTabs(from:any) {
            if (this.tabsPerScreen === this.tabHead.length) {
                this.visibleTabs = [...this.tabHead]; // Show all tabs
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerScreen;
                const endIdx = startIdx + this.tabsPerScreen;
                this.visibleTabs = this.tabHead.slice(startIdx, endIdx);
				console.log("this.visibleTabs",this.visibleTabs)
				console.log("1startIdx",startIdx)
				console.log("1endIdx",endIdx)
            }

            if(from=='tab'){
                this.toggleTabs(this.visibleTabs[0])
            }
        },
		asyncFind(query:any){
			if(query == ''){
				this.searchFoldersList = []
			}else if(query.length > 0){
				let objQuery = this.foldersDataArray.filter((obj:any)=>{return obj.toLowerCase().includes(query.toLowerCase())})
				console.log("objQuery", objQuery)
				this.searchFoldersList = objQuery
			}
			console.log("searchFoldersList", this.searchFoldersList)
		},
		approvedByMethod(){
            if (Object.keys(this.presentDocumentData).includes('approvals')) {
                this.presentDocumentData.approvals.forEach((eachDoc: any) => {
                    if (Object.keys(eachDoc).includes('approval') && eachDoc.approval) {
                        this.presentDocArray.push(eachDoc.approval);
                    }
                });
            }
 
            if (this.presentDocArray.length != 0) {
                let lastIndexId = this.presentDocArray[this.presentDocArray.length - 1];
                this.presentDocApprovedBy = this.users.find((user:any)=> {
                    return lastIndexId === user._id
                }).name
                // this.presentDocApprovedBy = lastIndexId;
            }

		},
		selectedcontrolsData() {
			let documentId = this.$route.params.documentId;
			let currentDocument: any = [] || '';
			this.allDocumentsData.find((data: any) => {
				if (data._id == documentId) {
					currentDocument.push(data.controls);
				}
			});
			if (currentDocument == '') {
				console.log('data is empty');
			} else {
				currentDocument.forEach((data: any) => {
					data.forEach((x: any) => {
						this.allControlsData.find((data: any) => {
							if (data._id == x) {
								this.controlsDataArray.push(data);
							}
						});
					});
				});
			}

		},
		showInfoEditPopUp(obj: any) {
			this.showPopUp = true;
			this.newDocumentObj = { ...obj };
			this.dummy = { ...obj };
			this.popupDocumentName = obj.documentName;
			// if (obj.documentUrl[0].url) {
				// let docUrl = obj.documentUrl[0].url;
				// let fileName = docUrl.split('/').pop();

				// let fileExtension = fileName.split('.').pop();
				// let id=uuidv4();
				// obj.file = `${obj.documentId}-${obj.documentName}.${fileExtension}`;
				// this.newDocumentObj.file = obj.file;
			// }
		},
		filteredCategories(filterColumn: any, status: any) {
			console.log("filterColumn", filterColumn)
			console.log("status", status)
			this.foldersDataArray = [
				...new Set(
					Object.values(this.allDocumentsData).map((x: any) => {
						x[status];
						return x[status];
					})
				)
			].filter((category: any) => {
				return category.toLowerCase().includes(filterColumn.toLowerCase());
			});
		},
		getName(options: any, status: any) {
			let s = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
			this.filteredCategories(options, s);
			this.setState(options, s);
		},
		setState(options: any, status: any) {
			this.newDocumentObj[status] = options;
			this.autoSuggestion = false;
		},
		updateFileName(event: any) {
			// const selectedFile = event.target.files[0];
			// this.newDocumentObj.file = selectedFile;
			let file = this.$refs.fileInput.files[0];

			if (!file && !file.type.match('application/pdf') && !file.name.endsWith('.doc') && !file.name.endsWith('.docx')) {
				alert('Please select a PDF, DOC, or DOCX file.');
				return;
			} else {
				this.fileChanged = true;
				this.newDocumentObj.file = file;
			}
		},

		showPopupByReversion() {
			this.showPopUp = true;
			let id: any = this.$route.params.documentId;
			let rowJsonData: any;
			this.allDocumentsData.filter((data: any) => {
				if (data._id == id) {
					rowJsonData = data;
				}
			});
			// if(rowJsonData.documentUrl && rowJsonData.documentUrl[rowJsonData.documentUrl.length - 1].url){
			// 	let docUrl = rowJsonData.documentUrl[rowJsonData.documentUrl.length - 1].url;
			// 	let fileName = docUrl.split('/').pop();

			// 	let fileExtension = fileName.split('.').pop();
			// 	rowJsonData.file = `${rowJsonData.documentId}-${rowJsonData.documentName}.${fileExtension}`;
			// }
			
			// let docOwnerObj: any = []
			// let docauthorObj: any = []
			// for (var i = 0; i < this.users.length; i++) {
			// 	if (this.users[i]._id == rowJsonData.documentOwner) {
			// 		docOwnerObj.push({ label: this.users[i].name, value: this.users[i]._id });
			// 	}
			// 	if (this.users[i]._id == rowJsonData.author) {
			// 		docauthorObj.push({ label: this.users[i].name, value: this.users[i]._id });
			// 	}
			// }
			// rowJsonData.documentOwner = docOwnerObj
			// rowJsonData.author = docauthorObj
			this.newDocumentObj = { ...rowJsonData };
			// let docOwnerObj = this.users
			// 	.filter((user: any) => user._id === this.newDocumentObj.documentOwner)
			// 	.map((user: any) => ({ label: user.name, value: user._id }));

			// let docAuthorObj = this.users
			// 	.filter((user: any) => user._id === this.newDocumentObj.author)
			// 	.map((user: any) => ({ label: user.name, value: user._id }));

			let docOwnerObj: any = [];
			let docAuthorObj: any = [];
			for (var i = 0; i < this.users.length; i++) {
				if (this.users[i]._id == this.newDocumentObj.documentOwner) {
					docOwnerObj.push({ label: this.users[i].name, value: this.users[i]._id });
				}
				if (this.users[i]._id == this.newDocumentObj.author) {
					docAuthorObj.push({ label: this.users[i].name, value: this.users[i]._id });
				}
			}
			this.newDocumentObj.documentOwner = docOwnerObj;
			this.newDocumentObj.author = docAuthorObj;

			this.newDocumentObj.documentOwner = docOwnerObj;
			this.newDocumentObj.author = docAuthorObj;
			this.dummyReviewJson = { ...this.newDocumentObj };
			this.popupDocumentName = rowJsonData.documentName;
			this.newDocumentObj.file = rowJsonData.file;
		},
		onCancelButtonAddPopUpInEditor(status: any) {
			this.v$.$reset();
			this.newDocumentObj = { ...this.dummyReviewJson };
		},

		async uploadEncodedDataFormat(data: any) {
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		async uploadToS3AndGetLink(file: any) {
			const bufferData = await this.uploadEncodedDataFormat(file);
			let fileExtension = file.name.split('.').pop();
			// let id=uuidv4();

			let payload = { fileData: bufferData, name: `${this.newDocumentObj.documentId}-${this.newDocumentObj.documentName}.${fileExtension}`, folderName: this.newDocumentObj.folder, fileType: file.type };
			try {
				await this.$http.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/test/file/uploading`, payload).then((response: any) => {
					if (response.status == 200) {
						this.uploadedDocumentUrl = response.data;
					} else {
						console.log('response status', response.status);
					}
				});
			} catch (err) {
				console.error(err);
			}
			return this.uploadedDocumentUrl;
		},
		async saveQuillData(docObj: any) {
			try {
				docObj.updatedAt = new Date();
				docObj.updatedBy = this.userInfo._id
				// docObj.statusCode = this.presentDocumentData.statusCode
				let docId = this.$route.params.documentId;

				await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/content/update`, docObj).then((result: any) => {
					toast.info(`Uploaded succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showPopUp = false;
			await this.getAllDocumentsList();
			await this.getPresentDocumentHistoryType2();
			await this.getPresentDocumentHistoryType1();
			this.$store.dispatch('updateDotsLoading', false);
		},
		async updateReviewData(docObject: any) {
			this.clickOnButton = true;
			let docObj: any = {...docObject}
			try {
				if(!this.v$.newDocumentObj.$invalid && this.validateVersion == false){
					let versionObj
					let docId = docObj._id;
					
					if (this.fileChanged == true) {
						let file = this.$refs.fileInput.files[0];
						let camedocumentUrl = await this.uploadToS3AndGetLink(file);
						versionObj = {
							version: docObj.version,
							docUrl: {
								url: camedocumentUrl.url,
								versionId: camedocumentUrl.versionId,
								updatedAt: new Date(),
								updatedBy: this.userInfo._id,
								statusCode: 10411
							},
							updatedBy: this.userInfo._id,
						}
						this.$store.dispatch('updateDotsLoading', true);
					} else{
						versionObj = {
							version: docObj.version,
							updatedBy: this.userInfo._id,
						}
					}
					try{
						await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/version/update`, versionObj).then(async (result: any) => {
							toast.info(`Uploaded succesfully`, {
								closeOnClick: true,
								closeButton: 'button'
							});
							this.fileChanged = false;
						});
					} catch(err){
						toast.error(`Error`, {
							closeOnClick: true,
							closeButton: 'button'
						});
					} finally {
						this.clickOnButton = false;
					}
					this.showPopUp = false;
					await this.getAllDocumentsList();
					await this.getPresentDocumentHistoryType2();
					await this.getPresentDocumentHistoryType1();
					await this.renderPDF();
					this.$store.dispatch('updateDotsLoading', false);
				}
				// else{
				// 	console.log("OUTTTTTTTTTT")
				// 	toast.error('Cannot Update with the Same version', {
				// 		timeout: 1000,
				// 		closeOnClick: true,
				// 		closeButton: 'button',
				// 		icon: true
				// 	});
				// }
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButton = false;
			}
		},
		checkFlow(cameId: any,index:any) {
			let History: any = [];
			console.log("this.presentDocumentHistoryObj.history", this.presentDocumentHistoryObj.history)
			if(this.presentDocumentHistoryObj.history != undefined){
				for (let i = this.presentDocumentHistoryObj.history.length - 1; i >= 0; i--) {
					const obj = this.presentDocumentHistoryObj.history[i];
					if ((obj.statusCode == 10411 && obj.previousStatusCode != undefined && obj.statusCode != obj.previousStatusCode )|| obj.actionEntity == 10301) {
						History.push(obj);
						break
					}
					else if(obj.previousStatusCode != undefined && obj.statusCode != obj.previousStatusCode) {
						History.push(obj);
					}
				}
				let renderHistory:any [] = History.filter((histObj: any) => 
					(histObj.statusCode !== undefined || histObj.previousStatusCode !== undefined) &&
					histObj.statusCode !== histObj.previousStatusCode
				);
				renderHistory = renderHistory.reverse()
			
				if(renderHistory && renderHistory.length>0){
					renderHistory.map((obj: any, index: any)=>{
						this.documentFlow[index].details = obj;
					})
					
					let result = renderHistory.find((obj: any) => {
						return obj.statusCode == cameId;
					});
					if (result == undefined) {
						return false;
					} else {
						return true;
					}
				}
			}
		},
		toggleTabs(obj: any) {
			if (this.$route.params.type != 'fileslist' || this.$route.params.type != 'archivedocuments') {
				this.selectedTabIndex = obj.id;
				this.selectedTabObj = obj;
				sessionStorage.setItem('selectedTab', obj.id);
			}
			if (obj.id == 4) {
				this.showRevisionHistory();
			}
			// console.log("objjjjjjjjjj", obj)
			// if(obj.id == 1 && this.presentDocumentData[this.presentDocumentData.length - 1].content){
			// 	this.changeBase64toHtml();
			// }
		},
		changeBackground(statusCode: any) {
			if (statusCode == 10411) {
				return 'bg-darkred textColorWhite';
			} else if (statusCode == 10412) {
				return 'bg-green textColorWhite';
			} else if (statusCode == 10413) {
				return 'bg-lightgreen textColorWhite';
			} else if (statusCode == 10408) {
				return 'bg-primary textColorWhite';
			} else if (statusCode == 10415) {
				return 'bg-yellow textColorWhite';
			} else {
				return '';
			}
		},
		workflow_text(value: any) {
			if (value == 10411) {
				return ['text-darkred'];
			} else if (value == 10415) {
				return ['text-yellow'];
			} else if (value == 10408) {
				// console.log("intoooo primaryyyyy")
				return ['text-primary'];
			} else if (value == 10412) {
				return ['text-green'];
			} else if (value == 10413) {
				return ['text-lightgreen'];
			} else {
				return ['text-grey'];
			}
		},

		workflow_color(value: any) {
			if (value == 10411) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			} else if (value == 10412) {
				return ['bg-lowColor border-solid border border-green'];
			} else if (value == 10408) {
				return ['bg-primaryBg border-solid border border-primary'];
			} else if (value == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (value == 10413) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else {
				return ['bg-lightgrey border-solid border border-grey'];
			}
		},

		colorTextClasificationStatus(number: any) {
			if (number == 1) {
				return ['text-darkred', 'border-solid border border-darkred font-semibold'];
			} else if (number == 2) {
				return ['text-yellow', 'border-solid border border-yellow font-semibold'];
			} else if (number == 3) {
				return ['text-primary', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-primary'];
			} else if (number == 4) {
				return ['text-darkred', 'flex justify-center py-1 font-semibold border-solid border border-darkred'];
			} else if (number == 5) {
				return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
			}
		},
		colorBgClasificationStatus(code: any) {
			if (code == 1) {
				return ['bg-darkred', 'opacity-10'];
			} else if (code == 2) {
				return ['bg-yellow', 'opacity-10'];
			} else if (code == 3) {
				return ['bg-primary', 'opacity-10'];
			} else if (code == 4) {
				return ['bg-darkred', 'opacity-10'];
			} else if (code == 5) {
				return ['bg-lightgreen', 'opacity-10'];
			}
		},
		colorTextRequestStatus(status: any) {
			if (status == 10411) {
				return ['text-darkred', 'border-solid border border-darkred font-semibold'];
			} else if (status == 10415) {
				return ['text-yellow', 'border-solid border border-yellow font-semibold'];
			} else if (status == 10408) {
				return ['text-primary', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-primary'];
			}
			// else if (status == 10412) {
			//     return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
			// }
			else if (status == 10413) {
				return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
			} else if (status == 10401) {
				return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
			}
		},
		colorBgRequestStatus(code: any) {
			if (code == 10411) {
				return ['bg-darkred', 'opacity-10'];
			} else if (code == 10415) {
				return ['bg-yellow', 'opacity-10'];
			} else if (code == 10408) {
				return ['bg-primary', 'opacity-10'];
			}
			// else if (code == 10412) {
			//     return ['bg-darkred', 'opacity-10'];
			// }
			else if (code == 10413) {
				return ['bg-lightgreen', 'opacity-10'];
			} else if (code == 10401) {
				return ['bg-darkred', 'opacity-10'];
			}
		},
		docPerviewStyles(){
			if(this.$route.params.type === 'fileslist' || this.$route.params.type === 'archivedocuments'){
				return true
			} else {
				return false
			}
		},
		colorLabelText(value: any) {
			if (value == 10411) {
				return ['text-darkred font-semibold'];
			} else if (value == 10412) {
				return ['text-green font-semibold'];
			} else if (value == 10413) {
				return ['text-lightgreen font-semibold'];
			} else if (value == 10408) {
				return ['text-primary font-semibold'];
			} else if (value == 10415) {
				return ['text-yellow font-semibold border-solid border border-yellow'];
			} else if (value == 10401) {
				return ['text-darkred font-semibold'];
			}
		},
		colorLabelBg(value: any) {
			if (value == 10411) {
				return ['bg-veryLowColor border-solid border border-darkred'];
			} else if (value == 10412) {
				return ['bg-lowColor border-solid border border-green'];
			} else if (value == 10413) {
				return ['bg-moderateColor border-solid border border-lightgreen'];
			} else if (value == 10408) {
				return ['bg-highColor border-solid border border-primary'];
			} else if (value == 10415) {
				return ['bg-yellow', 'opacity-10'];
			} else if (value == 10401) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			}
		},
		async showDetails() {
			this.currentHistoryArray = [];
			this.revisionList = false;
			this.activeButton = 'details';
			this.showArray = true;
			await this.getPresentDocumentHistoryType1();
			this.currentHistoryArray = this.historyArray;
			this.sortOrder = false;
		},
		showNewest() {
			// Toggle the sort order
			this.sortOrder = !this.sortOrder;

			// Choose the correct history array based on the active button
			let historyArray = this.activeButton === 'details' ? this.historyArray : this.RevisionHistory;

			if (historyArray && historyArray.length > 0) {
				// Sort the history array by date and reverse if sortOrder is true
				this.currentHistoryArray = historyArray.sort((a: any, b: any) => {
				let dateA: any = new Date(a.createdAt || a.updatedAt);
				let dateB: any = new Date(b.createdAt || b.updatedAt);
				return dateA - dateB;
				});

				if (!this.sortOrder) {
					this.currentHistoryArray.reverse();
				}
			}
		},

		// showNewest() {
		// 	// this.showArray = true;
		// 	let array: any = [];

		// 	if (this.activeButton === 'details') {
		// 		if (this.sortOrder) {
		// 			this.currentHistoryArray = [];
		// 			this.historyArray.forEach((data: any) => {
		// 				let date = data.createdAt || data.updatedAt;
		// 				array.push(date);
		// 			});
		// 			let array1 = array.sort();
		// 			array1.forEach((rowJsonData: any) => {
		// 				this.historyArray.find((data: any) => {
		// 					if (rowJsonData == data.createdAt || rowJsonData == data.updatedAt) {
		// 						this.currentHistoryArray.push(data);
		// 					}
		// 				});
		// 			});
		// 			this.sortOrder = !this.sortOrder;
		// 		} else {
		// 			this.currentHistoryArray = [];
		// 			this.historyArray.forEach((data: any) => {
		// 				let date = data.createdAt || data.updatedAt;
		// 				array.push(date);
		// 			});
		// 			let array1 = array.sort().reverse();
		// 			array1.forEach((rowJsonData: any) => {
		// 				this.historyArray.find((data: any) => {
		// 					if (rowJsonData == data.createdAt || rowJsonData == data.updatedAt) {
		// 						this.currentHistoryArray.push(data);
		// 					}
		// 				});
		// 			});
		// 			this.sortOrder = !this.sortOrder;
		// 		}
		// 	} else if (this.activeButton === 'revision') {
		// 		if (this.sortOrder) {
		// 			this.currentHistoryArray = [];
		// 			this.RevisionHistory.forEach((data: any) => {
		// 				let date = data.createdAt || data.updatedAt;
		// 				array.push(date);
		// 			});
		// 			let array1 = array.sort();
		// 			array1.forEach((rowJsonData: any) => {
		// 				this.RevisionHistory.find((data: any) => {
		// 					if (rowJsonData == data.createdAt || rowJsonData == data.updatedAt) {
		// 						this.currentHistoryArray.push(data);
		// 					}
		// 				});
		// 			});
		// 			this.sortOrder = !this.sortOrder;
		// 		} else {
		// 			this.currentHistoryArray = [];
		// 			this.RevisionHistory.forEach((data: any) => {
		// 				let date = data.createdAt || data.updatedAt;
		// 				array.push(date);
		// 			});
		// 			let array1 = array.sort().reverse();
		// 			array1.forEach((rowJsonData: any) => {
		// 				this.RevisionHistory.find((data: any) => {
		// 					if (rowJsonData == data.createdAt || rowJsonData == data.updatedAt) {
		// 						this.currentHistoryArray.push(data);
		// 					}
		// 				});
		// 			});
		// 			this.sortOrder = !this.sortOrder;
		// 		}
		// 	}
		// },
		async showRevisionHistory() {
			this.currentHistoryArray = [];
			this.showArray = false;
			this.activeButton = 'revision';
			this.revisionList = true;
			await this.getPresentDocumentHistoryType2();
			this.currentHistoryArray = this.RevisionHistory.reverse()
			this.sortOrder = false;
		},
		formatTime(time: any) {
			let now = moment(time);
			return now.format('DD-MM-YYYY, hh:mm:ss');
		},
		getInitials(id: any) {
			let name = '';
			let user = this.optionsUserArray.find((obj: any) => {
				return obj.value == id;
			});
			if (user == undefined) {
				name = '';
				return name;
			} else {
				name = user.label;
				const parts = name.split(' ');
				return parts.map((part: any) => part[0].toUpperCase()).join('');
			}
		},
		getInitialsForHistory(item: any, key1: any, key2: any) {
			let mainKey: any;
			if (item[key2] == undefined) {
				mainKey = key1;
			} else {
				mainKey = key2;
			}

			let name = '';
			let user = this.optionsUserArray.find((obj: any) => {
				return obj.value == item[mainKey];
			});
			if (user == undefined) {
				name = '';
				return name;
			} else {
				name = user.label;
				const parts = name.split(' ');
				return parts.map((part: any) => part[0].toUpperCase()).join('');
			}
		},
		getUserName(id: any) {
			let name = '';
			let user = this.optionsUserArray.find((obj: any) => {
				return obj.value == id;
			});
			if (user == undefined) {
				name = '';
			} else {
				name = user.label;
			}
			return name;
		},

		toggleComments() {
			this.showComments = !this.showComments;
			if (this.showComments) {
				this.showControls = false;
				this.showDocumentInfo = false;
				this.showWorkFlow = false;
				this.comment.text = '';
			}
		},
		toggleControls() {
			this.showControls = !this.showControls;
			if (this.showControls) {
				this.showComments = false;
				this.showDocumentInfo = false;
				this.showWorkFlow = false;
			}
		},
		toggleWorkFlow() {
			this.showWorkFlow = !this.showWorkFlow;
			if (this.showWorkFlow) {
				this.showComments = false;
				this.showDocumentInfo = false;
				this.showControls = false;
			}
		},
		toggleDocumentInfo() {
			this.showDocumentInfo = !this.showDocumentInfo;
			if (this.showDocumentInfo) {
				this.showComments = false;
				this.showControls = false;
				this.showWorkFlow = false;
				this.cancelDocumentProperties();
			}
		},
		clickOnIcon(id: any) {
			this.selectedIconId = id;
			if (id == 1) {
				this.toggleDocumentInfo();
			} else if (id == 2) {
				this.toggleComments();
			} else if (id == 3) {
				this.toggleWorkFlow();
			} else if (id == 4) {
				if (this.$route.params.type == 'myDocuments') {
					this.dbcontrolsList();
				}
				this.controlsDataArray = [];
				this.selectedcontrolsData();
				this.toggleControls();
			}
		},
		dbcontrolsList() {
			let documentId = this.$route.params.documentId;
			let currentDocument: any = [];
			this.allDocumentsData.filter((data: any) => {
				if (data.controls) {
					if (data._id == documentId) {
						currentDocument.push(data.controls);
					}
				}
			});
			if (currentDocument[0]) {
				this.mappedControlsForDocument = currentDocument[0];
			}
		},
		async saveDocumentProperties(docDropdown: any) {
			this.v$.$touch();
			if (!this.v$.documentDropdown.$invalid) {
				let payloadObj = { ...this.presentDocumentData };
				let keys: any = ['documentOwner', 'statusCode', 'reviewPeriod', 'type', 'classification', 'version'];
				keys.map((key: any) => {
					payloadObj[key] = this.documentDropdown[key];
				});
				let docId = payloadObj._id;
				delete payloadObj._id;

				await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/update`, payloadObj).then((result: any) => {
					toast.info(`Uploaded succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
				});
				await this.getAllDocumentsList();
				await this.getPresentDocumentHistoryType2();
				await this.getPresentDocumentHistoryType1();
			}
		},
		cancelButtonForControls() {
			if (this.presentDocumentData.controls) {
				this.mappedControlsForDocument = this.presentDocumentData.controls;
			} else {
				this.mappedControlsForDocument = [];
			}
			// this.buttonDisable = false;
		},
		cancelDocumentProperties(){
			this.v$.$reset();
			this.documentDropdown = { ...this.dummyPresentDocumentData };
		},
		toggleBorderColor(event: any){
			// Check if the clicked element or any of its parents is the commentsContainer
			if (event.target.closest('.commentsContainer')) {
				this.divBorder = '1px solid black'; // Change border color to pink
			} else {
				this.divBorder = '1px solid #e9e9e9'; // Change border color to black
			}
		},
		handleClickOutside(event: any) {
			// Check if the clicked element is not the commentsContainer or any of its children
			if (!event.target.closest('.commentsContainer')) {
				this.divBorder = '1px solid #e9e9e9'; // Change border color to black
			}
		},
		showApproval() {
			let filteredPublishedUrls = this.presentDocumentData.documentUrl;
			if(filteredPublishedUrls && filteredPublishedUrls[filteredPublishedUrls.length - 1].url != undefined || filteredPublishedUrls && filteredPublishedUrls[filteredPublishedUrls.length - 1].content != undefined){
				this.showApprovalPopUp = true;
				console.log("this.presentDocumentData11111111111111111", this.presentDocumentData)
				
				let userObj: any = {};
				for (var i = 0; i < this.users.length; i++) {
					if (this.presentDocumentData.documentOwner === this.users[i]._id) {
						userObj = { label: this.users[i].name, value: this.users[i]._id };
					}
				}
				this.approvalComment.owner = userObj;
			} else{
				toast.error(`Add some content in Current Document`, {
					closeOnClick: true,
					closeButton: 'button'
				});
			}
		},
		async approveOrReject(status: any) {
			this.statusCode = status;
			this.approveAndRejectPopUp = true;
		},
		async renderPDF() {
			// const file = event.target.files[0];
			// const pdfViewer = this.$refs.content;
			// pdfViewer.innerHTML = '';
			// const docUrl = URL.createObjectURL(file);

			// this.presentDocumentData.documentUrl.forEach((eachUrlObj: any)=>{
			// 	console.log("eachUrlObj", eachUrlObj)
			// 	let filename = eachUrlObj.url.split('/').pop();
			// 	// Extract the file extension from the filename
			// 	let fileExtension = filename.split('.').pop();
			// 	console.log("fileExtension", fileExtension)
			// 	if(eachUrlObj.statusCode == 10413 && fileExtension === 'pdf'){
			// 		let toolBarStyle="#toolbar=0&navpanes=0"
			// 		let finalUrl=`${eachUrlObj.url}+${toolBarStyle}`
			// 		return this.url = finalUrl
			// 	} else {
			// 		console.log("application/pdf")
			// 	}
			// })
			this.renderUrl = [];

			let filteredPublishedUrls = this.presentDocumentData.documentUrl;

			if (filteredPublishedUrls && filteredPublishedUrls[filteredPublishedUrls.length - 1].url != undefined && !this.$route.path.includes('/edit/fileslist')) {
				let length = filteredPublishedUrls.length;
				let mainUrlObj = filteredPublishedUrls[length - 1];
				let mainUrl = mainUrlObj.url;
				let version = mainUrlObj.versionId;
				await this.clickedFileUrl(mainUrl, version, 'display');
				let getPresign = this.dynamicPresignedUrl;

				let filename = getPresign.split('/').pop();

				if (filename.includes('.pdf?')) {
					let finalUrl = `${getPresign}#toolbar=0&view=FitH`;
					this.renderUrl = finalUrl;
				} else {
					this.renderUrl = '';
				}
			} else if(this.$route.path.includes('/edit/fileslist')) {
				if (filteredPublishedUrls) {
					let documentsFilterList = filteredPublishedUrls.filter((doc:any)=>{ return doc.statusCode == 10413})
					console.log("documentsFilterListdocumentsFilterList", documentsFilterList)
						if(documentsFilterList && documentsFilterList[documentsFilterList.length - 1].url){
							let mainUrl = documentsFilterList[documentsFilterList.length - 1].url;
							let version = documentsFilterList[documentsFilterList.length - 1].versionId;
							await this.clickedFileUrl(mainUrl, version, 'display');
							let getPresign = this.dynamicPresignedUrl;

							let filename = getPresign.split('/').pop();

							if (filename.includes('.pdf?')) {
								let finalUrl = `${getPresign}#toolbar=0&view=FitH`;
								this.renderUrl = finalUrl;
							} else {
								this.renderUrl = '';
							}
						} else if(documentsFilterList && documentsFilterList[documentsFilterList.length - 1].content) {
							if(this.isBase64(documentsFilterList[documentsFilterList.length - 1].content)){
								documentsFilterList[documentsFilterList.length - 1].content = atob(documentsFilterList[documentsFilterList.length - 1].content)
							}
							this.currentDocument = documentsFilterList[documentsFilterList.length - 1]
						}
				}
			}
		},
		async publishAction() {
			let commentObj = {
				statusCode: 10413,
				comments: this.approvalComment.comments,
				createdBy: this.userInfo._id,
				createdAt: new Date(),
				documentId: this.$route.params.documentId
			};
			// let docId = this.$route.params.documentId;

			await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/dms`, commentObj).then(async (res: any) => {
				toast.info(`Published`, {
					closeOnClick: true,
					closeButton: 'button'
				});
				this.$router.push({ name: 'myDocuments' });
			});
			// await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/approvals`, commentObj).then(async (res: any) => {
			// 	toast.info(`Published`, {
			// 		closeOnClick: true,
			// 		closeButton: 'button'
			// 	});
			// 	this.$router.push({ name: 'myDocuments' });
			// });
		},
		async clickedFileUrl(cameLink: any, version: any, action: any) {
			let payload = { link: cameLink, versionId: version };
			this.dynamicPresignedUrl = '';
			await this.$http.post(`${process.env.VUE_APP_FILE_API_URL}/presignedurl`, payload).then(async (res: any) => {
				this.dynamicPresignedUrl = res.data;
				if (action == 'clickHere') {
					if(this.dynamicPresignedUrl != undefined){
						// Create a link element
						const link = document.createElement('a');
						link.href = this.dynamicPresignedUrl;
						console.log("lbnldwc", link.href)
						link.download;
						// link.click();
						// document.body.appendChild(link);
						window.open(this.dynamicPresignedUrl, '_blank');
					}
					// let anchor = document.createElement('a');
					// anchor.href = this.dynamicPresignedUrl;
					// anchor.click();
				} else {
					return res.data;
				}
			});
		},
		async addComment() {
			this.v$.$touch();
			if (!this.v$.comment.$invalid) {
				this.v$.$reset();
				let payload = { comment: this.comment.text, createdAt: new Date(), createdBy: this.userInfo._id };
				let id = this.$route.params.documentId;

				await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${id}/addComment`, payload).then(async (res: any) => {
					toast.info(`Saved succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
					this.comment.text = '';
					await this.getAllDocumentsList();
					this.savedComments = this.presentDocumentData.comments;
				});
			}
		},
		async mapControlsForDocument() {
			this.clickOnButton = true;
			let payload = this.mappedControlsForDocument;
			let id = this.$route.params.documentId;
			try{
				await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${id}/addControls`, payload).then(async (res: any) => {
					toast.info(`updated succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
					await this.getAllDocumentsList();
					this.mappedControlsForDocument = this.presentDocumentData.controls;
					// this.buttonDisable = true;
				});
			} catch(err){
				toast.error(`Error`, {
					closeOnClick: true,
					closeButton: 'button'
				});
			} finally {
				this.clickOnButton = false;
			}
		},
		// editComment(index: any) {
		// 	this.comment.text = this.savedComments[index].text;
		// 	this.comment.date = this.savedComments[index].date;
		// 	this.editedIndex = index;

		// 	// Change the background color of the edited comment
		// 	const commentDivs = document.querySelectorAll('.comment-item');
		// 	commentDivs.forEach((div, i) => {
		// 		if (i === index) {
		// 			div.classList.add('editedComment');
		// 		} else {
		// 			div.classList.remove('editedComment');
		// 		}
		// 	});

		// 	if (this.comment.text.trim() === '') {
		// 		alert('Error: Message cannot be empty');
		// 	}
		// },

		deleteComment(index: any) {
			if (this.editedIndex === index) {
				this.comment.text = '';
				this.editedIndex = -1;
			}
			this.savedComments.splice(index, 1);
			localStorage.setItem('savedComments', JSON.stringify(this.savedComments));
		},

		formatDate(dateString: string) {
			const dateObject = new Date(dateString);
			const day = dateObject.getDate();
			const month = dateObject.getMonth() + 1;
			const year = dateObject.getFullYear();
			const formattedDay = day < 10 ? '0' + day : day;
			const formattedMonth = month < 10 ? '0' + month : month;
			return `${formattedDay}-${formattedMonth}-${year}`;
		},
		async getAllArchiveDocumentsList() {
			await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/archivedocuments/getall`).then((res: any) => {
				this.getAllArchiveDocumentsJson = res.data;
				this.$store.dispatch('updateDotsLoading', false);
			});
		},
		async getAllDocumentsList() {
			this.previousDocuments = [];
			this.currentDocument = []
			if (this.$route.params.type == 'archivedocuments') {
				this.showFullToolbar = true;
				await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/archivedocuments/getall`).then((res: any) => {
					this.allDocumentsData = res.data;
					let present = this.allDocumentsData.find((obj: any) => {
						if (obj.statusCode == 10408 || obj.statusCode == 10410) {
							this.approveRejectBtns = false;
							this.publish = true;
						} else if (obj.statusCode == 10415) {
							this.approveRejectBtns = true;
						} else if (obj.statusCode == 10413) {
							this.approveRejectBtns = false;
							this.publish = false;
						}

						return obj._id == this.$route.params.documentId;
					});

					if (present.reviewPeriod == undefined || present.reviewPeriod == null) {
						present.reviewPeriod = '';
					}
					this.presentDocumentData = present;
					if (this.presentDocumentData.documentUrl !== undefined) {
						// Push all elements except the last one into previousDocuments
						this.presentDocumentData.documentUrl.slice(0, -1).forEach((obj: any) => {
							this.previousDocuments.push(obj);
						});

						// Push the last element into currentDocument
						const lastDocument = this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1];
						this.currentDocument = lastDocument;
					
					console.log("this.previousDocuments", this.previousDocuments)
					if(this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].content){
						this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].content = atob(this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].content)
					}
					}
					this.presentDocumentData.approvals ? (this.Approvals = this.presentDocumentData.approvals) : (this.Approvals = []);
					let keys: any = ['documentOwner', 'statusCode', 'reviewPeriod', 'type', 'classification', 'version'];
					keys.map((key: any) => {
						this.documentDropdown[key] = this.presentDocumentData[key];
					});
					this.dummyPresentDocumentData = { ...this.documentDropdown };
				});
			} else {
				await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/documents/getAll`).then((res: any) => {
					this.allDocumentsData = res.data;
					let present = this.allDocumentsData.find((obj: any) => {
						if (obj.statusCode == 10408 || obj.statusCode == 10410) {
							this.approveRejectBtns = false;
							this.publish = true;
						} else if (obj.statusCode == 10415) {
							this.approveRejectBtns = true;
						} else if (obj.statusCode == 10413) {
							this.approveRejectBtns = false;
							this.publish = false;
						}

						return obj._id == this.$route.params.documentId;
					});

					if (present.reviewPeriod == undefined || present.reviewPeriod == null) {
						present.reviewPeriod = '';
					}
					this.presentDocumentData = present;
					if(this.$route.path.includes('fileslist') || this.presentDocumentData.approvals && this.presentDocumentData.approvals[this.presentDocumentData.approvals.length - 1].approval != undefined && this.presentDocumentData.approvals[this.presentDocumentData.approvals.length - 1].approval != '' && this.presentDocumentData.approvals[this.presentDocumentData.approvals.length - 1].approval == this.userInfo._id || this.presentDocumentData.statusCode != 10411){
						this.showFullToolbar = true;
					} else{
						this.showFullToolbar = false;
					}
					if (this.presentDocumentData.documentUrl != undefined) {
						console.log("qwert", this.presentDocumentData.documentUrl.slice(0, -1))
						const urls = this.presentDocumentData.documentUrl;

						// Iterate through the array and push all except the last element
						urls.forEach((obj: any, index: number) => {
							console.log("index", index)
							console.log("obj", urls.length - 1)
							if (index < urls.length - 1) {
								console.log("qwertttttttt")
								this.previousDocuments.push(obj);
							}
						});

						// Push the last element into currentDocument
						const lastDocument = this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1];
						this.currentDocument = lastDocument;
					}
					console.log("this.previousDocuments", this.previousDocuments)
					console.log("this.presentDocumentData.", this.presentDocumentData)
					if(this.presentDocumentData.documentUrl && this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].content && this.isBase64(this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].content)){
						this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].content = atob(this.presentDocumentData.documentUrl[this.presentDocumentData.documentUrl.length - 1].content)
					}
					// this.changeBase64toHtml();
					this.presentDocumentData.approvals ? (this.Approvals = this.presentDocumentData.approvals) : (this.Approvals = []);
					let keys: any = ['documentOwner', 'statusCode', 'reviewPeriod', 'type', 'classification', 'version'];
					keys.map((key: any) => {
						this.documentDropdown[key] = this.presentDocumentData[key];
					});
					this.dummyPresentDocumentData = { ...this.documentDropdown };
				});
			}
			console.log("allDocumentsData", this.allDocumentsData)
			this.foldersDataArray = [
				...new Set(
					Object.values(this.allDocumentsData).map((x: any) => {
						x["folder"];
						return x["folder"];
					})
				)
			]

		},
		isBase64(str: any) {
			try {
				// Base64 strings are typically in lengths divisible by 4
				if (str.length % 4 === 0) {
					// Decoding and checking if it converts back cleanly
					const decoded = atob(str);
					// Return true if the decoded string re-encoded equals the original string
					return btoa(decoded) === str;
				} else {
					return false;
				}
			} catch (e) {
				return false;
			}
		},
		// changeBase64toHtml(){
		// 	if(this.presentDocumentData.content){
		// 		emitter.emit("documentEditorComments", this.presentDocumentData.content)
		// 	}
		// },
		async getAllControls() {
			await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`).then((result: { data: any }) => {
				this.allControlsData = result.data;
			});
		},
		async getPresentDocumentHistoryType1() {
			this.currentHistoryArray = [];
			let id: any = this.$route.params.documentId;
			this.RevisionHistory = [];
			this.historyArray = [];
			await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${id}/${1}/history`).then((res: any) => {
				this.presentDocumentHistoryObj = res.data;
				// console.log("11111111111", res.data);
				let reverseHistoryArr = [];
				reverseHistoryArr = [...res.data.history];
				// this.historyArray = reverseHistoryArr.sort().reverse();
				// this.RevisionHistory = res.data.history;
				reverseHistoryArr.map((obj: any) => {
					// if(obj.actionEntity == 10301){
					// 	this.historyArray.push(obj)
					// }
					if(obj.actionEntity == 10301 || obj.previousStatusCode && obj.statusCode && obj.previousStatusCode != obj.statusCode){
						this.historyArray.push(obj)
					}
					// if(!Object.keys(obj).includes('previousStatusCode')) {
					// 	this.RevisionHistory.push(obj);
					// }
				});
				this.historyArray = this.historyArray.sort().reverse();
			});
		},
		async getPresentDocumentHistoryType2() {
			this.currentHistoryArray = [];
			let id: any = this.$route.params.documentId;
			this.RevisionHistory = [];
			this.historyArray = [];
			await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${id}/${2}/history`).then((res: any) => {
				// this.presentDocumentHistoryObj = res.data;
				// console.log("11111111111", res.data);
				let reverseHistoryArr = [];
				reverseHistoryArr = [...res.data];
				// this.historyArray = reverseHistoryArr.sort().reverse();
				// this.RevisionHistory = res.data.history;
				reverseHistoryArr.map((obj: any) => {
					// if(obj.actionEntity == 10301){
					// 	this.historyArray.push(obj)
					// }
					// if(obj.actionEntity == 10301 || obj.previousStatusCode && obj.statusCode && obj.previousStatusCode != obj.statusCode){
					// 	this.historyArray.push(obj)
					// }
					if(!Object.keys(obj).includes('previousStatusCode')) {
						this.RevisionHistory.push(obj);
					}
				});
				// this.historyArray = this.historyArray.sort().reverse();
			});
		},
		async getAllUsers() {
			this.allUserArray = [];
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.users = res.data;
			});
			for (var i = 0; i < this.users.length; i++) {
				if (this.userInfo._id !== this.users[i]._id) {
					this.optionsUsersList.push({ label: this.users[i].name, value: this.users[i]._id });
				}
				this.allUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
				this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
			}
		},
		restrictCharacters(code:any){
			if(code === 'request'){
              if(this.approvalComment.comments.length > 308){console.log("dsfsjkdkj");
                this.displayErrorMessage = true;
              }else if(this.approvalComment.comments.length < 308 || this.approvalComment.comments.length == 308){
                this.displayErrorMessage = false;
              }

			} else if(code === 'approval'){
			  if(this.justification.length > 308){console.log("okijhf");
			  
                this.displayErrorMessage = true;
              }else if(this.justification.length < 308 || this.justification.length == 308){
                this.displayErrorMessage = false;
              }

			}
        },
		onCancelButtonAddPopUp(action: any) {
			if (action === 'close') {
				this.showApprovalPopUp = false;
				this.showPopUp = false;
				let userObj: any = [];
				for (var i = 0; i < this.users.length; i++) {
					if (this.presentDocumentData.documentOwner !== this.users[i]._id) {
						userObj.push({ label: this.users[i].name, value: this.users[i]._id });
					}
				}
				this.approvalComment.owner = userObj;
				this.approvalComment.comments = '';
				this.displayErrorMessage = false
				this.v$.$reset();
			}
		},
		cancelComments() {
			let userObj: any = [];
			for (var i = 0; i < this.users.length; i++) {
				if (this.presentDocumentData.documentOwner === this.users[i]._id) {
					userObj.push({ label: this.users[i].name, value: this.users[i]._id });
				}
			}
			this.approvalComment.owner = userObj;
			Object.keys(this.approvalComment).forEach((prop) => {
				if (prop != 'owner') {
					Array.isArray(this.approvalComment[prop]) ? (this.approvalComment[prop] = []) : (this.approvalComment[prop] = '');
				}
			});
			this.displayErrorMessage = false
		},
		async saveComments() {
			this.v$.$touch();
			if(!this.v$.approvalComment.$invalid && this.displayErrorMessage == false){
				this.clickOnButton = true;
				console.log("this.approvalComment.owner", this.approvalComment.owner)
				// if (!this.v$.approvalComment.$invalid) {
				let copyOfapprovalComment:any = {...this.approvalComment}
				let commentObj = {
					comments: copyOfapprovalComment.comments,
					approval: copyOfapprovalComment.owner.value,
					statusCode: 10415,
					createdBy: this.userInfo._id,
					createdAt: new Date(),
					documentId: this.$route.params.documentId
				};
				try{
					await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/dms`, commentObj).then(async (res: any) => {
						toast.info(`Saved succesfully`, {
							closeOnClick: true,
							closeButton: 'button'
						});
						await this.getAllDocumentsList();
						await this.getPresentDocumentHistoryType2();
						await this.getPresentDocumentHistoryType1();
						await this.showDetails();
						this.presentDocumentData.approvals ? (this.Approvals = this.presentDocumentData.approvals) : (this.Approvals = []);
					});
					this.showPopUp = false;
					this.showApprovalPopUp = false;
					this.approvalComment = {};
				} catch(err){
					toast.error(`Error`, {
						closeOnClick: true,
						closeButton: 'button'
					});
				} finally {
					this.clickOnButton = false;
				}
			}else{
				console.log("error in sending approval");
				// toast.error(`Error`, {
				// 	closeOnClick: true,
				// 	closeButton: 'button'
				// });
			}
			
			// let docId = this.$route.params.documentId;
			
			
			// await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/approvals`, commentObj).then(async (res: any) => {
			// 	toast.info(`Saved succesfully`, {
			// 		closeOnClick: true,
			// 		closeButton: 'button'
			// 	});

			
			// } else {
			// 	toast.info(`updated succesfully`, {
			// 		closeOnClick: true,
			// 		closeButton: 'button'
			// 	});
			// }
			this.v$.$reset();
		},
		CancelButtonPermissionPopUp(action: any) {
			if (action === 'close') {
				this.showPopUp = false;
				this.approveAndRejectPopUp = false;
				this.justification = this.justification.trim() !== '';
			this.justification = '';
			this.displayErrorMessage = false
			}
		},
		onCancelbtn(id: any) {
			this.justification = this.justification.trim() !== '';
			this.justification = '';
			this.displayErrorMessage = false
		},
		// downloadHtmlAsPdf(htmlContent: any, fileName: any) {
		// 	console.log("htmlContent", htmlContent)
		// 	let decodedHtmlData = atob(htmlContent)
		// 	console.log("decodedHtmlData",decodedHtmlData)
		// 	const blob = new Blob([decodedHtmlData], { type: 'text/html' });
		// 	const url = URL.createObjectURL(blob);

		// 	const a = document.createElement('a');
		// 	a.href = url;
		// 	a.download = `${fileName}.pdf`; // Set the file name with .pdf extension
		// 	a.click();

		// 	// Clean up
		// 	URL.revokeObjectURL(url);
		// },
		downloadHtmlAsPdf(htmlContent: any, fileName: any, action: any) {

			// Decode the base64 HTML content
			let decodedHtmlData 
			if(action == 'workHistory'){
				decodedHtmlData = htmlContent
			} else if(action == 'base64'){
				// decodedHtmlData = htmlContent;
				decodedHtmlData = atob(htmlContent);
			}

			// Initialize jsPDF with A4 size
			const pdf = new jsPDF({
				format: 'a4',
			});

			// Convert HTML to PDF
			pdf.html(decodedHtmlData, {
				callback: function (pdf) {
					// Create a Blob from the generated PDF
					const blob = pdf.output('blob');
					
					// Create an object URL from the Blob
					const url = URL.createObjectURL(blob);
					pdf.save(fileName);
					
					// Open the PDF in a new tab
					window.open(url, '_blank');
					
					// Optionally, revoke the object URL after a certain time to free up memory
					setTimeout(() => {
						URL.revokeObjectURL(url);
					}, 100);
				},
				x: 10, // Adjusted margins
				y: 10,
				margin: [10, 10, 10, 10], // Optional: Set margins (top, left, bottom, right)
				autoPaging: 'text',
				width: 190, // Maximum width for A4 page (210mm - 20mm for margins)
				windowWidth: 800 // Optional: Adjust based on your HTML content width
			});
		},
		async saveApproveAndRejectData(status: any) {
			if (!this.v$.justification.$invalid && this.displayErrorMessage == false) {
				this.clickOnButton = true;
				if (status == 10408) {
					let commentObj = {
						statusCode: status,
						comments: this.justification,
						createdBy: this.userInfo._id,
						createdAt: new Date(),
						documentId: this.$route.params.documentId
					};
					let docId = this.$route.params.documentId;
					// await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/approvals`, commentObj).then(async (res: any) => {
					// 	toast.info(`Saved succesfully`, {
					// 		closeOnClick: true,
					// 		closeButton: 'button'
					// 	});
					// 	await this.getAllDocumentsList();
					// });
					try{
						await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/dms`, commentObj).then(async (res: any) => {
							toast.info(`Approved`, {
								closeOnClick: true,
								closeButton: 'button'
							});
							await this.getAllDocumentsList();
							await this.getPresentDocumentHistoryType2();
							await this.getPresentDocumentHistoryType1();
						});
						this.approveAndRejectPopUp = false;
						this.$router.push({ name: 'approvals' });
					} catch(err){
						toast.error(`Error`, {
							closeOnClick: true,
							closeButton: 'button'
						});
					} finally {
						this.clickOnButton = false;
					}
					
				} else if (status == 10410) {
					let commentObj = {
						statusCode: 10411,
						comments: this.justification,
						createdBy: this.userInfo._id,
						createdAt: new Date(),
						documentId: this.$route.params.documentId
					};
					//let docId = this.$route.params.documentId;

					await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/dms`, commentObj).then(async (res: any) => {
						toast.error(`Rejected`, {
							closeOnClick: true,
							closeButton: 'button'
						});
						await this.getAllDocumentsList();
						await this.getPresentDocumentHistoryType2();
						await this.getPresentDocumentHistoryType1();
					});
					this.approveAndRejectPopUp = false;
					this.$router.push({ name: 'approvals' });
					// await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/approvals`, commentObj).then(async (res: any) => {
					// 	toast.info(`Saved succesfully`, {
					// 		closeOnClick: true,
					// 		closeButton: 'button'
					// 	});
					// 	await this.getAllDocumentsList();
					// });
				}
			} else {
				console.log("error in approving");
			
				// toast.error(`Error`, {
				// 	closeOnClick: true,
				// 	closeButton: 'button'
				// });
			}
		}
	}
});
</script>
<style scoped>
.no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
.bottomGap{
	bottom: 2rem;
}
/* .bottomGap::-webkit-scrollbar {
	width: 3px !important;
} */
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.heightStyles{

/* height: 70vh; */
/* border:2px solid blue; */
}
.mainContainer {
	background-color: #f9f9fb;
}
.header {
	border: 1px solid #e9e9e9;
	background-color: #ffffff;
}
.fileUrlClick {
	color: blue !important;
	font-weight: 600;
	text-decoration: underline !important;
}
.nodynamicwidth {
	margin-left: 10%;
}
.controlsFixedIcon {
	width: calc((100% - 195px) * (26 / 100));
	position: fixed;
	bottom: 39px;
	height: 10%;
	/* margin-bottom: 30px; */
	/* z-index: 1000; */
}
.bgColorAndColor {
	background-color: #152a43;
	color: white;
}
.inputboxstyling1 {
    display: block;
    width: 100%;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(233 233 233 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 300;
    --tw-text-opacity: 1;
    color: rgb(77 77 77 / var(--tw-text-opacity));
}
/* .bgColorForCommentBox {
	background-color: #ffffff;
	width: calc((100% - 195px) * (24.5 / 100));
	bottom: 8%;
} */
/* .commentsContainer {
	bottom: 8.5%; 
}  */
.centerContainer {
	height: calc(100vh - 100px);
}
.ScrollTextBox {
	height: 98%;
}
.borderBottomStyle {
	border-bottom: 1px solid #e9e9e9;
}
/* .commentscrollable-container {
	height: 57%;
} */
.commentSectionWidth {
	border-color: #e9e9e9;
	width: 96%;
}
.fontSizing {
	font-size: 10px;
}
.text-blue {
	color: #152a43;
}
/* .text-gray {
	color: rgba(77, 77, 77, var(--tw-text-opacity));
} */
/* .progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	top: -8px;
} */
.line {
	white-space: nowrap;
}
.line > span {
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 5px;
}
.key-span {
	display: inline-block;
	padding-right: 5px;
}
.borderStyle {
	border: 1px solid #e9e9e9;
}
.toppadding {
	margin-top: -26px;
}
.open-status {
	border-color: #c00000 !important;
	background-color: rgba(255, 0, 0, 0.2) !important;
}
.open-text {
	color: #c00000;
}
.approval-status {
	border-color: #ff9900;
	background-color: rgba(255, 192, 0, 0.2);
}
.approval-text {
	color: #ff9900;
}
.closed-status {
	border-color: #00b050;
	background-color: rgba(0, 176, 80, 0.2);
}
.closed-text {
	color: #00b050;
}
.draft-status {
	border-color: #92d050;
	background-color: rgba(146, 208, 80, 0.2);
}
.draft-text {
	color: #92d050;
}
.review-status {
	border-color: #c00000;
	background-color: rgba(192, 0, 0, 0.2);
}
.review-text {
	color: #c00000;
}
.button {
	border-color: rgba(0, 0, 0, 0.2);
}
.active {
	background-color: #152a43;
	color: white;
}
.taskEditButtons {
	background-color: #fff;
	border: 1px solid #4d4d4d;
	color: #4d4d4d;
}
.active-tab {
	background-color: #152a43;
	color: white;
}
.inactive-tab {
	color: black;
	fill: #152a43 !important;
}
.FirstLastLetterIcon {
	background-color: #152a43;
	color: white;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
}
.middleDiv {
	border: 1px solid #e9e9e9;
	background-color: #ffffff;
}
.middleDivScroll {
	max-height: 80vh;
}
.docMiddleDivScroll object, .previousDocStyle {
	width: 100%;
	/* height: 73vh; */
	height: calc(98vh - 150px);
}
.fileMiddleDivScroll object {
	width: 100%;
	/* height: 82vh; */
	height: calc(98vh - 150px);
}
.middleDivWidth {
	width: 70%;
}
.approvalScroll,
.workHistoryScroller {
	height: 68vh;
}
.approvalScroll::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}
.approvalScroll::-webkit-scrollbar-thumb {
	background: transparent;
}
.workHistoryScroller::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}
.workHistoryScroller::-webkit-scrollbar-thumb {
	background: transparent;
}
.middleDivScroll::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}
.middleDivScroll::-webkit-scrollbar-thumb {
	background: transparent;
}
.middleDiv-active {
	margin-left: 8px;
	/* width: 70% !important; */
	margin-left: 0px;
}
.rightSideBar {
	width: 34px !important;
	background-color: #ffffff;
}
.highlyConfidentialStyling {
	font-size: 10px;
}
/* .commentscrollable-container, */
.InnerDiv,
.controlsInnerDiv,
.workHistoryScroller,
.middleDivScroll,
.approvalScroll,
.workFlowScroll {
	overflow-y: auto;
	padding-bottom:4px;
	height: 75%;
}
.controlsInnerDiv::-webkit-scrollbar {
	width: 3px !important;
	background: transparent;
}
/* .controlsInnerDiv::-webkit-scrollbar-thumb {
	background: transparent;
} */
.commentscrollable-container::-webkit-scrollbar {
	width: 3px !important;
	background: transparent;
}
/* .commentscrollable-container::-webkit-scrollbar-thumb {
	background: transparent;
} */
.workFlowScroll::-webkit-scrollbar {
	width: 3px !important;
	background: transparent;
}
.heightOfInnerdiv{
	height: calc(98vh - 150px);
}
/* .workFlowScroll::-webkit-scrollbar-thumb {
	background: transparent;
} */
.InnerDiv::-webkit-scrollbar {
	width: 3px !important;
	background: transparent;
}
/* .InnerDiv::-webkit-scrollbar-thumb {
	background: transparent;
} */
.border-increased {
	border: 2px solid rebeccapurple;
	background-color: red;
}
.editorIconsStyle {
	border-left: 1px solid #e9e9e9;
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	background-color: #ffffff;
	width: 90%;
}
/* .editedComment {
	background-color: #e9e9e9;
} */
.addedMessage {
	word-wrap: break-word;
	overflow-wrap: break-word;
}
.steps{
	counter-reset: step;
    grid-auto-columns: 1fr;
    display: inline-grid;
    overflow: hidden;
    overflow-x: auto;
    grid-auto-flow: column;
}
.steps .step-primary + .step-primary:before,
.steps .step-primary:after {
	background-color: #152a43;
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23152A43'/%3E%3Cg clip-path='url(%23clip0_6921_34685)'%3E%3Cpath d='M12.6562 5.76738C12.4277 5.80605 12.5543 5.68828 10.0494 8.18965L7.73437 10.5029L6.67441 9.44648C5.69355 8.46738 5.60742 8.38652 5.50547 8.34258C5.18379 8.20195 4.80586 8.3127 4.61426 8.60273C4.5334 8.72227 4.5 8.84004 4.5 9C4.5 9.15469 4.5334 9.27246 4.60547 9.38496C4.63359 9.42891 5.25586 10.0652 5.98887 10.7965C7.44434 12.2502 7.3793 12.1939 7.62891 12.2326C7.77656 12.2572 7.91367 12.2344 8.0666 12.1641C8.17559 12.1131 8.32852 11.9637 10.7789 9.51328C13.2732 7.01894 13.377 6.91172 13.4332 6.79394C13.4877 6.67793 13.4912 6.66035 13.4912 6.47754C13.4912 6.29824 13.4877 6.27539 13.4367 6.16992C13.2979 5.87637 12.9814 5.71289 12.6562 5.76738Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_6921_34685'%3E%3Crect width='9' height='9' fill='white' transform='translate(4.5 4.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
	/**progress_step svg */
}
.steps .step:after {
	width: 18px;
	height: 18px;
}
.steps .step:before {
	background-color: hsl(216deg 6.72% 72.43%);
}
.steps-vertical{
	grid-auto-rows: 1fr;
    grid-auto-flow: row;
}
.steps-vertical .step:before {
	width: 2px;
	height: 156px;
}
.steps-vertical .step {
    gap: 1px;
    grid-template-columns: 40px 1fr;
    grid-template-rows: auto;
    min-height: 4rem;
    justify-items: start;
}
.step:after {
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23A4A4A4'/%3E%3Cg clip-path='url(%23clip0_6921_34699)'%3E%3Cpath d='M12.6562 5.76738C12.4277 5.80605 12.5543 5.68828 10.0494 8.18965L7.73437 10.5029L6.67441 9.44648C5.69355 8.46738 5.60742 8.38652 5.50547 8.34258C5.18379 8.20195 4.80586 8.3127 4.61426 8.60273C4.5334 8.72227 4.5 8.84004 4.5 9C4.5 9.15469 4.5334 9.27246 4.60547 9.38496C4.63359 9.42891 5.25586 10.0652 5.98887 10.7965C7.44434 12.2502 7.3793 12.1939 7.62891 12.2326C7.77656 12.2572 7.91367 12.2344 8.0666 12.1641C8.17559 12.1131 8.32852 11.9637 10.7789 9.51328C13.2732 7.01894 13.377 6.91172 13.4332 6.79394C13.4877 6.67793 13.4912 6.66035 13.4912 6.47754C13.4912 6.29824 13.4877 6.27539 13.4367 6.16992C13.2979 5.87637 12.9814 5.71289 12.6562 5.76738Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_6921_34699'%3E%3Crect width='9' height='9' fill='white' transform='translate(4.5 4.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.error {
	border-color: red;
}
.iconContainerWidth {
	width: 30%;
}
.nk-icon {
	top: -2px;
	left: -20px;
}
.editedMessage {
	color: #4d4d4d;
}
.rejectbtn {
	background-color: #c00000;
}
.approvebtn {
	background-color: #00b050;
}
.steps .step {
	/* display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-columns: 40px 1fr;
	grid-template-rows: repeat(1, minmax(0, 1fr));
	grid-template-rows: auto;
	gap: 0.5rem; */
	min-height: 1rem;
	/* justify-items: start; */
	position: relative;
	top: -34px;
}
.workflow {
	text-align: start;
}
.status_box {
	padding-top: 48px;
}
.date_settings {
	font-size: x-small;
	text-align: start;
}
.disablestep {
	margin-top: -3px;
}
.btnprimary1 {
	cursor: pointer;
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-family: Open Sans;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.placeholder-styles {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 14px !important;
	color: #4d4d4d;
}
.label {
	font-size: 12px !important;
}
.controllabel {
	font-size: 12px !important;
	font-weight: 600 !important;
}
.disabled-input {
	opacity: 0.7;
	pointer-events: none;
}
.commentscrollable-container {
	overflow-y: auto;
	padding-bottom:4px;
	height: calc(100vh - 80% + var(--scroll-done-value) - 25px);
}
.alignmentDocument{
	right: 43%;
}
</style>
