<template>
    <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center ">
            <li v-for="crumb,crumbIndex in routesArray" :key="crumbIndex">
                <div class="flex items-center">
                    <div>
                        <a :class="crumbIndex == (routesArray.length-1)?'disabled pointer-events-none':''" href="#" @click="navigate(crumb)" class="crumbSize font-medium text-gray-700 hover:text-blue-600">{{crumb.name}}</a>
                    </div>
                    <div v-if="crumbIndex !== (routesArray.length-1)">
                        <img src="@/assets/rightArrowFilled.svg" class="w-2.5 h-2.5 mx-1.5 mt-1 text-gray-400" />
                    </div>
                </div>
            </li>
        </ol>
    </nav>   
</template>
<style scoped>
.crumbSize {
    font-size: 12px;
    line-height: 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '@/main';

export default defineComponent({
    props: ['routesArray','navIndexNums'],
    data(): any {
        return {
            navIndexes:{},
        };
    },
    
    methods:{
        navigate(crumb:any){
            this.$store.dispatch('selectedRoute', crumb.name);
            this.$router.push({ name: crumb.routeName });
        }
    }
})
</script>
