<template>
	<div >
	<h1>hello</h1>
	</div>
</template>
<style scoped>

</style>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	
});
</script>