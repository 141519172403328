<template>
	<div class="pt-3 pl-5 pr-5">
		<breadCrumb :routesArray="routesArray"/>
		<div class="flex items-center justify-between flex-wrap mt-2">
			<div class="title flex items-center">
				<h1>Documents</h1>
			</div>
			<div v-if="filesDataFiltered" class="flex items-center">
				<button class="flex" :class="filesDataFiltered.length === 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
					Filters
					<img src="../assets/filter.svg" class="h-3 ml-2" />
				</button>
			</div>
		</div>
		<p class="headerText mt-3">
			Document management, often referred to as Document Management Systems (DMS), is the use of a computer system and software to store, manage, and track electronic documents and electronic images of paper-based information captured through the use of a document scanner. Document management
			is how your organization stores, manages, and tracks its electronic documents. According to ISO 12651-2, a document is "recorded information or object which can be treated as a unit."
		</p>
		<div class="mt-5">
			<newTable :tableRowData="filesDataFiltered" :headersData="fileHeaders" :navigate="true" :fileNavigateId= '10002' :loadData="dataLoading"></newTable>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import newTable from '@/components/newTable.vue';
import breadCrumb from '../components/breadCrumb.vue';
import { emitter } from '@/main';
import { mapGetters } from 'vuex';

export default defineComponent({
	data(): any {
		return {
			filesData: [],
			routesArray: [],
			fileHeaders: ['fileImage','documentName', 'version', 'type'],
			filesDataFiltered: [],
			documentJSONData: [],
			allDocumentsData: [],
			filterUsingText:'',
			dataLoading: true,
		};
	},
	computed: {
		...mapGetters({ dotsLoading: 'dotsLoading', userInfo: 'userInfo' })
	},
	methods: {
		displayBreadcrumbs(data: any) {
			this.routesArray = [
				{ name: 'Documents List', routeName: 'documentslist' },
				{ name: data.title, routeName: 'fileslist' }
			];
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		groupBy(arr: any[], key: string | number) {
            return arr.reduce((group: any, product: any) => {
                const { folder } = product;
                group[folder] = group[folder] ?? [];
                group[folder].push(product);
                return group;
            }, {});
        },
		async getAllDocumentsList() {
			this.dataLoading = true
			this.allDocumentsData = [];
			await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/publisheddocuments/getAll`).then((res: any) => {
				// const filteredDocs = res.data.filter((doc: any) => 
				// this.userInfo.currentRole === 10207 || 
				// (this.userInfo.currentRole === 10208 && doc.author === this.userInfo._id)
				// );
				let filteredDocs :any = []
				res.data.forEach((eachDoc: any) => {
					if (this.userInfo.currentRole == 10207 && eachDoc.statusCode != 10412) {
						filteredDocs.push(eachDoc);
					} else if (this.userInfo.currentRole == 10208 && eachDoc.author == this.userInfo._id && eachDoc.statusCode != 10412) {
						filteredDocs.push(eachDoc);
					}
				});
				this.allDocumentsData.push(...filteredDocs);
				const groupedDocuments = this.groupBy(this.allDocumentsData);
				
				let documentsArrays: any = []
				
				documentsArrays = Object.values(groupedDocuments)
				let finalArray: any = []
				documentsArrays.forEach((eachArr: any)=>{
					eachArr.map((eachObj: any)=>{
						if(eachObj.folder == this.documentJSONData.title){
							finalArray.push(eachObj)
						} else {
							console.log("eachObjeachObjeachObj")
						}
					})
				})
				// if(!this.filterUsingText.trim()){
				// 	console.log("text not entered");
				// 	this.filesDataFiltered = finalArray;
				// }
				if (this.filterUsingText === '' || this.filterUsingText === null) {
					console.log('text not entered');
					finalArray.filter((data: any) => {
						if (data.statusCode != 10412) {
							this.filesDataFiltered = finalArray;
						}
					});
				} else {
					let filesListPrimary:[]
					filesListPrimary=finalArray.filter((file: any) => file.documentName.toLowerCase().includes(this.filterUsingText));
					if(filesListPrimary.length===0)
					{
						this.filesDataFiltered=finalArray;
					}
					else{
						this.filesDataFiltered=filesListPrimary;
					}

				}
				// this.$store.dispatch('updateDotsLoading', false);
				this.dataLoading = false
			});
		},
		getSearchText()
		{
			this.filterUsingText=this.$store.state.filterText;
			// emitter.on('searchTextForDocuments',(searchText:any)=>this.filterUsingText=searchText);
		}
	},
	components: {
		breadCrumb,
		newTable
	},
	async mounted() {
		// this.filesDataFiltered = this.filesData.find((item: any) => {
		// 	if (item.id === this.$router.currentRoute._rawValue.params.id) {
		// 		return item;
		// 	}
		// });
		// this.$store.dispatch('updateDotsLoading', true);
		this.dataLoading = true
		this.getSearchText();
		const documentData:any = localStorage.getItem('DOCUMENT_DATA');
        this.documentJSONData = JSON.parse(documentData);
		await this.getAllDocumentsList();
		this.displayBreadcrumbs(this.documentJSONData);
		this.dataLoading = false
		
		
	}
});
</script>
