<template>
    <div>
        <div>
            <breadCrumb :routesArray="routesArray" class="ml-5 mt-6" />
            <div class="font-bold pl-5 p-2">Document History</div>
            <div class="font-medium pl-5 headerText">
                A risk assessment is a process to identify potential hazards and analyze what could happen if a hazard occurs. A business impact analysis (BIA) is the process for determining the potential impacts resulting from the interruption of time sensitive or critical business processes. A
                risk assessment is a process to identify potential hazards and analyze what could happen if a hazard occurs. A business impact analysis (BIA) is the process for determining the potential impacts resulting from the interruption of time sensitive or critical business processes.
            </div>
            <div class="card mt-4 m-4">
                <div class="document-container border-b py-4 px-8 flex justify-between items-center" style="border-color: rgba(0, 0, 0, 0.1)">
                    <p class="text-lg font-bold">Name of the Document</p>
                    <div class="flex">
                        <div class="flex parent-toggle-border border rounded-sm">
                            <button :class="{ 'active-tab': activeButton === 'revision', 'inactive-tab': activeButton !== 'revision' }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-7" style="font-size: x-small" @click="showRevisionHistory">
                                <div v-if="activeButton === 'revision'"></div>
                                Revision History
                            </button>
                            <button :class="{ 'active-tab': activeButton === 'details', 'inactive-tab': activeButton !== 'details' }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-7" style="font-size: x-small" @click="showDetails">
                                <div v-if="activeButton === 'details'"></div>
                                Details
                            </button>
                        </div>
                        <button v-if="sortOrder" class="taskEditButtons w-24 h-7 border rounded-sm flex items-center justify-center p-2 mx-3.5 font-semibold" @click="showNewest">
                            <img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
                            Newest
                        </button>
                        <button v-else class="taskEditButtons w-24 h-7 border border rounded-sm flex items-center justify-center p-2 mx-3.5 font-semibold" @click="showNewest">
                            <img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
                            Oldest
                        </button>
                    </div>
                </div>
                <div v-if="showArray">
                    <div v-for="(item, index) in currentArray" :key="index" class="border-b py-4 px-8" style="border-color: rgba(0, 0, 0, 0.1)">
                        <div class="initials-container">
                            <div class="rounded-full h-8 w-8" style="background-color: #152a43; color: white; display: flex; justify-content: center; align-items: center; margin-right: 8px">
                                <span class="font-bold text-sm">{{ getInitials(item.createdBy) }}</span>
                            </div>
                        </div>
                        <p class="toppadding px-11">
                            <span v-for="(value, key) in item" :key="key" class="line">
                                <template v-if="key === 'createdBy'">
                                    <span class="font-bold">{{ value }}</span> created the document
                                </template>
                                <template v-else-if="key === 'createdAt'">
                                    <span class="font-bold pl-1">{{ value }}</span> at
                                </template>
                                <template v-else-if="key === 'time'">
                                    <span class="font-bold">{{ value }}</span
                                    ><br />
                                </template>
                                <div v-else-if="key === 'statusCode' || key === 'updatedCode'" class="pl-5 pt-2 pb-2">
                                    <div :style="{ float: key === 'statusCode' || key === 'updatedCode' ? 'left' : 'none', 'margin-right': key === 'statusCode' || key === 'updatedCode' ? '25px' : '0', 'margin-top': key === 'updatedCode' ? '-16px' : '0' }">
                                        <div class="w-24 h-5 border rounded-sm flex justify-center items-center" :class="getStatusClass(value)">
                                            <div class="status-text" :class="getStatusClass1(value)">
                                                {{ key === 'statusCode' ? getStatusText(value) : getStatusText1(value) }}
                                            </div>
                                        </div>
 
                                        <!-- Add the arrow image between statusCode and updatedCode -->
                                        <img src="@/assets/arrowRight.svg" v-if="key === 'statusCode'" style="padding-left: 120px; margin-top: -16px" />
                                        <div v-if="key === 'updatedCode'" style="margin-left: 160px !important; margin-top: -35px !important"></div>
                                        <!-- Content for updatedCode -->
                                    </div>
                                </div>
 
                                <template v-else>
                                    <span class="key-span">{{ key.replace(/([a-z])([A-Z])/g, '$1 $2') }}</span> <span class="colon">:</span> <span class="font-bold">{{ value }}</span
                                    ><br />
                                </template>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
 
<script lang="ts">
import { defineComponent } from 'vue';
import breadCrumb from '../components/breadCrumb.vue';
 
export default defineComponent({
    components: {
        breadCrumb
    },
    data(): any {
        return {
            breadCrumbRoutes: [{ name: 'Name of the Document ', routeName: 'Name of the Document' }],
            revisionHistory: [],
            details: [],
            showArray: false,
            currentArray: [],
            activeButton: null,
            breadcrumbIndex: 0,
            props: {
                routesArray: Array
            },
            history: [
                { createdBy: 'Nikhil Kapuganti', createdAt: 'Aug 23, 2023', time: '2:46 PM', Version: '1.0', DocumentID: 'AA01010011', DocumentName: 'Policies and Guidelines of Human resources', Author: 'Nikhil Kapuganti' },
                { createdBy: 'Nikhil Kapuganti', createdAt: 'Aug 24, 2023', time: '2:47 PM', statusCode: 10142, updatedCode: 10143 },
                { createdBy: 'Abhiram Kadari', createdAt: 'Aug 22, 2023', time: '2:48 PM', statusCode: 10141, updatedCode: 10144 },
                { createdBy: 'Nikhil Kapuganti', createdAt: 'Aug 25, 2023', time: '2:42 PM', Version: '1.1', DocumentID: 'AA01010011', DocumentName: 'Policies and Guidelines of Human resources', Author: 'Nikhil Kapuganti' }
            ],
            RevisionHistory: [
                { createdBy: 'Nikhil Kapuganti', createdAt: 'Aug 22, 2023', time: '2:46 PM', Version: '1.0', DocumentID: 'AA01010011', DocumentName: 'Policies and Guidelines of Human resources', Author: 'Nikhil Kapuganti' },
                { createdBy: 'Nikhil Kapuganti', createdAt: 'Aug 24, 2023', time: '2:46 PM', Version: '1.1', DocumentID: 'AA01010011', DocumentName: 'Policies and Guidelines of Human resources', Author: 'Nikhil Kapuganti' }
            ],
            sortOrder: true
        };
    },
    methods: {
        showDetails() {
            this.activeButton = 'details';
            this.showArray = true;
            this.currentArray = this.history;
            this.routesArray = [
                { name: 'Name of the Document', routeName: 'Name of the Document' },
                { name: 'Details', routeName: 'Details' }
            ];
            this.sortOrder=true;
        },
        showNewest() {
            this.showArray = true;
            this.sortOrder = !this.sortOrder;
            if (this.activeButton === 'details') {
                this.currentArray = this.history.sort((a: any, b: any) => {
                    const dateA = new Date(a.createdAt + ' ' + a.time).getTime();
                    const dateB = new Date(b.createdAt + ' ' + b.time).getTime();
                    return this.sortOrder ? dateB - dateA : dateA - dateB;
                });
            } else if (this.activeButton === 'revision') {
                this.currentArray = this.RevisionHistory.sort((a: any, b: any) => {
                    const dateA = new Date(a.createdAt + ' ' + a.time).getTime();
                    const dateB = new Date(b.createdAt + ' ' + b.time).getTime();
                    return this.sortOrder ? dateB - dateA : dateA - dateB;
                });
            }
        },
        showRevisionHistory() {
            this.activeButton = 'revision';
            this.showArray = true;
            this.currentArray = this.RevisionHistory;
            this.routesArray = [
                { name: 'Name of the Document', routeName: 'Name of the Document' },
                { name: 'Revision History', routeName: 'Revision History' }
            ];
            this.sortOrder=true;
        },
        getInitials(name: any) {
            const parts = name.split(' ');
            return parts.map((part: any) => part[0].toUpperCase()).join('');
        },
        getStatusClass(statusCode: any) {
            const statusText = this.getStatusText(statusCode);
            switch (statusText.toLowerCase()) {
                case 'draft':
                    return 'draft-status';
                case 'in review':
                    return 'review-status';
                case 'open':
                    return 'open-status';
                case 'approval':
                    return 'approval-status';
                case 'closed':
                    return 'closed-status';
                default:
                    return '';
            }
        },
        getStatusClass1(statusCode: any) {
            const statusText = this.getStatusText(statusCode);
            switch (statusText.toLowerCase()) {
                case 'draft':
                    return 'draft-text';
                case 'in review':
                    return 'review-text';
                case 'open':
                    return 'open-text';
                case 'approval':
                    return 'approval-text';
                case 'closed':
                    return 'closed-text';
                default:
                    return '';
            }
        },
        getStatusText(statusCode: any) {
            switch (statusCode) {
                case 10140:
                    return 'Open';
                case 10141:
                    return 'Approval';
                case 10142:
                    return 'Draft';
                case 10143:
                    return 'In Review';
                case 10144:
                    return 'Closed';
                default:
                    return '';
            }
        },
        getStatusText1(updatedCode: any) {
            switch (updatedCode) {
                case 10140:
                    return 'Open';
                case 10141:
                    return 'Approval';
                case 10142:
                    return 'Draft';
                case 10143:
                    return 'In Review';
                case 10144:
                    return 'Closed';
                default:
                    return '';
            }
        }
    },
    mounted() {
        this.showDetails();
        const storedActiveButton = localStorage.getItem('activeButton');
        const storedShowArray = localStorage.getItem('showArray');
        const storedCurrentArray = localStorage.getItem('currentArray');
 
        if (storedActiveButton && storedShowArray && storedCurrentArray) {
            this.activeButton = storedActiveButton;
            this.showArray = JSON.parse(storedShowArray);
            this.currentArray = JSON.parse(storedCurrentArray);
        }
    }
});
</script>
<style scoped>
.line {
    white-space: nowrap;
}
.line > span {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}
.key-span {
    display: inline-block;
    padding-right: 5px;
}
.toppadding {
    margin-top: -26px;
}
.open-status {
    border-color: #ff0000 !important;
    background-color: rgba(255, 0, 0, 0.1) !important;
}
.open-text {
    color: #ff0000;
}
.approval-status {
    border-color: #ffc000;
    background-color: rgba(255, 192, 0, 0.1);
}
.approval-text {
    color: #ffc000;
}
.closed-status {
    border-color: #00b050;
    background-color: rgba(0, 176, 80, 0.1);
}
.closed-text {
    color: #00b050;
}
.draft-status {
    border-color: #92d050;
    background-color: rgba(146, 208, 80, 0.1);
}
.draft-text {
    color: #92d050;
}
.review-status {
    border-color: #c00000;
    background-color: rgba(192, 0, 0, 0.1);
}
.review-text {
    color: #c00000;
}
.button {
    border-color: rgba(0, 0, 0, 0.1);
}
.active {
    background-color: #152a43;
    color: white;
}
.taskEditButtons {
    background-color: #fff;
    border: 1px solid #4d4d4d;
    color: #4d4d4d;
    padding: 8px;
}
.active-tab {
    background-color: #152a43;
    color: white;
}
 
.inactive-tab {
    color: black;
    fill: #152a43 !important;
}
.parent-toggle-border {
    border: 1px solid #e9e9e9;
}
 
.card-box {
    background-color: white;
 
}
</style>
 