<template>
    <div id="body-container">
        <navbar />
        <div class="flex" @click="closeApps()">
            <div :class="{ 'w-40': isToggling, 'w-10': !isToggling }">
                <sidemenubar />
            </div>
            <div class="content flex-1 w-full h-screen overflow-y-scroll pb-12" :class="{ toggledstate: isToggling }" @scroll="handleScroll($event)">
                <div :class="nomargincondtion ? 'noMarginBottom':'page'">
                    <slot />
                </div>
                <Footer />  
            </div>
        </div>
    </div>
</template>
<style  scoped>
.noMarginBottom{
    margin-bottom: 30px;
}
.page
{
    margin-bottom: 80px;
}
</style>
 <script lang="ts">
import navbar from '@/components/navbar.vue';
import sidemenubar from '@/components/sidemenubar.vue';
import Footer from '@/components/footer.vue'
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
    components: {
        navbar,
        sidemenubar,
        Footer
    },
    data() {
        return {
            isToggling: true,
            appsVisible:false,
            nomargincondtion:false,
        };
    },
    computed:{
        ...mapGetters({ selectedOptionName: 'val',toggleState:'toggle' })
    },
    methods:{
        closeApps(){
            this.appsVisible=false;
        emitter.emit('apps', this.appsVisible);
        },
		handleScroll(event:any) {
            const target = event.target as HTMLElement;
            const scrollTop = target.scrollTop;
            this.$store.dispatch('scrollDone', scrollTop);
			if (this.$route.path.includes('/myDocuments')){
				emitter.emit('close-dropdown'); // Emit event to close dropdown on scroll
			}
		}
    },
 
    mounted() {
        this.isToggling = this.toggleState;
        emitter.on('toggle-sidebar', (isToggle: any) => {
            this.isToggling = isToggle;
        });
        if(this.$route.path.includes('editor/document')){
            this.nomargincondtion = true
        }else{
            this.nomargincondtion = false
        }
    }
});
</script>