<template>
	<div class="pt-3 pl-5 pr-5">
		<div v-if="dotsLoading || pageStatus" class="mt-2">
			<div class="flex items-center justify-center w-full h-full skeleton mt-4 tableCornerRadius bg-white">
				<dots-loader />
			</div>
		</div>
		<div v-else>
			<div class="items-center justify-between flex">
				<div class="title flex items-center">
					<h1>Document Management System</h1>
				</div>
				<div class="flex items-center">
					<!-- <button class="btnprimary ml-2" @click="importDoc()">Import</button> -->
					<!-- <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" /> -->
					<button class="btnprimary flex items-center" @click="showPopupByAdd">Add</button>
					<button class="ml-2 flex" @click="filterOpen" :class="allDocumentsData.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
				</div>
			</div>
			<div class="headerText mt-3">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
			</div>

			<new-table :tableRowData="allDocumentsData" :headersData="Headers" :openPopup="true" :loadData="dataLoading"></new-table>

			<popup class="userPopup" v-if="popupStatus.permissionsPopUp && dynamicPopUp == 'permissions'" :popUp="popupStatus.permissionsPopUp && dynamicPopUp == 'permissions'">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Permissions</h1>
						<button class="float-right text-3xl" @click="CancelButtonPermissionPopUp('close')">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12 ">
											<label class="controllabel">Access</label>
											<select
												class="inputboxstylingForSelect mt-2"
												@change="globalAccess"
												v-model="popupData.type"
												:class="{
													'is-invalid': v$.popupData.type.$error,'inputboxstylingForSelectAtPopup': popupData.type === '','stylingForInputboxstylingForSelectAtPopup': popupData.type !== ''
												}"
											>
												<option value="" selected disabled hidden>Please select</option>
												<option class="optionColors" v-for="option in type" :key="option.id" :value="option.typeId">{{ option.data }}</option>
											</select>
											<div v-if="v$.popupData.type.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.type.required.$invalid">Required</div>
											</div>
										</div>

										<div v-if="popupData.type == 3" class="w-3/6 leftspacing">
											<label class="controllabel">Entitys</label>
											<div class="mt-2">
											<multiselect
												v-model="popupData.entity"
												:options="entityOptions"
												:showNoOptions="false"
												@search-change="asyncFind3"
												:limit-text="limitText"
												:multiple="false"
												:hide-selected="true"
												:close-on-select="true"
												:clear-on-select="true"
												open-direction="bottom"
												label="label"
												track-by="value"    
												:searchable="true"
												:hideArrow="false"
												placeholder="Entity"
												class="custom-multiselect userSelect"
												:class="{
													'is-invalid borderRed': v$.popupData.entity.$error,
													'has-options': entityOptions && entityOptions.length > 0,
													'no-options':  !entityOptions.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</multiselect>
										</div>
											<!-- <select
												class="inputboxstyling mt-2"
												v-model="popupData.entity"
												@change="entityTeams(popupData.entity)"
												:class="{
													'is-invalid': v$.popupData.entity.$error
												}"
											>
												<option value="" selected disabled hidden>Please select</option>
												<option v-for="option in entitesDataArray" :key="option._id" :value="option._id">{{ option.entityName }}</option>
											</select> -->
											<div v-if="v$.popupData.entity.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.entity.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="popupData.type == 2" class="w-3/6 leftspacing">
											<label class="controllabel">Region Access</label>
											<div class="mt-2">
											<multiselect
												v-model="popupData.region"
												:options="regionOptions"
												:showNoOptions="false"
												@search-change="asyncFind1"
												@select="RegionAccesshandler(popupData.region.value)"
												:limit-text="limitText"
												:multiple="false"
												:hide-selected="true"
												:close-on-select="true"
												:clear-on-select="true"
												open-direction="bottom"
												label="label"
												track-by="value"    
												:searchable="true"
												:hideArrow="false"
												placeholder="Region Access"
												class="custom-multiselect userSelect"
												:class="{
													'is-invalid borderRed': v$.popupData.region.$error,
													'has-options': regionOptions && regionOptions.length > 0,
													'no-options':  !regionOptions.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</multiselect>
										</div>
											<!-- <select
												class="inputboxstyling mt-2"
												v-model="popupData.region"
												@change="RegionAccesshandler(popupData.region)"
												:class="{
													'is-invalid': v$.popupData.region.$error
												}"
											>
												<option value="" selected disabled hidden>Please select</option>
												<option v-for="option in regionDataArray" :key="option._id" :value="option._id">{{ option.region }}</option>
											</select> -->
											<div v-if="v$.popupData.region.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.region.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="popupData.type == 1" class="w-3/6 leftspacing">
											<label class="controllabel">Global Access</label>
											<select
												class="inputboxstylingForSelect mt-2"
												v-model="popupData.target_audience"
												@change="handleAccessLevelTwoChange"
												:class="{
													'is-invalid': v$.popupData.target_audience.$error,'inputboxstylingForSelectAtPopup': popupData.target_audience === '','stylingForInputboxstylingForSelectAtPopup': popupData.target_audience !== ''
												}"
											>
												<option value="" selected disabled hidden>Please select</option>
												<option class="optionColors" v-for="option in accessLevelTwo" :key="option.id" :value="option.target_audience_id">{{ option.data }}</option>
											</select>
											<div v-if="v$.popupData.target_audience.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.target_audience.required.$invalid">Required</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div v-if="popupData.type == 1 && popupData.target_audience == 1" class="w-6/12">
											<label class="controllabel ">Teams</label>
											<div class="mt-2">
											<Multiselect
												v-model="popupData.teams"
												:options="teamsOptionsArr"
												@search-change="asyncTeamsFind"
												:multiple="false"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"
												:searchable="true"
												:hideArrow="false"
												deselectLabel=""
												selectLabel=""
												class="custom-multiselect"
												:showNoOptions="false"
												placeholder="Suggest Teams"
												:class="{
													'is-invalid redBorder': v$.popupData.teams.$error,
													'has-options': teamsOptionsArr && teamsOptionsArr.length > 0,
													'no-options':  !teamsOptionsArr.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</Multiselect>
										</div>
											<div v-if="v$.popupData.teams.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.teams.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="popupData.type == 1 && popupData.target_audience == 2" class="w-6/12">
											<label class="controllabel ">Users</label>
											<Multiselect
												v-model="popupData.users"
												:options="userOptionsArr"
												@search-change="asyncUsersFind"
												:multiple="false"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"
												:searchable="true"
												:hideArrow="false"
												deselectLabel=""
												selectLabel=""
												class="custom-multiselect"
												:showNoOptions="false"
												placeholder="Suggest Users"
												:class="{
													'is-invalid redBorder': v$.popupData.users.$error,
													'has-options': userOptionsArr && userOptionsArr.length > 0,
													'no-options':  !userOptionsArr.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</Multiselect>

											<div v-if="v$.popupData.users.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.users.required.$invalid">Required</div>
											</div>
										</div>
										<!-- {{popupData.entity}} -->
										<div v-if="popupData.type == 2 && popupData.region" class="w-6/12">
											<label class="controllabel ">Entity</label>
											<div class="mt-2">
											<multiselect
												v-model="popupData.entity"
												:options="entityOptions"
												:showNoOptions="false"
												@search-change="asyncFind2"
												:limit-text="limitText"
												:multiple="false"
												:hide-selected="true"
												:close-on-select="true"
												:clear-on-select="true"
												open-direction="bottom"
												label="label"
												track-by="value"    
												:searchable="true"
												:hideArrow="false"
												placeholder="Entity"
												class="custom-multiselect userSelect"
												:class="{
													'is-invalid borderRed': v$.popupData.entity.$error,
													'has-options': entityOptions && entityOptions.length > 0,
													'no-options':  !entityOptions.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</multiselect>
										</div>
											<!-- <select
												class="inputboxstyling mt-2"
												v-model="popupData.entity"
												@change="entityTeams(popupData.entity)"
												:class="{
													'is-invalid': v$.popupData.entity.$error
												}"
											>
												<option value="" selected disabled hidden>Please select</option>
												<option v-for="option in entityData1" :key="option.id" :value="option._id">{{ option.entityName }}</option>
											</select> -->
											<div v-if="v$.popupData.entity.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.entity.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="popupData.type == 2 && popupData.region && popupData.entity" class="w-3/6 leftspacing">
											<label class="controllabel ">Target Audience</label>
											<select
												class="inputboxstylingForSelect mt-2"
												v-model="popupData.target_audience"
												@change="handleAccessLevelTwoChange"
												:class="{
													'is-invalid': v$.popupData.target_audience.$error,'inputboxstylingForSelectAtPopup': popupData.target_audience === '','stylingForInputboxstylingForSelectAtPopup': popupData.target_audience !== ''
												}"
											>
												<option value="" selected disabled hidden>Please select</option>
												<option class="optionColors" v-for="option in accessLevelTwo" :key="option.id" :value="option.target_audience_id">{{ option.data }}</option>
											</select>
											<div v-if="v$.popupData.target_audience.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.target_audience.required.$invalid">Required</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div v-if="popupData.type == 2 && popupData.target_audience == 1" class="w-6/12">
											<label class="controllabel ">Teams</label>
											<div class="mt-2">
											<Multiselect
												v-model="popupData.teams"
												:options="teamsOptionsArr"
												@search-change="asyncTeamsFind"
												:multiple="false"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"
												:searchable="true"
												:hideArrow="false"
												deselectLabel=""
												selectLabel=""
												class="custom-multiselect"
												:showNoOptions="false"
												placeholder="Suggest Teams"
												:class="{
													'is-invalid redBorder': v$.popupData.teams.$error,
													'has-options': teamsOptionsArr && teamsOptionsArr.length > 0,
													'no-options':  !teamsOptionsArr.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</Multiselect>
										</div>
											<div v-if="v$.popupData.teams.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.teams.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="popupData.type == 2 && popupData.target_audience == 2" class="w-6/12">
											<label class="controllabel ">Users</label>
											<div class="mt-2">
											<Multiselect
												v-model="popupData.users"
												:options="userOptionsArr"
												@search-change="asyncUsersFind"
												:multiple="false"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"
												:searchable="true"
												:hideArrow="false"
												deselectLabel=""
												selectLabel=""
												class="custom-multiselect"
												:showNoOptions="false"
												placeholder="Suggest Users"
												:class="{
													'is-invalid redBorder': v$.popupData.users.$error,
													'has-options': userOptionsArr && userOptionsArr.length > 0,
													'no-options':  !userOptionsArr.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</Multiselect>
										</div>
											<div v-if="v$.popupData.users.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.users.required.$invalid">Required</div>
											</div>
										</div>
										<div v-if="popupData.type == 3 && popupData.entity" class="w-6/12">
											<label class="controllabel ">Target Audience</label>
											<select
												class="inputboxstyling mt-2"
												v-model="popupData.target_audience"
												@change="handleAccessLevelTwoChange"
												:class="{
													'is-invalid': v$.popupData.target_audience.$error
												}"
											>
												<option value="" selected disabled hidden>Please select</option>
												<option v-for="option in accessLevelTwo" :key="option.id" :value="option.target_audience_id">{{ option.data }}</option>
											</select>
											<div v-if="v$.popupData.target_audience.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.target_audience.required.$invalid">Required</div>
											</div>
										</div>

										<div v-if="popupData.type == 3 && popupData.target_audience == 1" class="w-3/6 leftspacing">
											<label class="controllabel ">Teams</label>
											<div class="mt-2">
											<Multiselect
												v-model="popupData.teams"
												:options="teamsOptionsArr"
												@search-change="asyncTeamsFind"
												:multiple="false"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"
												:searchable="true"
												:hideArrow="false"
												deselectLabel=""
												selectLabel=""
												class="custom-multiselect"
												:showNoOptions="false"
												placeholder="Suggest Teams"
												:class="{
													'is-invalid redBorder': v$.popupData.teams.$error,
													'has-options': teamsOptionsArr && teamsOptionsArr.length > 0,
													'no-options':  !teamsOptionsArr.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</Multiselect></div>
											<div v-if="v$.popupData.teams.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.teams.required.$invalid">Required</div>
											</div>
										</div>

										<div v-if="popupData.type == 3 && popupData.target_audience == 2" class="w-3/6 leftspacing">
											<label class="controllabel ">Users</label>
											<div class="mt-2">
											<Multiselect
												v-model="popupData.users"
												:options="userOptionsArr"
												@search-change="asyncUsersFind"
												:multiple="false"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"
												:searchable="true"
												:hideArrow="false"
												deselectLabel=""
												selectLabel=""
												class="custom-multiselect"
												:showNoOptions="false"
												placeholder="Suggest Users"
												:class="{
													'is-invalid redBorder': v$.popupData.users.$error,
													'has-options': userOptionsArr && userOptionsArr.length > 0,
													'no-options':  !userOptionsArr.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</Multiselect>
										</div>
											<div v-if="v$.popupData.users.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.popupData.users.required.$invalid">Required</div>
											</div>
										</div>
									</div>

									<!-- Add and Cancel Buttons -->
									<div class="buttonposition flex justify-center items-center mt-6 mx-0">
										<button class="mr-1.5" :class="JSON.stringify(popupData) !== JSON.stringify(popupData_dummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelFormPermissionPopUp()">Cancel</button>
										<button :class="JSON.stringify(popupData) !== JSON.stringify(popupData_dummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="formAddPermissionPopUp">Add</button>
									</div>

									<div v-if="finalJsonData.length > 0" class="w-full">
										<div v-for="(data, index) in finalJsonData" :key="index" class="flex justify-between items-center">
											<div class="w-full mt-2">
												<div class="border-b border-solid border-lightgrey"></div>
												<!-- type ==2 -->
												<div v-if="data.type == 2 && data.target_audience == 3">
													<div class="row flex flex-row w-full upperspacing">
														<div class="w-6/12">
															<label class="controllabel">Access</label>
															<input type="text" class="inputboxstyling mt-2" :value="type[1].data" readonly />
														</div>
														<div class="flex w-6/12">
															<div class="pl-2 w-full">
																<label class="controllabel">Region</label>
																<input type="text" class="inputboxstyling mt-2" :value="data.region.label" readonly />
															</div>
															<div class="pl-2 mt-7">
																<button @click="removeSubmittedData(index)" class="text-red-500">
																	<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																</button>
															</div>
														</div>
													</div>
													<div class="formcontainer flex flex-col upperspacing">
														<div class="row flex flex-row w-full">
															<div class="w-6/12">
																<label class="controllabel">Entity</label>
																<input type="text" class="inputboxstyling mt-2" :value="data.entity.label" readonly />
															</div>

															<div class="w-6/12 pl-2">
																<label class="controllabel">Target Audience</label>
																<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
															</div>
														</div>
													</div>
												</div>
												<div v-if="data.type == 2 && data.target_audience == 1">
													<div>
														<div class="row flex flex-row w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Access</label>
																<input type="text" class="inputboxstyling mt-2" :value="type[1].data" readonly />
															</div>
															<div class="flex w-6/12">
																<div class="pl-2 w-full">
																	<label class="controllabel">Region</label>
																	<input type="text" class="inputboxstyling mt-2" :value="data.region.label" readonly />
																</div>
																<div class="pl-2 mt-7">
																	<button @click="removeSubmittedData(index)" class="text-red-500">
																		<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																	</button>
																</div>
															</div>
														</div>
														<div class="formcontainer flex flex-col upperspacing">
															<div class="row flex flex-row w-full">
																<div class="w-6/12">
																	<label class="controllabel">Entity</label>
																	<input type="text" class="inputboxstyling mt-2" :value="data.entity.label" readonly />
																</div>

																<div class="w-6/12 pl-2">
																	<label class="controllabel">Target Audience</label>
																	<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
																</div>
															</div>
														</div>
														<div class="flex w-full upperspacing">
															<div class="w-6/12">
																<div v-if="data.target_audience == 1">
																	<label class="controllabel">Teams</label>
																	<div v-if="data.teams != undefined || data.teams != null">
																		<div v-for="team in data.teams" :key="team">
																			<input type="text" class="inputboxstyling mt-2" :value="team.label" readonly />
																		</div>
																	</div>
																	<!-- <input type="text" class="inputboxstyling mt-2" :value="x.label" readonly /> -->
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-if="data.type == 2 && data.target_audience == 2">
													<!-- <div v-for="x in data.users" :key="x"> -->
														<div class="row flex flex-row w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Access</label>
																<input type="text" class="inputboxstyling mt-2" :value="type[1].data" readonly />
															</div>
															<div class="flex w-6/12">
																<div class="pl-2 w-full">
																	<label class="controllabel">Region</label>
																	<input type="text" class="inputboxstyling mt-2" :value="data.region.label" readonly />
																</div>
																<div class="pl-2 mt-7">
																	<button @click="removeSubmittedData(index)" class="text-red-500">
																		<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																	</button>
																</div>
															</div>
														</div>
														<div class="formcontainer flex flex-col">
															<div class="row flex flex-row w-full upperspacing">
																<div class="w-6/12">
																	<label class="controllabel">Entity</label>
																	<input type="text" class="inputboxstyling mt-2" :value="data.entity.label" readonly />
																</div>

																<div class="w-6/12 pl-2">
																	<label class="controllabel">Target Audience</label>
																	<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
																</div>
															</div>
														</div>
														<div class="flex w-full upperspacing">
															<div class="w-6/12">
																<div v-if="data.target_audience == 2 && (data.users != undefined || data.users != null)">
																	<label class="controllabel">Users</label>
																	<div v-for="userdata in data.users" :key="userdata">
																		<div class="inputboxstyling mt-2">{{ userdata.label }}</div>
																	</div>
																	<!-- <input type="text" class="inputboxstyling mt-2" :value="x.label" readonly /> -->
																</div>
															</div>
														</div>
													<!-- </div> -->
												</div>
												<!-- type==3 -->
												<div v-if="data.type == 3 && data.target_audience == 3">
													<div class="row flex flex-row w-full upperspacing">
														<div class="w-6/12">
															<label class="controllabel">Access</label>
															<input type="text" class="inputboxstyling mt-2" :value="type[2].data" readonly />
														</div>
														<div class="flex w-6/12">
															<div class="pl-2 w-full">
																<label class="controllabel">Entity</label>
																<input type="text" class="inputboxstyling mt-2" :value="data.entity.label" readonly />
															</div>

															<div class="mt-7 pl-2">
																<button @click="removeSubmittedData(index)" class="text-red-500">
																	<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																</button>
															</div>
														</div>
													</div>
													<div class="flex w-full upperspacing">
														<div class="w-6/12">
															<label class="controllabel">Target Audience</label>
															<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
														</div>
													</div>
												</div>
												<div v-if="data.type == 3 && data.target_audience == 1">
													<!-- <div v-for="x in data.teams" :key="x"> -->
														<div class="row flex flex-row w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Access</label>
																<input type="text" class="inputboxstyling mt-2" :value="type[2].data" readonly />
															</div>
															<div class="flex w-6/12">
																<div class="pl-2 w-full">
																	<label class="controllabel">Entity</label>
																	<input type="text" class="inputboxstyling mt-2" :value="data.entity.label" readonly />
																</div>

																<div class="mt-7 pl-2">
																	<button @click="removeSubmittedData(index)" class="text-red-500">
																		<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																	</button>
																</div>
															</div>
														</div>
														<div class="flex w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Target Audience</label>
																<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
															</div>
															<div class="pl-2 w-6/12">
																<div v-if="data.target_audience == 1 && data.teams != undefined">
																	<label class="controllabel">Teams</label>
																	<div v-for="x in data.teams" :key="x" class="mt-2">
																		<div class="inputboxstyling mb-1">{{ x.label }}</div>
																	</div>
																	<!-- {{ data.teams }} -->
																	<!-- <input type="text" class="inputboxstyling mt-2" :value="x.label" readonly /> -->
																</div>
															</div>
														</div>
													<!-- </div> -->
												</div>
												<div v-if="data.type == 3 && data.target_audience == 2">
													<!-- <div v-for="x in data.users" :key="x"> -->
														<div class="row flex flex-row w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Access</label>
																<input type="text" class="inputboxstyling mt-2" :value="type[2].data" readonly />
															</div>
															<div class="flex w-6/12">
																<div class="pl-2 w-full">
																	<label class="controllabel">Entity</label>
																	<input type="text" class="inputboxstyling mt-2" :value="data.entity.label" readonly />
																</div>

																<div class="mt-7 pl-2">
																	<button @click="removeSubmittedData(index)" class="text-red-500">
																		<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																	</button>
																</div>
															</div>
														</div>
														<div class="flex w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Target Audience</label>
																<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
															</div>
															<div class="pl-2 w-6/12">
																<div v-if="data.target_audience == 2 && data.users != undefined">
																	<label class="controllabel">Users</label>
																	<div v-for="x in data.users" :key="x">
																		<div class="inputboxstyling mt-2">{{ x.label }}</div>
																	</div>
																	<!-- {{ data.users }} -->
																	<!-- <input type="text" class="inputboxstyling mt-2" :value="x.label" readonly /> -->
																</div>
															</div>
														</div>
													<!-- </div> -->
												</div>
												<!-- type==1 -->
												<div v-if="data.type == 1 && data.target_audience == 3">
													<div class="row flex flex-row w-full upperspacing">
														<div class="w-6/12">
															<label class="controllabel">Access</label>
															<input type="text" class="inputboxstyling mt-2" :value="type[0].data" readonly />
														</div>
														<div class="flex w-6/12">
															<div class="pl-2 w-full">
																<label class="controllabel">Target Audience</label>
																<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
															</div>

															<div class="mt-7 pl-2">
																<button @click="removeSubmittedData(index)" class="text-red-500">
																	<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																</button>
															</div>
														</div>
													</div>
												</div>
												<div v-if="data.type == 1 && data.target_audience == 1">
													<!-- <div v-for="x in data.teams" :key="x"> -->
														<div class="row flex flex-row w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Access</label>
																<input type="text" class="inputboxstyling mt-2" :value="type[0].data" readonly />
															</div>
															<div class="flex w-6/12">
																<div class="pl-2 w-full">
																	<label class="controllabel">Target Audience</label>
																	<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
																</div>

																<div class="mt-7 pl-2">
																	<button @click="removeSubmittedData(index)" class="text-red-500">
																		<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																	</button>
																</div>
															</div>
														</div>
														<div class="flex w-full upperspacing">
															<div class="w-6/12">
																<div v-if="data.target_audience == 1 && data.teams != undefined">
																	<label class="controllabel">Teams</label>
																	<div v-for="x in data.teams" :key="x">
																		<div class="inputboxstyling mt-2">{{ x.label }}</div>
																	</div>
																	<!-- <input type="text" class="inputboxstyling mt-2" :value="x.label" readonly /> -->
																</div>
															</div>
														</div>
													<!-- </div> -->
												</div>
												<div v-if="data.type == 1 && data.target_audience == 2">
													<!-- <div v-for="x in data.users" :key="x"> -->
														<div class="row flex flex-row w-full upperspacing">
															<div class="w-6/12">
																<label class="controllabel">Access</label>
																<input type="text" class="inputboxstyling mt-2" :value="type[0].data" readonly />
															</div>
															<div class="flex w-6/12">
																<div class="pl-2 w-full">
																	<label class="controllabel">Target Audience</label>
																	<input type="text" class="inputboxstyling mt-2" :value="accessLevelTwo[data.target_audience - 1].data" readonly />
																</div>

																<div class="mt-7 pl-2">
																	<button @click="removeSubmittedData(index)" class="text-red-500">
																		<img src="../assets/deleteIcon.svg" style="width: 22px;height: 35px;"/>
																	</button>
																</div>
															</div>
														</div>
														<div class="flex w-full upperspacing">
															<div class="w-6/12">
																<div v-if="data.target_audience == 2 && (data.users != undefined || data.users != null)">
																	<label class="controllabel">Users</label>
																	<div v-for="x in data.users" :key="x">
																		<div v-if="x!=undefined" class="inputboxstyling mt-2">{{ x.label }}</div>
																		<div v-else class="inputboxstyling mt-2">-</div>
																	</div>
																	<!-- <input type="text" class="inputboxstyling mt-2" :value="x.label" readonly /> -->
																</div>
																
															</div>
														</div>
													<!-- </div> -->
												</div>
											</div>
										</div>
									</div>
									<!-- {{ (finalJsonData.length != dummyFinalJson.length || buttonStatus)&&(!v$.popupData.$invalid) }} -->
									<!-- {{ (JSON.stringify(finalJsonData) != JSON.stringify(dummyFinalJson) || buttonStatus)&&dataAdded }}  -->
									<div class="buttonposition flex justify-center items-center mt-4 mx-0" v-if="(JSON.stringify(finalJsonData) != JSON.stringify(dummyFinalJson) || buttonStatus)&&dataAdded">
										<button :disabled="clickOnButton" class="mr-1.5" :class="JSON.stringify(finalJsonData) != JSON.stringify(dummyFinalJson) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="CancelButtonPermissionPopUp('cancel')">Cancel</button>
										<button :disabled="clickOnButton" :class="JSON.stringify(finalJsonData) != JSON.stringify(dummyFinalJson) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="saveButtonPermissionPopUp()">Save</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</popup>

			<popup v-if="popupStatus.archivePopUp && dynamicPopUp == 'archive'">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2 w-60" :class="titleLength > 10? 'table-ellipsis':''">{{ archiveData[0].documentName }}</h1>
						<button class="float-right text-3xl" @click="closeArchivePopUp()">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div class="statuscontainer popupbodycontainer">
						<div class="row flex flex-row w-full upperspacing">
							<div class="w-full">
								<label for="Risk" class="controllabel ">Comment</label><br />
								<textarea rows="5" class="inputboxstyling mt-2" @input ="restrictCharacters('archive')" placeholder="Comment" :class="{ 'is-invalid': v$.archiveDocumentComment.$invalid || displayErrorMessage == true }" v-model.trim="archiveDocumentComment"></textarea>
								
								<!-- <div v-if="v$.archiveDocumentComment.$invalid" class="text-red-500"> -->
									<!-- <div class="error-text text-center text-xs" v-if="v$.archiveDocumentComment.required.$invalid">Please add the required comments</div> -->
									<!-- <div class="error-text text-xs" v-if="v$.archiveDocumentComment.maxLength.$invalid">Comments length exceeded</div> -->
								<!-- </div> -->
								<div v-if="displayErrorMessage == true">
									<div class="text-red-500 text-xs">Character Limit is exceeded</div>
								</div>
							</div>
						</div>

						<div class="flex justify-center items-between mt-4">
							<!-- <button @click="resetComments()" >Reset</button>
						<button @click="saveComments()" >Save</button> -->
							<button @click="resetComments()" :disabled="clickOnButton" class="btnprimary mr-1.5" :class="{ 'btndisabled pointer-events-none': archiveDocumentComment === '' || archiveDocumentComment === null }">Cancel</button>
							<button @click="saveComments()" :disabled="clickOnButton" class="btnprimary" :class="{ 'btndisabled pointer-events-none': archiveDocumentComment === '' || archiveDocumentComment === null }">Save</button>
						</div>
					</div>
				</fieldset>
			</popup>

			<popup class="userPopup" v-if="showPopUp" :popUp="showPopUp" :documentName="popupDocumentName">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2 w-60" :class="titleLength > 10? 'table-ellipsis':''">{{ popupDocumentName }}</h1>
						<button class="float-right text-3xl" @click="onCancelButtonAddPopUp('close')">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<div>
												<label class="controllabel ">Document Name</label>
												<div v-if='newDocumentObj._id' disabled class="inputboxstyling mt-2 text-sm">{{newDocumentObj.documentName}}</div>
												<input v-else
													type="text"
													@input ="restrictCharacters('documentName')"
													class="inputboxstyling mt-2"
													v-model.trim="newDocumentObj.documentName"
													placeholder="Document Name"
													:class="{
														'is-invalid': (v$.newDocumentObj.documentName.$error||checkDocumentName|| displayErrorMessageOfDocumentName == true )

													}"
												/>
												<div v-if="(v$.newDocumentObj.documentName.$error||checkDocumentName)" class="text-red-500">
													<div class="error-text text-xs" v-if="v$.newDocumentObj.documentName.required.$invalid">Required</div>
													<div v-else-if="checkDocumentName" class="error-text text-xs">Document Name already exists.</div>
													<!-- <div v-else-if="v$.newDocumentObj.documentName.maxLength.$invalid" class="error-text text-xs">Document Name length exceeded.</div> -->
												</div>
												<div v-else-if="displayErrorMessageOfDocumentName == true">
													<div class="text-red-500 text-xs">Character Limit is exceeded</div>
												</div>
											</div>
										</div>
									</div>

									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel ">Document ID</label>
											<div v-if='newDocumentObj._id' disabled class="inputboxstyling mt-2 text-sm">{{newDocumentObj.documentId}}</div>
											<input v-else type="text" class="inputboxstyling mt-2" @input ="restrictCharacters('documentId')" v-model.trim="newDocumentObj.documentId" placeholder="Document ID"
											    :class="{
													'is-invalid': (v$.newDocumentObj.documentId.$error || checkDocumentId || displayErrorMessage == true)
												}"
												/>
												<div v-if="(v$.newDocumentObj.documentId.$error || checkDocumentId)" class="text-red-500">
													<div class="error-text text-xs" v-if="v$.newDocumentObj.documentId.required.$invalid">Required</div>
													<div v-else-if="checkDocumentId" class="error-text text-xs">Document Id already exists.</div>
												</div>
												<div v-else-if="displayErrorMessage == true">
													<div class="text-red-500 text-xs">Character Limit is exceeded</div>
												</div>
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
									<div class="w-3/6">
										<label class="controllabel">Author</label>
										<!-- <div v-if='newDocumentObj._id' disabled class="mt-3 text-sm">{{newDocumentObj.author[0].label}}</div> -->
										<div class="inputboxstyling mt-3 text-sm">
											{{ getAuthorName(newDocumentObj.author) }}
										<!-- <Multiselect
											v-model="newDocumentObj.author"
											:options="allUserArray"
											:multiple="false"
											:caret="false"
											:close-on-select="true"
											:clear-on-select="true"
											disabled
											label="label"
											track-by="value"
											:searchable="true"
											:hideArrow="true"
											placeholder="Owner"
											deselectLabel=""
											selectLabel=""
											class="custom-multiselect"
										>
										</Multiselect> -->
									</div>
										<!-- <Multiselect
												v-model="newDocumentObj.author"
												:options="allUserArray"
												:searchable="true"
												:minChars="1"
												:disabled="true"
												:filterResults="true"
												class="multiselect-custom"
												:class="{ 'is-invalid redBorder': v$.newDocumentObj.author.$error }"
												:canClear="false"
												:caret="false"
												placeholder="Author"
											></Multiselect> -->
										<!-- <dropdown :dataprops="optionsUserArray" :inputValue="newDocumentObj.author" :placeholderValue="'author'" @name="getName" :error="v$.newDocumentObj.author.$error"></dropdown> -->

										<!-- <div v-if="v$.newDocumentObj.author.$error" class="text-red-500">
												<div class="error-text" v-if="v$.newDocumentObj.author.required.$invalid">Required</div>
											</div> -->
									</div>
									<div class="w-3/6 leftspacing">
										<label class="controllabel ">Version</label>
										<div v-if='newDocumentObj._id' disabled class="inputboxstyling mt-3 text-sm">{{newDocumentObj.version}}</div>
										<input v-else
											type="text"
											class="inputboxstyling mt-2"
											v-model.trim="newDocumentObj.version"
											:disabled="newDocumentObj._id"
											placeholder="Version"
											:class="{
												'is-invalid': v$.newDocumentObj.version.$error
											}"
										/>
										<div v-if="v$.newDocumentObj.version.$error" class="text-red-500">
											<div class="error-text text-xs" v-if="v$.newDocumentObj.version.required.$invalid">Required</div>
										</div>
									</div>
								</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel ">Owner</label>
											<div v-if="editDocument == false || newDocumentObj.statusCode == 10411" class="mt-2">
											<Multiselect
												
												v-model.trim="newDocumentObj.documentOwner"
												:options="documentOwnerFilter"
												:multiple="false"
												@search-change="AutoSuggestForDocumentOwner"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"
												:searchable="true"
												:hideArrow="false"
												placeholder="Owner"
												deselectLabel=""
												:showNoOptions="false"
												selectLabel=""
												class="custom-multiselect userSelect"
												:class="{
													'is-invalid redBorder': v$.newDocumentObj.documentOwner.$error,
													'has-options': documentOwnerFilter && documentOwnerFilter.length > 0,
													'no-options':  !documentOwnerFilter.length
												}"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Results Found</span>
												</template>
											</Multiselect></div>
											<div v-else class="inputboxstyling mt-3 text-sm">{{ newDocumentObj.documentOwner[0].label }}</div>

											<!-- <Multiselect
												v-model="newDocumentObj.documentOwner"
												:options="optionsUserArray"
												:disabled="newDocumentObj._id"
												:searchable="true"
												:minChars="1"
												:filterResults="true"
												class="multiselect-custom"
												:class="{ 'is-invalid redBorder': v$.newDocumentObj.documentOwner.$error }"
												:canClear="false"
												:caret="false"
												placeholder="Owner"
											></Multiselect> -->

											<div v-if="v$.newDocumentObj.documentOwner.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.newDocumentObj.documentOwner.required.$invalid">Required</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row flex flex-row w-full upperspacing">
									<div class="w-6/12">
										<label class="controllabel ">Classification</label>
										<select
										v-if="editDocument == false || newDocumentObj.statusCode == 10411"
											class="inputboxstylingForSelect mt-2"
											v-model="newDocumentObj.classification"
											:class="{
												'is-invalid': v$.newDocumentObj.classification.$error,'inputboxstylingForSelectAtPopup': newDocumentObj.classification === '','stylingForInputboxstylingForSelectAtPopup': newDocumentObj.classification !== ''
											}"
										>
											<option value="" selected disabled hidden>Please select one</option>

											<option class="optionColors" v-for="option in classificationData" :key="option.id" :value="option.id">
												{{ option.value }}
											</option>
										</select>
										<div v-else class="inputboxstyling mt-3 text-sm">{{ classificationData.find((data:any)=>{return data.id == newDocumentObj.classification}).value }}</div>
										<div v-if="v$.newDocumentObj.classification.$error" class="text-red-500">
											<div class="error-text text-xs" v-if="v$.newDocumentObj.classification.required.$invalid">Required</div>
										</div>
									</div>

									<div class="w-3/6 leftspacing">
										<label class="controllabel ">Folder</label>
										<div v-if="editDocument == false || newDocumentObj.statusCode == 10411" class="mt-2">
										<Multiselect
											v-model.trim="newDocumentObj.folder"
											:options="searchFoldersList"
											@search-change="asyncFoldersDataFind"
											@select="selectedOption"
											:multiple="false"
											:searchable="true"
											:hide-selected="true"
											open-direction="bottom"
											:close-on-select="true"
											:clear-on-select="true"
											:hideArrow="false"
											placeholder="Folder"
											deselectLabel=""
											:showNoOptions="false"
											selectLabel=""
											class="custom-multiselect userSelect"
											:class="{
												'is-invalid redBorder': v$.newDocumentObj.folder.$error,
												'has-options': searchFoldersList && searchFoldersList.length > 0,
												'no-options':  !searchFoldersList.length
											}"
										>
											<template v-slot:noResult>
												<span class="text-xs">No Results Found</span>
											</template>
										</Multiselect></div>
										<div  v-else class="inputboxstyling mt-3 text-sm">{{ newDocumentObj.folder }}</div>
										<!-- <dropdown :dataprops="foldersDataArray" :inputValue="newDocumentObj.folder" :placeholderValue="'Folder'" @name="getName" :error="v$.newDocumentObj.folder.$error"></dropdown> -->
										<div v-if="v$.newDocumentObj.folder.$error" class="text-red-500">
											<div class="error-text text-xs" v-if="v$.newDocumentObj.folder.required.$invalid">Required</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full upperspacing">
									<div class="w-6/12">
										<label class="controllabel ">Type</label>
										<select
										v-if="editDocument == false || newDocumentObj.statusCode == 10411"
											class="inputboxstylingForSelect mt-2"
											v-model="newDocumentObj.type"
											:class="{
												'is-invalid': v$.newDocumentObj.type.$error,'inputboxstylingForSelectAtPopup': newDocumentObj.type === '','stylingForInputboxstylingForSelectAtPopup': newDocumentObj.type !== ''
											}"
										>
											<option value="" selected disabled hidden>Please select one</option>

											<option class="optionColors" v-for="option in docTypeData" :key="option.id" :value="option.id">
												{{ option.value }}
											</option>
										</select>
										<div v-else class="inputboxstyling mt-3 text-sm">{{ docTypeData.find((data:any)=>{return data.id == newDocumentObj.type}).value }}</div>
										<div v-if="v$.newDocumentObj.type.$error" class="text-red-500">
											<div class="error-text text-xs" v-if="v$.newDocumentObj.type.required.$invalid">Required</div>
										</div>
									</div>
									<div class="w-6/12 leftspacing">
										<label class="controllabel">Review Period</label>
										<select
										v-if="editDocument == false || newDocumentObj.statusCode == 10411"
											class="inputboxstylingForSelect mt-2"
											v-model="newDocumentObj.reviewPeriod"
											:class="{
												'is-invalid': v$.newDocumentObj.reviewPeriod.$error,'inputboxstylingForSelectAtPopup': newDocumentObj.reviewPeriod === '','stylingForInputboxstylingForSelectAtPopup': newDocumentObj.reviewPeriod !== ''
											}"
										>
											<option value="" selected disabled hidden>Please select one</option>

											<option class="optionColors" v-for="option in reviewPeriodSelect" :key="option.id" :value="option.id">
												{{ option.status }}
											</option>
										</select>
										<div v-else class="inputboxstyling mt-3 text-sm">{{ reviewPeriodSelect.find((data:any)=>{return data.id == newDocumentObj.reviewPeriod}).status }}</div>
										<div v-if="v$.newDocumentObj.reviewPeriod.$error" class="text-red-500">
											<div class="error-text text-xs" v-if="v$.newDocumentObj.reviewPeriod.required.$invalid">Required</div>
										</div>
									</div>
								</div>
								<!-- <div class="row flex flex-row w-full upperspacing">
									<div class="w-6/12">
										<label class="controllabel ">Review Period</label>
										<select
											class="inputboxstyling mt-2"
											v-model="newDocumentObj.reviewPeriod"
											:class="{
												'is-invalid': v$.newDocumentObj.reviewPeriod.$error
											}"
										>
											<option value="" selected disabled hidden>Please select one</option>

											<option v-for="option in reviewPeriodSelect" :key="option.id" :value="option.id">
												{{ option.status }}
											</option>
										</select>
										<div v-if="v$.newDocumentObj.reviewPeriod.$error" class="text-red-500">
											<div class="error-text text-xs" v-if="v$.newDocumentObj.reviewPeriod.required.$invalid">Required</div>
										</div>
									</div>
								</div> -->

								<div>
									<div class="upperspacing">
										<label class="controllabel">Upload File</label>
										<div class="w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2">
											<input type="file" accept="application/pdf, .docx, .doc" ref="fileInput" class="hidden" id="upload" @change.stop="updateFileName($event)" :disabled="newDocumentObj._id"/>
											<label for="upload" class="float-right text-white btnprimary1 p-2">Browse File</label>
											<span id="fileNameSpan" class=" items-center justify-between flex" :class="(newDocumentObj.file)?'uploadedFileNameStyles':'placeholder-styles'" v-if="!newDocumentObj._id && !newDocumentObj.file">{{ newDocumentObj.file ? newDocumentObj.file.name : placeholder }}</span>
											<span id="fileNameSpan" class=" items-center justify-between flex" :class="(newDocumentObj.file)?'uploadedFileNameStyles':'placeholder-styles'" v-else-if="!newDocumentObj.file || !newDocumentObj.file.name">{{ newDocumentObj.file ? newDocumentObj.file : placeholder }}</span>
											<span id="fileNameSpan" class=" items-center justify-between flex" :class="(newDocumentObj.file)?'uploadedFileNameStyles':'placeholder-styles'" v-else>{{ newDocumentObj.file ? newDocumentObj.file.name : placeholder }}</span>
										</div>
										<!-- :class="{
												'is-invalid': v$.newDocumentObj.file.$error
											}"
										<div v-if="v$.newDocumentObj.file.$error" class="text-red-500">
											<div class="error-text text-xs mx-2" v-if="v$.newDocumentObj.file.required.$invalid">Required</div>
										</div> -->
									</div>
								</div>
							</div>
						</div>
						<div class="buttonposition flex justify-center items-center mb-4 mx-0">
							<button class="mr-1.5" :disabled="clickOnButton" :class="JSON.stringify(newDocumentObj) !== JSON.stringify(dummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelButtonAddPopUp('edit')">Cancel</button>
							<button :disabled="clickOnButton" :class="JSON.stringify(newDocumentObj) !== JSON.stringify(dummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" v-if="!newDocumentObj._id" @click="saveButtonAddPopUp">Save</button>
							<button :disabled="clickOnButton" v-else :class="JSON.stringify(newDocumentObj) !== JSON.stringify(dummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="updateButtonEditPopUp(newDocumentObj)">Update</button>
						</div>
					</div>
				</fieldset>
			</popup>
		</div>
	</div>
</template>

<script lang="ts">
import newTable from '../components/newTable.vue';
import { defineComponent, toValue } from 'vue';
import { emitter } from '../main';
import Popup from '../components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { required, maxLength,requiredIf } from '@vuelidate/validators';
import { toast } from '../main';
// import Multiselect from '@vueform/multiselect';
import Multiselect from 'vue-multiselect';
// import dropdown from '@/components/dropdown.vue';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import { omit } from 'lodash';
import _ from 'lodash';
import dotsLoader from '../components/dotsLoader.vue';
import jsPDF from 'jspdf';

export default defineComponent({
	name: 'myDocuments',
	data(): any {
		return {
			v$: useVuelidate(),
			Headers: ['documentId', 'documentName', 'version', 'classification', 'type', 'statusCode', 'Action'],
			entitesDataArray: [],
			regionDataArray: [],
			teamsDataArray: [],
			entitysTeams: [],
			entitysUsers: [],
			allDocumentsIds:[],
			allDocumentsNames:[],
			users: [],
			entitesDataArray1: [],
			permissionEntitiesArray:[],
			showSelectedData: false,
			optionsUserArray: [],
			dataLoading: true,
            clickOnButton: false,
			allUserArray: {},
			type: [
				{
					typeId: 1,
					data: 'Global'
				},
				{
					typeId: 2,
					data: 'Region'
				},
				{
					typeId: 3,
					data: 'Entity'
				}
			],
			accessLevelTwo: [
				{
					target_audience_id: 1,
					data: 'Teams'
				},
				{ target_audience_id: 2, data: 'Users' },
				{ target_audience_id: 3, data: 'EveryOne' }
			],
			placeholder: 'Select a File',
			selectedFile: null,
			displayErrorMessage : false,
			dataAdded:false,
			regionOptions:[],
			regionsArray:[],
			entityOptions:[],
			entitiyArray:[],
			allDocumentsData: [],
			teamsOptionsArr: [],
			userOptionsArr: [],
			allFoldersData: [],
			foldersDataArray: [],
			presentDocumentData: {},
			docTypeData: [
				{
					id: 1,
					value: 'Policies'
				},
				{
					id: 2,
					value: 'Procedures'
				},
				{
					id: 3,
					value: 'Standards'
				},
				{
					id: 4,
					value: 'Guidelines'
				}
			],
			docStatusValues: [
				{
					id: 10411,
					value: 'Draft' //open
				},
				{
					id: 10415,
					value: 'In review' //In progress
				},
				{
					id: 10408,
					value: 'Approved' //Accepted
				},
				{
					id: 10403,
					value: 'Accepted' //Accepted
				},
				{
					id: 10410,
					value: 'Rejected' // closed
				},
                {
                    id: 10413,
                    value: 'Published'
                },
                {
                    id: 10412,
                    value: 'Archive'
                }
			],
			classificationData: [
				{
					id: 1,
					value: 'Public'
				},
				{
					id: 2,
					value: 'Internal'
				},
				{
					id: 3,
					value: 'Confidential'
				},
				{
					id: 4,
					value: 'Highly Confidential'
				},
				{
					id: 5,
					value: 'Sensitive'
				}
			],
			folder: [
				{
					id: 1,
					name: 'folder1'
				},
				{
					id: 2,
					name: 'folder2'
				}
			],
			reviewPeriodSelect: [
				{
					id: 1,
					status: 'Quarterly'
				},
				{
					id: 2,
					status: 'Half Yearly'
				},
				{
					id: 3,
					status: 'Yearly'
				}
			],
			orgUsersList: [],

			showPopUp: false,
			popupStatus: {
				permissionsPopUp: false,
				archivePopUp: false
			},
			selectedDocument: {},
			enteredData: {},
			isAddData: false,
			showFilters: false,
			titleLength: 0,
			popupDocumentName: '',
			newDocumentObj: {
				documentId: '',
				documentName: '',
				classification: '',
				version: '',
				// publishedDate: '',
				type: '',
				statusCode: '',
				documentOwner: '',
				file: null,
				folder: '',
				reviewPeriod: '',
				author: []
			},
			uploadedDocumentUrl: '',
			filteredRegionsArray:[],
			filteredTeams:[],
			popupData: {
				type: '',
				target_audience: '',
				region: '',
				entity: '',
				teams: '',
				users: ''
			},
			popupData_dummy: {
				type: '',
				target_audience: '',
				region: '',
				entity: '',
				teams: '',
				users: ''
			},

			submittedData: [],
			responseurl: '',
			archiveData: [],
			documentOwnerFilter:[],
			permissionsData: {},
			dummy: {
				documentId: '',
				documentName: '',
				classification: '',
				version: '',
				// publishedDate: '',
				type: '',
				statusCode: '',
				documentOwner: '',
				file: null,
				folder: ''
			},
			searchFoldersList: [],
			allEntitiesArray:[],
			type3Teams:[],
			type3Users:[],
			allTeamsWereAdded: false,
			allUsersWereAdded: false,
			allEntitiesWereAdded: false,
			autoSuggestion: false,
			dataSubmitted: false,
			showArchivePopUp: false,
			dynamicPopUp: '',
			archiveDocumentComment: '',
			entityData1: [],
			finalJsonData: [],
			dummyFinalJson: [],
			fileChanged: false,
			rowData: '',
			archiveDocumentName: '',
			buttonStatus: false,
			finalSubmittedData: [],
			finalSubmittedData1: [],
			pageStatus: false,
			allTeamMembers:[],
			selectedEntities: [],
			selectedRegions:[],
			documentNamesExcludingPresentDocumentName:[],
			documentIdsExcludingPresentDocumentId:[],
			displayErrorMessageOfDocumentName: false,
			editDocument: false
		};
	},
	async created() {
		this.dataLoading = true;
		await this.getAllRegions();
		await this.getAllTeams();
		await this.getAllEntities();
		window.addEventListener('click', (e) => {
			this.autoSuggestion = false;
		});
	},
	async mounted() {
		// this.$store.dispatch('updateDotsLoading', true);
		
		// await this.getAllEntities();
		this.dataLoading = false;
		emitter.off('PermissionStatus');
		emitter.on('PermissionStatus', (isData) => {
			this.showPermissionStatusPopUp(isData);
			// this.filterRegions(isData)
		});
		emitter.off('archiveStatus');
		emitter.on('archiveStatus', (data) => {
			this.showArchiveStatusPopUp(data);
		});
		emitter.off('exportStatus');
		emitter.on('exportStatus', (data) => {
			this.showExportStatusPopUp(data);
		});
		emitter.off('viewStatus');
		emitter.on('viewStatus', (id) => {
			this.$router.push({ name: 'editor', params: { documentId: id, type: 'myDocuments' } });
		});
		await this.getAllDocumentsList();
		await this.getallTeamMembers();
		emitter.off('showData123');
		emitter.on('showData123', (isData: any) => {
			let comingId = isData.entityData._id;
			let showObj = this.allDocumentsData.find((obj: any) => {
				return obj._id === comingId;
			});
			if(this.allDocumentsData != undefined){
                this.documentIdsExcludingPresentDocumentId = []
               this.allDocumentsData.forEach((name: any)=>{
				console.log("ghjj",comingId)
                if(comingId != name._id){
                    this.documentIdsExcludingPresentDocumentId.push(name.documentId)
                    this.documentNamesExcludingPresentDocumentName.push(name.documentId)
                }
				})
				console.log("this.documentIdsExcludingPresentDocumentId",this.documentIdsExcludingPresentDocumentId)
            }
			this.showInfoEditPopUp(showObj);
			this.editDocument = true
		});
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo', dotsLoading: 'dotsLoading' }),
		checkDocumentId: function (): any {
            const trimmedDocmentId = this.newDocumentObj.documentId.trim().toLowerCase();
            const trimmedDocmentName = this.newDocumentObj.documentName.trim().toLowerCase();
            if((this.allDocumentsIds == null || this.allDocumentsNames == null) || (this.allDocumentsIds == undefined || this.allDocumentsNames == undefined) || (this.allDocumentsIds.length == 0 || this.allDocumentsNames.length == 0)){
                return false
            }
            if(this.editDocument == false){
               let a = false;
               a = (this.allDocumentsIds.map((name: any) => name.toLowerCase()).includes(trimmedDocmentId));
                return a
            }else if(this.editDocument == true){
                let a = false;
                 a = this.documentIdsExcludingPresentDocumentId.map((name: any) => name.toLowerCase()).includes(trimmedDocmentId);
                return a
            }
			if(this.editDocument == false){
               let a = false;
               a = (this.allDocumentsNames.map((name: any) => name.toLowerCase()).includes(trimmedDocmentName));
                return a
            }else if(this.editDocument == true){
                let a = false;
                 a = this.documentNamesExcludingPresentDocumentName.map((name: any) => name.toLowerCase()).includes(trimmedDocmentName);
                return a
            }
        },
		checkDocumentName: function (): any {
            const trimmedDocmentName = this.newDocumentObj.documentName.trim().toLowerCase();
            if(( this.allDocumentsNames == null) || (this.allDocumentsNames == undefined) || (this.allDocumentsNames.length == 0)){
                return false
            }
			if(this.editDocument == false){
               let a = false;
               a = (this.allDocumentsNames.map((name: any) => name.toLowerCase()).includes(trimmedDocmentName));
                return a
            }else if(this.editDocument == true){
                let a = false;
                 a = this.documentNamesExcludingPresentDocumentName.map((name: any) => name.toLowerCase()).includes(trimmedDocmentName);
                return a
            }
        }
	},
	validations() {
		return {
			newDocumentObj: {
				documentName: { required },
				documentId:{required},
				classification: { required },
				version: { required },
				type: { required },
				folder: { required },
				documentOwner: { required },
				// file: {
				// 	required: requiredIf(() => {
				// 		return !this.newDocumentObj._id;
				// 	})
				// },
				author: { required },
				reviewPeriod: { required }
			},
			popupData: {
				type: { required },
				region: {
					required: requiredIf(() => {
						return this.popupData.type == 2;
					})
				},
				entity: {
					required: requiredIf(() => {
						return (this.popupData.type === 2 && this.popupData.region) || this.popupData.type === 3;
					})
				},
				target_audience: {
					required: requiredIf(() => {
						return this.popupData.type === 1 || (this.popupData.type === 2 && this.popupData.region && this.popupData.entity) || (this.popupData.type === 3 && this.popupData.entity);
					})
				},

				teams: {
					required: requiredIf(() => {
						return (
							(this.popupData.type === 1 && this.popupData.target_audience === 1) ||
							(this.popupData.type === 2 && this.popupData.region && this.popupData.entity && this.popupData.target_audience === 1) ||
							(this.popupData.type === 3 && this.popupData.entity && this.popupData.target_audience == 1)
						);
					})
				},
				users: {
					required: requiredIf(() => {
						return (
							(this.popupData.type === 1 && this.popupData.target_audience === 2) ||
							(this.popupData.type === 2 && this.popupData.region && this.popupData.entity && this.popupData.target_audience === 2) ||
							(this.popupData.type === 3 && this.popupData.entity && this.popupData.target_audience == 2)
						);
					})
				}
			},
			archiveDocumentComment: {  maxLength: maxLength(308), }
		};
	},
	// created() {
	// 	this.loadDataFromLocalStorage();
	// },
	components: {
		newTable,
		Popup,
		// dropdown,
		Multiselect,
		dotsLoader
	},

	methods: {
	restrictCharacters(code: any){
		if(code === 'archive'){
			if(this.archiveDocumentComment.length > 308){
			this.displayErrorMessage = true;
			}else if(this.archiveDocumentComment.length < 308 || this.archiveDocumentComment.length == 308){
				this.displayErrorMessage = false;
			}
		} else if(code === 'documentName'){
			if(this.newDocumentObj.documentName.length > 308){
				this.displayErrorMessageOfDocumentName = true;
			}else if(this.newDocumentObj.documentName.length < 308 || this.newDocumentObj.documentName.length == 308){
				this.displayErrorMessageOfDocumentName = false;
			}
		} else if(code === 'documentId'){
			if(this.newDocumentObj.documentId.length > 8){
			this.displayErrorMessage = true;
			}else if(this.newDocumentObj.documentId.length < 8 || this.newDocumentObj.documentId.length == 8){
				this.displayErrorMessage = false;
			}
		}
		
	},
	getAuthorName(Author: any) {
        let AuthorName = this.allUserArray.find((obj: any) => {
            return obj.value === Author;
        });
        return AuthorName ? AuthorName.label : 'N/A';
    },
	AutoSuggestForDocumentOwner(query:any){
		if(query == ''){
          this.documentOwnerFilter = []
		}
		else if(query.length > 0){
             this.documentOwnerFilter = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
		}

	},
		asyncTeamsFind(query:any){
			if(query == ''){
				this.teamsOptionsArr = []
			}else if(query.length > 0){
				this.teamsOptionsArr = this.entitysTeams.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
			
			// this.entityTeams(this.popupData.entity.value)
			console.log("this.finalJsonDatarteter",this.entitysTeams)
		},
		asyncUsersFind(query:any){
			if(query == ''){
				this.userOptionsArr = []
			}else if(query.length > 0){
				this.userOptionsArr = this.entitysUsers.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		limitText (count:any) {
		// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind3(query:any){
			if(query == ''){
				this.entityOptions = []
			}else if(query.length > 0){
				this.entityOptions = this.allEntitiesArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
				
			}
			this.popupData.target_audience = ''
		},
		asyncFind2(query:any){
			if(query == ''){
				this.entityOptions = []
			}else if(query.length > 0){
				this.entityOptions = this.entitiyArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
				
			}
			this.popupData.target_audience = ''
			// this.filterEntities(this.popupData.entity.value)
			console.log("this.popupData.entity.value", this.popupData.entity.value)
		},
		asyncFind1(query:any){
			// console.log("queryyyy",query)
			// console.log("regionsArrayyyyyyyyy",this.regionsArray)
			if(query == ''){
				this.regionOptions = []
			}else if(query.length > 0){
				this.regionOptions = this.filteredRegionsArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
			this.popupData.entity = ''
			this.popupData.target_audience = ''
			// this.RegionAccesshandler(this.popupData.region.value)
			console.log("regionOptions", this.regionOptions)
		},
		asyncFind(query:any){
			if(query == ''){
				this.searchFoldersList = []
			}else if(query.length > 0){
				this.searchFoldersList = this.foldersDataArray.filter((obj:any)=>{return obj.toLowerCase().includes(query.toLowerCase())})
				
			}
			console.log("searchFoldersList", this.searchFoldersList)
		},
		selectedOption(opt:any){
			let isNewOption = !this.foldersDataArray.includes(opt);
			isNewOption==true ? this.foldersDataArray.push(opt) : ''
		},
		asyncFoldersDataFind(query:any) {
			query = query.trim();
			if (query == '') {
				this.searchFoldersList = [];
			} else {
				console.log("this.foldersDataArray",this.foldersDataArray)
				this.searchFoldersList = this.foldersDataArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.foldersDataArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.searchFoldersList.push(query);
				}
			}
		},
        filteredCategories(filterColumn: any, status: any) {
			this.foldersDataArray = [
				...new Set(
					Object.values(this.allDocumentsData).map((x: any) => {
						x[status];
						return x[status];
					})
				)
			].filter((category: any) => {
				return category.toLowerCase().includes(filterColumn.toLowerCase());
			});
		},
		getName(options: any, status: any) {
			let s = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
			this.filteredCategories(options, s);
			this.setState(options, s);
		},
		setState(options: any, status: any) {
			this.newDocumentObj[status] = options;
			this.autoSuggestion = false;
		},
		async getAllRegions() {
			this.regionDataArray = [];
			this.regionsArray = [];
			this.filteredRegionsArray = []
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
				res.data.map((obj: any) => {
					let userDetails: any;
					Object.keys(obj).includes('regionOwner')
						? (userDetails = this.users.find((user: any) => {
								return obj.regionOwner == user._id;
						  }))
						: userDetails == undefined;
					userDetails != undefined ? (obj.email = userDetails.email) : (obj.email = '');
					userDetails != undefined ? (obj.ownerName = userDetails.name) : (obj.ownerName = '');

					obj.entities = [];
					let entityFilter = this.entitesDataArray1.filter((entity: any) => {
						return obj._id == entity.region;
					});

					if (entityFilter.length > 0) {
						obj.entityCount = entityFilter.length;
						entityFilter.map((entity: any) => {
							obj.entities.push(entity._id);
						});
					} else {
						obj.entityCount = 0;
					}

					this.regionDataArray.push(obj);
					
					this.regionsArray.push({label: obj.region,value:obj._id })
					this.filteredRegionsArray.push({label: obj.region,value:obj._id })
					console.log("this.regionDataArray",this.regionDataArray)
					console.log("this.regionsArray",this.regionsArray)
				});
			});
		},

		async getAllEntities() {
			this.entitesDataArray = [];
			this.permissionEntitiesArray = []
			this.allEntitiesArray = []
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				if (Object.keys(this.$route.query).includes('from') && this.$route.query.from == 'region') {
					res.data.map((obj: any) => {
						if (obj.region == this.$route.params.regionId) {
							this.entitesDataArray.push(obj);
						}
					});
				} else {
					this.entitesDataArray = res.data;
				}
				console.log("kudfhgfd",this.entitesDataArray)
				let departmentArray: any =[]
				for(let i = 0; i< this.entitesDataArray.length;i++){
					const particularEntityDepartments: any = this.entitesDataArray[i].departments
					departmentArray = []
					console.log("particularEntityDepartments", particularEntityDepartments)
					if(particularEntityDepartments && particularEntityDepartments.length != 0){
						for(let j = 0; j < particularEntityDepartments.length ; j++){
							for(let k = 0; k < this.teamsDataArray.length ;k++){
								if(particularEntityDepartments[j] == this.teamsDataArray[k]._id){
									departmentArray.push({label: this.teamsDataArray[k].teamName,value: this.teamsDataArray[k]._id})
								}
							}
						}
					}
					this.permissionEntitiesArray.push({label:this.entitesDataArray[i].entityName,value:this.entitesDataArray[i]._id,departments: departmentArray,region:this.entitesDataArray[i].region})
					this.allEntitiesArray.push({label:this.entitesDataArray[i].entityName,value:this.entitesDataArray[i]._id})
					
				}
				console.log("this.permissionEntitiesArray",this.permissionEntitiesArray)
			});
			
		},
		async getAllTeams() {
			this.teamsDataArray = [];
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.teamsDataArray = res.data
				console.log("this.teamsDataArray",this.teamsDataArray);
				
				// res.data.map((teamObj: any) => {
				// 	if (Object.keys(teamObj).includes('entities') && teamObj.type == 20302) {
				// 		teamObj.entitiesCount = teamObj.entities.length;
				// 	} else if (teamObj.type == 20301) {
				// 		teamObj.entitiesCount = 'Global';
				// 	} else {
				// 		teamObj.entitiesCount = 0;
				// 	}

				// 	this.teamsDataArray.push(teamObj);
					
				// });
			});
			for(let i=0 ; i<this.teamsDataArray.length ;i++){
				this.filteredTeams.push({label: this.teamsDataArray[i].teamName,value: this.teamsDataArray[i]._id,type:this.teamsDataArray[i].entityType})
			}
			console.log("this.filteredTeams",this.filteredTeams);
			console.log("this.teamsDataArray",this.teamsDataArray);
		},
		
		RegionAccesshandler(id: any) {
    console.log("Selected Region ID:", id);
    this.entitiyArray = [];
    const permissionEntitiesValues: any[] = []; // Array to store unique entity values
    let originalEntityTeamsArray: any = [];

    // Check if Type 2 is present in finalJsonData
    const type2Permissions = this.finalJsonData.filter((permission: any) => permission.type === 2);
    console.log("Type 2 Permissions:", type2Permissions);

    // Check if the selected region exists in the finalJsonData
    const regionExists = this.finalJsonData.some((permission: any) => (permission.type==2)&&(permission.region.value === this.popupData.region.value));

    if (regionExists) {
        if (type2Permissions.length > 0) {
            type2Permissions.forEach((permission: any) => {
                const regionValue = permission.region.value;
                const entityValue = permission.entity.value;

                // Check if the same region and entity exist in finalJsonData
                const matchingPermissions = this.finalJsonData.filter((perm: any) =>
                    perm.type === 2 && perm.region.value === regionValue && perm.entity.value === entityValue
                );

                console.log("Matching Permissions:", matchingPermissions);
                originalEntityTeamsArray = [];
                this.permissionEntitiesArray.forEach((entity: any) => {
                    if (this.popupData.region.value === permission.region.value && entity.region === this.popupData.region.value) {
                        originalEntityTeamsArray.push(entity);
                    }
                });

                let teamsAdded = false;
                let usersAdded = false;

                if (matchingPermissions.length > 0) {
                    // Get original teams and users
                    let originalEntityTeams = this.permissionEntitiesArray.find((entity: any) => entity.value === entityValue)?.departments || [];
                    let originalEntityUsers = this.optionsUserArray.filter((user: any) => user.entity === entityValue);

                    if (matchingPermissions.length === 1) {
                        const perm = matchingPermissions[0];
                        // Check conditions for the single object
                        if (perm.teams && originalEntityTeams.length === perm.teams.length) {
                            teamsAdded = true;
                        } else if (originalEntityTeams.length === 0) {
                            teamsAdded = true; // If no teams, consider as all teams added
                        }
                        if (originalEntityUsers.length === 0) {
                            usersAdded = true; // If no users, consider as all users added
                        } else if (perm.users && originalEntityUsers.length === perm.users.length) {
                            usersAdded = true;
                        }
                    } else {
                        // Check conditions for multiple objects
                        teamsAdded = matchingPermissions.some((perm: any) => {
                            return perm.teams && originalEntityTeams.length === perm.teams.length;
                        });
                        usersAdded = matchingPermissions.some((perm: any) => {
                            return perm.users && originalEntityUsers.length === perm.users.length;
                        });
                    }

                    // Store entity values if both conditions are satisfied
                    if (teamsAdded && usersAdded) {
                        if (!permissionEntitiesValues.includes(entityValue)) {
                            permissionEntitiesValues.push(entityValue); // Add to the array if not already present
                            console.log("Entity value added:", entityValue);
                        }
                    }
                }
            });

            console.log("Stored Permission Entity Values:", permissionEntitiesValues);

            // After checking all permissions, filter out the entities based on stored values
            if (permissionEntitiesValues.length > 0) {
                this.entitiyArray = originalEntityTeamsArray.filter((entity: any) => !permissionEntitiesValues.includes(entity.value));
                console.log("Filtered entity array after processing:", this.entitiyArray);
            }else {
        // Handle case where the selected region does not exist in finalJsonData
        this.entitesDataArray.forEach((data: any) => {
            if (data.region === id) {
                this.entitiyArray.push({ label: data.entityName, value: data._id });
					}
				});
			}
        }
    } else {
        // Handle case where the selected region does not exist in finalJsonData
        this.entitesDataArray.forEach((data: any) => {
            if (data.region === id) {
                this.entitiyArray.push({ label: data.entityName, value: data._id });
					}
				});
			}

			console.log("Final Entity Array:", this.entitiyArray);
			this.v$.$reset();
		},
		selectedEntity(entityId: any){
			this.entitysTeams = []
			this.entitysUsers = []
			console.log("this.permissionEntitiesArrayfhgjghjhgjhj",this.permissionEntitiesArray);
			if(this.permissionsData.permissions != undefined){

			const relavantPermissions: any = this.permissionsData.permissions
			let relatedEntityTeamArray: any = []
			let relatedEntityUserArray: any = []
			relavantPermissions.forEach((data: any) => {
				// for(let i = 0; i<this.permissionEntitiesArray.length ; i++){
				// 	if(this.permissionEntitiesArray[i].value == data){

				// 	}
				// }
				let relatedUserObj :any = this.entitesDataArray.find((obj:any)=>{
					return obj._id == entityId
				}).owner
				console.log("relatedUserObj",relatedUserObj);
				console.log("this.optionsUserArray",this.optionsUserArray)
				if(data.type == 3 && data.entity != undefined && data.entity.value === entityId && data.teams != undefined){
					for(let i = 0;i<this.teamsDataArray.length ; i++){
						if(entityId === this.teamsDataArray[i].entity){
							relatedEntityTeamArray.push({label: this.teamsDataArray[i].teamName,value: this.teamsDataArray[i]._id })
						}
					}
					for(let i = 0;i < relatedEntityTeamArray.length ;i++){
						let teamsObj: any = data.teams[i]
						if(teamsObj != undefined && relatedEntityTeamArray[i].value !== teamsObj.value){
							this.entitysTeams.push({label: relatedEntityTeamArray[i].label , value : relatedEntityTeamArray[i].value})
						}
					}
				}
				else if(data.type == 3 && data.entity != undefined && data.entity.value === entityId && data.users != undefined){
					for(let i = 0;i<this.optionsUserArray.length ; i++){
						if(relatedUserObj === this.optionsUserArray[i].value){
							relatedEntityUserArray.push({label: this.optionsUserArray[i].label,value: this.optionsUserArray[i].value })
						}
					}
					for(let i = 0;i < relatedEntityUserArray.length ;i++){
						let usersObj: any = data.users[i]
						if(usersObj != undefined && relatedEntityUserArray[i].value !== usersObj.value){
							this.entitysUsers.push({label: relatedEntityUserArray[i].value , value : relatedEntityUserArray[i].label})
						}
					}
				}
				console.log("relatedEntityTeamArray",relatedEntityTeamArray);
				console.log("data.teams",data.teams);
				
				
				
			});
			
			} 
			else {
			
				let relatedUserObj :any = this.entitesDataArray.find((obj:any)=>{
					return obj._id == entityId
				}).owner
				for(let i = 0;i<this.teamsDataArray.length ; i++){
					if(entityId === this.teamsDataArray[i].entity){
						this.entitysTeams.push({label: this.teamsDataArray[i]._id,value: this.teamsDataArray[i].teamName })
					}
				}
				for(let i = 0;i<this.optionsUserArray.length ; i++){
					if(relatedUserObj === this.optionsUserArray[i].value){
						this.entitysUsers.push({label: this.optionsUserArray[i].label,value: this.optionsUserArray[i].value })
					}
				}console.log("dsgfdgfdtfghgf",this.entitysUsers);
			}
		},
		entityTeams(data: any) {
			
			// this.entitysUsers = [];
			
			// this.teamsDataArray.filter((data_object1: any) => {
			// 	if (data_object1.entity && data_object1.entity == data) {
			// 		this.entitysTeams.push({label:data_object1.teamName, value:data_object1._id});
			// 		// this.entitysTeams.push(data_object1.teamName);
			// 	}
			// });
			console.log("this.entitysTeamskjdjkdfvjk",this.entitysTeams);
			console.log("this.teamsDataArray",this.teamsDataArray)
			// this.orgUsersList.filter((data_object1: any) => {
			// 	if (data_object1.entity && data_object1.entity == data) {
			// 		this.entitysUsers.push({ label: data_object1.name, value: data_object1._id });

			// 		// this.entitysUsers.push(data_object1.name);
			// 	}
			// });
			if (this.popupData.type === 3 && data == this.popupData.entity) {
				this.popupData.target_audience = '';

				this.popupData.teams = '';
				this.popupData.users = '';
			}

			this.v$.$reset();
		},
		filterEntities(id: any) {
			let originalEntityTEamsArray:any
			// this.entitiyArray=[]
			let permission:any = this.finalJsonData
			
			
			// return this.entitiyArray
			},
		globalAccess(newValue: any, oldValue: any) {
			let permissions:any = this.finalJsonData
			let matchedRegionValues: any[] = [];
			if(permissions.length != 0){
				permissions.forEach((permission :any) => {
					if(permission.type == 3){
						let originalEntityTeams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == permission.entity.value})
						let originalEntityUsers: any = this.optionsUserArray.filter((user: any)=>{return user.entity == permission.entity.value})
						if(originalEntityTeams.departments.length != 0 && permission.teams != undefined){
							if((originalEntityTeams.departments.length == permission.teams.length)){
								this.allTeamsWereAdded = true
							}
						} else if(originalEntityUsers.length != 0 && permission.users != undefined){
							if(originalEntityUsers.length == permission.users.length){
								this.allUsersWereAdded = true
							}
						}
						if(originalEntityUsers.length == 0){
							console.log("lfdxgkhfkjhfiuhyd",permission.entity.value)
							let originalEntityUsers: any = this.optionsUserArray.filter((user: any)=>{return user.entity == permission.entity.value})
							if(originalEntityUsers.length == 0){
								this.allUsersWereAdded = true
							}
						}
						if(originalEntityTeams.departments.length == 0){
							let originalEntityTeams:any = this.allEntitiesArray.find((entity:any)=>{return entity.value == permission.entity.value})
							if(originalEntityTeams.departments.length == 0){
								this.allTeamsWereAdded = true
							}
						}
						if(this.allTeamsWereAdded == true &&  this.allUsersWereAdded == true){
							let filteredEntities = this.allEntitiesArray.filter((entity: any)=> { return entity.value != permission.entity.value})
							this.allEntitiesArray = filteredEntities
							console.log("filtered Entites",filteredEntities)
						}
					} // Array to store matched region values

			else if (permission.type === 2) {
				let originalEntitiesArray: any = [];
				this.permissionEntitiesArray.forEach((entity: any) => {
					if (permission.region.value === entity.region) {
						originalEntitiesArray.push(entity.value);
					}
				});

				// Find all type 2 permissions for the specified region
				const type2Permissions = this.finalJsonData.filter((data: any) =>
					data.type === 2 && data.region.value === permission.region.value
				);

				// Create an array of unique entity values from the type2Permissions without duplicates
				const uniqueType2Entities: any[] = [];
				type2Permissions.forEach((perm: any) => {
					if (!uniqueType2Entities.includes(perm.entity.value)) {
						uniqueType2Entities.push(perm.entity.value);
					}
				});

				console.log("Type 2 Permissions:", type2Permissions);
				console.log("Unique Type 2 Entities:", uniqueType2Entities);
				console.log("Original Entities Array:", originalEntitiesArray);

					// Check if the length of uniqueType2Entities is equal to the original length
					if (uniqueType2Entities.length === originalEntitiesArray.length) {
						// Store the region value in the matchedRegionValues array
						matchedRegionValues.push(permission.region.value);
					}
				}

				// After processing all permissions, filter the filteredRegionsArray
				let filteredRegions:any= this.filteredRegionsArray.filter((region: any) => 
					!matchedRegionValues.includes(region.value)
				);
				this.filteredRegionsArray=filteredRegions
				console.log("Filtered Regions Array:", filteredRegions);


	
				});
			}
			this.allTeamsWereAdded = false
			this.allUsersWereAdded = false
			if (newValue !== oldValue) {
				(this.popupData.target_audience = ''), (this.popupData.region = ''), (this.popupData.entity = ''), (this.popupData.teams = ''), (this.popupData.users = ''), this.v$.$reset();
			}
		},
		
		handleAccessLevelTwoChange(newValue: any, oldValue: any) {
			if (newValue !== oldValue) {
				this.popupData.teams = '';
				this.popupData.users = '';
				this.v$.$reset();
			}
			this.entitysTeams = [];
			this.entitysUsers = [];
			console.log("this.entitiyArray",this.entitiyArray);
			console.log("this.finalJsonDatalkasj",this.finalJsonData)
			const hasType = this.finalJsonData.some((permission: any)=>permission.type == this.popupData.type)
			if (this.finalJsonData.length != 0 && hasType) {
				const permissions = this.finalJsonData;

				// Check for type 1 permissions
				const hasType1WithAudience1 = permissions.some((permission:any) => 
					permission.type === this.popupData.type && permission.target_audience === 1
				);
				const hasType1WithAudience2 = permissions.some((permission:any) => 
					permission.type === this.popupData.type && permission.target_audience === 2
				);
				const hasType1WithAudience3 = permissions.some((permission:any) => 
					permission.type === this.popupData.type && permission.target_audience === 3
				);
				// Condition 1: Type 1 with target audience 1 is not added
				if (hasType1WithAudience1 && !hasType1WithAudience2) { console.log("dhjkfkjsfh")
				permissions.forEach((permission:any) => {
						if (permission.type === this.popupData.type && permission.target_audience === 1) {
							this.filterTeams(permission);
						} 
					});
					if(this.popupData.type == 1 && this.popupData.target_audience == 2){
						for (let i = 0; i < this.optionsUserArray.length; i++) {
							this.entitysUsers.push({ label: this.optionsUserArray[i].label, value: this.optionsUserArray[i].value });
						}
					} else if((this.popupData.type == 3||this.popupData.type == 2) && this.popupData.target_audience == 2){
						let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
						this.entitysUsers = users
					}
				}

				// Condition 2: Type 1 with target audience 2 is not added
				else if (hasType1WithAudience2 && !hasType1WithAudience1) {console.log("asdfsd")
				permissions.forEach((permission:any) => {
						
					if(permission.type === this.popupData.type && permission.target_audience === 2){
							this.filterUsers(permission);
						}
					});
					if(this.popupData.type == 1 && this.popupData.target_audience == 1){
						for (let i = 0; i < this.teamsDataArray.length; i++) {
							if (this.teamsDataArray[i].entityType == 20301) {
								this.entitysTeams.push({ label: this.teamsDataArray[i].teamName, value: this.teamsDataArray[i]._id });
							}
						}
					}else if((this.popupData.type == 3||this.popupData.type == 2) && this.popupData.target_audience == 1){
						let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
						this.entitysTeams = teams
					}
				}
				
				// Condition 2: Type 1 with target audience 2 is not added
				else if (hasType1WithAudience2 && hasType1WithAudience1) {console.log("tfyfhvghfg")
				permissions.forEach((permission:any) => {
						if (permission.type === this.popupData.type && permission.target_audience === 1) {
							this.filterTeams(permission);
						} else if(permission.type === this.popupData.type && permission.target_audience === 2){
							this.filterUsers(permission);
						}
					});
				}
				else if((hasType1WithAudience1 && !hasType1WithAudience3) || (hasType1WithAudience1 && hasType1WithAudience3)){
					permissions.forEach((permission:any) => {
						if (permission.type === this.popupData.type && permission.target_audience === 1) {
							this.filterTeams(permission);
						} 
					});
					if(this.popupData.type == 1 && this.popupData.target_audience == 2){
						for (let i = 0; i < this.optionsUserArray.length; i++) {
							this.entitysUsers.push({ label: this.optionsUserArray[i].label, value: this.optionsUserArray[i].value });
						}
					}else if((this.popupData.type == 3||this.popupData.type == 2) && this.popupData.target_audience == 2){
						let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
						this.entitysUsers = users
					}
				}
				else if((!hasType1WithAudience1 && hasType1WithAudience3) || (!hasType1WithAudience2 && hasType1WithAudience3)){
					if(this.popupData.type == 1 && this.popupData.target_audience == 1){
						for (let i = 0; i < this.teamsDataArray.length; i++) {
							if (this.teamsDataArray[i].entityType == 20301) {
								this.entitysTeams.push({ label: this.teamsDataArray[i].teamName, value: this.teamsDataArray[i]._id });
							}
						}
					}
					
					else if(this.popupData.type == 1 && this.popupData.target_audience == 2){
						for (let i = 0; i < this.optionsUserArray.length; i++) {
							this.entitysUsers.push({ label: this.optionsUserArray[i].label, value: this.optionsUserArray[i].value });
						}
					}
					else if((this.popupData.type == 3||this.popupData.type == 2) && this.popupData.target_audience == 1){
						let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
						this.entitysTeams = teams
					}
					else if((this.popupData.type == 3||this.popupData.type == 2) && this.popupData.target_audience == 2){
						let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
						this.entitysUsers = users
					}

				}
				
				else if((hasType1WithAudience2 && !hasType1WithAudience3) || (hasType1WithAudience2 && hasType1WithAudience3)){
					permissions.forEach((permission:any) => {
						if(permission.type === this.popupData.type && permission.target_audience === 2){
							this.filterUsers(permission);
						}
					});
					if(this.popupData.type == 1 && this.popupData.target_audience == 1){
						for (let i = 0; i < this.teamsDataArray.length; i++) {
							if (this.teamsDataArray[i].entityType == 20301) {
								this.entitysTeams.push({ label: this.teamsDataArray[i].teamName, value: this.teamsDataArray[i]._id });
							}
						}
					}else if((this.popupData.type == 3||this.popupData.type == 2) && this.popupData.target_audience == 1){
						let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
						this.entitysTeams = teams
					}
				}
				// Further logic can be added here if necessary
			} else {
					console.log("jdsjhdshjd");
					if(this.popupData.type == 1){
						if(this.teamsDataArray != undefined && this.popupData.target_audience == 1){
							for(let i = 0; i < this.teamsDataArray.length ;i++){
								if(this.teamsDataArray[i].entityType == 20301){
									this.entitysTeams.push({label:this.teamsDataArray[i].teamName,value:this.teamsDataArray[i]._id})
								}
							}
						}
						else if(this.optionsUserArray != undefined && this.popupData.target_audience == 2){
							console.log("this.optiosjkhkjhjkfd",this.optionsUserArray);
							for(let i = 0; i < this.optionsUserArray.length ;i++){
								this.entitysUsers.push({label:this.optionsUserArray[i].label,value:this.optionsUserArray[i].value})
							}
						}
						console.log("this.optiosjkhkjhjkfd",this.popupData);
					} else if(this.popupData.type == 3 || this.popupData.type == 2){
						let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
						this.entitysTeams = teams
						this.type3Teams = teams
						console.log("ghjfghjfghj",teams);
						console.log("this.optionsUserArray",this.optionsUserArray)
						let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
						this.entitysUsers = users
						this.type3Users = users
						console.log("useriueyluihf",users)
					}
				}
		},

		filterTeams(permission: any) {
			console.log("teamsssss permission", permission);
			let entityExists = this.finalJsonData.find((data: any) => (data.type == 3) && (data.entity.value === this.popupData.entity.value)  && (data.teams != undefined));
			let regionExists = this.finalJsonData.find((data: any) => (data.type == 2) && (data.region.value === this.popupData.region.value)  && (data.entity.value === this.popupData.entity.value)  && (data.teams != undefined));
			// let equallySelectedPermission:any 
			// if(entityExists){ 
			// 	equallySelectedPermission = this.finalJsonData.find((data: any) => (data.type == 3 ) && (data.entity.value === this.popupData.entity.value));
			// }
			// Get the values of the teams in the permission object\
			// Filter out the teams that match the values in permissionTeamValues
			if(permission.type == 1){
				const permissionTeamValues = permission.teams.map((team: any) => team.value);
				const remainingTeams = this.filteredTeams.filter((team: any) => {
					// Only keep teams that are not in the permissionTeamValues
					return !permissionTeamValues.includes(team.value) && (team.type == 20301);
				});
				this.entitysTeams.push(...remainingTeams);
			} else if(permission.type == 3){
				let permissionTeamValues:any
				// Check if the selected entity exists in finalJsonData
				if(entityExists){
					this.entitysTeams=[]
					console.log("permissionTeamValuestf",permission)
					permissionTeamValues = entityExists.teams.map((team: any) => team.value);
					console.log("permissionTeamValues1",permissionTeamValues)
					console.log("permissionTeamValues",permissionTeamValues)
					let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
					console.log("yiuoeiyuuowheihrhwjeguh",teams)
					const remainingTeams = teams.filter((team: any) => {
						// Only keep teams that are not in the permissionTeamValues
						return !permissionTeamValues.includes(team.value);
					});
					console.log("remainingTeams",remainingTeams)
					this.entitysTeams.push(...remainingTeams);
				}else{
					console.log("dhjsgfuyyuf",permission)
					// permissionTeamValues = permission.teams.map((team: any) => team.value);
					console.log("permissionTeamValues1",permissionTeamValues)
					console.log("permissionTeamValues",permissionTeamValues)
					let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
					// console.log("yiuoeiyuuowheihrhwjeguh",teams)
					// const remainingTeams = teams.filter((team: any) => {
					// 	// Only keep teams that are not in the permissionTeamValues
					// 	return !permissionTeamValues.includes(team.value);
					// });
					// console.log("remainingTeams",remainingTeams)
					this.entitysTeams = teams
				}
				
			} else if(permission.type == 2){
				let permissionTeamValues:any
				if(regionExists!=undefined ){
					this.entitysTeams=[]
					// console.log("permissionTeamValuestf",permission)
					permissionTeamValues = regionExists.teams.map((team: any) => team.value);
					// console.log("permissionTeamValues1",permissionTeamValues)
					// console.log("permissionTeamValues",permissionTeamValues)
					let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
					console.log("yiuoeiyuuowheihrhwjeguh",teams)
					const remainingTeams = teams.filter((team: any) => {
						// Only keep teams that are not in the permissionTeamValues
						return !permissionTeamValues.includes(team.value);
					});
					console.log("remainingTeams",remainingTeams)
					this.entitysTeams.push(...remainingTeams);
				}else{
					console.log("dhjsgfuyyuf",permission)
					// permissionTeamValues = permission.teams.map((team: any) => team.value);
					console.log("permissionTeamValues1",permissionTeamValues)
					console.log("permissionTeamValues",permissionTeamValues)
					let teams:any = this.permissionEntitiesArray.find((entity:any)=>{return entity.value == this.popupData.entity.value}).departments
					// console.log("yiuoeiyuuowheihrhwjeguh",teams)
					// const remainingTeams = teams.filter((team: any) => {
					// 	// Only keep teams that are not in the permissionTeamValues
					// 	return !permissionTeamValues.includes(team.value);
					// });
					// console.log("remainingTeams",remainingTeams)
					this.entitysTeams = teams
				}
			}
			
			
			// Update entitysTeams with the remaining teams
			
			console.log("Remaining teams after filtering:", this.entitysTeams);
		},
		filterUsers(permission: any) {
			console.log("teamsssss permission", permission);
			let entityExists = this.finalJsonData.find((data: any) => (data.type == 3 ) && (data.entity.value === this.popupData.entity.value) && (data.users&&data.users.length!=0));
			// Get the values of the teams in the permission object
			let regionExists = this.finalJsonData.find((data: any) => (data.type == 2) && (data.region.value === this.popupData.region.value)  && (data.entity.value === this.popupData.entity.value)  && (data.users != undefined));

			// Filter out the teams that match the values in permissionTeamValues
			if(permission.type == 1){
				const permissionUserValues = permission.users.map((user: any) => user.value);
				const remainingUsers = this.optionsUserArray.filter((user: any) => {
					// Only keep teams that are not in the permissionTeamValues
					return !permissionUserValues.includes(user.value);
				});

				// Update entitysTeams with the remaining teams
				this.entitysUsers.push(...remainingUsers);
			} else if(permission.type == 3){
				let permissionUserValues:any
				if(entityExists!=undefined){
					this.entitysUsers=[]
					console.log("permissionUserValuestf",permission)
					permissionUserValues = entityExists.users.map((user: any) => user.value);
					console.log("permissionUserValues1",permissionUserValues)
					console.log("permissionUserValues",permissionUserValues)
					let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
					const remainingUsers = users.filter((user: any) => {
						// Only keep teams that are not in the permissionTeamValues
						return !permissionUserValues.includes(user.value);
					});
					console.log("remainingUsers",remainingUsers)
					this.entitysUsers.push(...remainingUsers);
				}else{
					let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
					// const remainingUsers = users.filter((user: any) => {
					// 	// Only keep teams that are not in the permissionTeamValues
					// 	return !permissionUserValues.includes(user.value);
					// });
					// console.log("remainingUsers",remainingUsers)
					this.entitysUsers = users;
				}
			}else if(permission.type == 2){
				let permissionUserValues:any
				if(regionExists){
					this.entitysUsers=[]
					console.log("permissionUserValuestf",permission)
					permissionUserValues = regionExists.users.map((user: any) => user.value);
					console.log("permissionUserValues1",permissionUserValues)
					console.log("permissionUserValues",permissionUserValues)
					let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
					const remainingUsers = users.filter((user: any) => {
						// Only keep teams that are not in the permissionTeamValues
						return !permissionUserValues.includes(user.value);
					});
					console.log("remainingUsers",remainingUsers)
					this.entitysUsers.push(...remainingUsers);
				}else{
					let users: any = this.optionsUserArray.filter((user: any)=>{return user.entity == this.popupData.entity.value})
					// const remainingUsers = users.filter((user: any) => {
					// 	// Only keep teams that are not in the permissionTeamValues
					// 	return !permissionUserValues.includes(user.value);
					// });
					// console.log("remainingUsers",remainingUsers)
					this.entitysUsers = users;
				}
			}
			console.log("Remaining teams after filtering:1", this.entitysUsers);
		},
		showInfoEditPopUp(obj: any) {
			console.log("Hiiii")
			this.showPopUp = true;
			if (obj.documentUrl && obj.documentUrl[0].url) {
				let docUrl = obj.documentUrl[0].url;
				let fileName = docUrl.split('/').pop();

				let fileExtension = fileName.split('.').pop();
				// let id=uuidv4();
				obj.file = `${obj.documentId}-${obj.documentName}.${fileExtension}`;
				this.newDocumentObj.file = obj.file;
			}

			this.popupDocumentName = obj.documentName;
			this.newDocumentObj = { ...obj };
			let docOwnerObj = this.orgUsersList.filter((user: any) => user._id === this.newDocumentObj.documentOwner).map((user: any) => ({ label: user.name, value: user._id }));
            let docAuthorObj = this.orgUsersList.filter((user: any) => user._id === this.newDocumentObj.author).map((user: any) => user._id)[0];
            this.newDocumentObj.documentOwner = docOwnerObj;
			this.newDocumentObj.author = docAuthorObj;
            console.log("docAuthorObj",docAuthorObj)
			console.log("this.newDocumentObj.author",this.newDocumentObj.author)
			this.dummy = { ...this.newDocumentObj };
			this.titleLength = this.newDocumentObj.documentName ? this.newDocumentObj.documentName.length:0
		},
		showArchiveStatusPopUp(showObj: any) {
			this.archiveData.push(showObj);
			this.popupStatus.archivePopUp = true;
			this.dynamicPopUp = 'archive';
			this.titleLength = this.archiveData[0].documentName ? this.archiveData[0].documentName.length:0
		},
		showPermissionStatusPopUp(showObj: any) {
			this.permissionsData = { ...showObj };
			this.popupStatus.permissionsPopUp = true;
			this.dynamicPopUp = 'permissions';
			if (Object.keys(this.permissionsData).includes('permissions')) {
				this.permissionsData.permissions.forEach((data: any) => {
					console.log("data",data)
					if (Object.keys(data).includes('entity')) {
						console.log("Object.keys(data).includes('entity')",Object.keys(data).includes('entity'))
						this.permissionEntitiesArray.forEach((eachEntity: any) => {
							if (eachEntity.value === data.entity) {
								data.entity = eachEntity;
							}
						});
					}
					if (Object.keys(data).includes('region')) {
						this.regionsArray.forEach((eachregion: any) => {
							if (eachregion.value === data.region) {
								data.region = eachregion;
							}
						});
					}
					if(data.target_audience == 2 && (data.users!= undefined||data.users!= null)){
						this.optionsUserArray.forEach((obj: any)=> {
							for(let i=0 ; i<data.users.length;i++){
								if(data.users[i] == obj.value){
									let UserObj = {label: obj.label, value: obj.value}
									console.log("UserObj",UserObj)
									data.users[i] = UserObj
								}
							}
							
						})
						// let users: any = this.optionsUserArray.find((user: any)=>{
						// 	return user.value == data.users[0]
						// })
						// data.users = [users]
					}
					// if(data.users!= undefined){
					// 	data.users = [this.optionsUserArray.find((obj: any)=> data.users[0].value === obj.value)]
					// }
					console.log("data.users",data.users)
					if(data.teams){
						this.teamsDataArray.forEach((obj: any)=> {
							for(let i=0 ; i<data.teams.length;i++){
								if(data.teams[i] == obj._id){
									let teamObj = {label: obj.teamName, value: obj._id}
									console.log("teamObj",teamObj)
									data.teams[i] = teamObj
								}
							}
							
						})
					}
					if(data.type == 2 && data.region != undefined && data.entity != undefined){
						this.selectedEntities.push(data.entity.value)
						this.selectedRegions.push(data.region.value)
					}
					
				});
				this.finalJsonData = this.permissionsData.permissions;
				// this.dummyFinalJson = [...this.permissionsData.permissions];
				// console.log("sadfdhgfd",this.finalJsonData)
		this.dummyFinalJson =[...this.finalJsonData]
		// console.log("sadfdhgfd",this.finalJsonData)
		// console.log("gdrgeg",[...this.finalJsonData])
			}
		},
		
		async showExportStatusPopUp(data: any) {
			if(data.documentUrl && data.documentUrl[data.documentUrl.length - 1].url){
				let url = data.documentUrl[data.documentUrl.length - 1].url;
				let versionId = data.documentUrl[0].versionId;
				let obj = {
					link: url,
					versionId: versionId
				};
				await this.$http.post(`${process.env.VUE_APP_FILE_API_URL}/presignedurl`, obj).then(async (res: any) => {
					if(res.status == 200){
						this.pageStatus = false
					} else{
						this.pageStatus = true
					}
					this.responseurl = res.data;
					// toast.info(`Exported`, {
					// 	closeOnClick: true,
					// 	closeButton: 'button'
					// });
					// Open the URL in a new window
					if(this.responseurl != undefined){
						window.open(this.responseurl, '_blank');
					}
				});
			} else if(data.documentUrl && data.documentUrl[data.documentUrl.length - 1].content){
				let content = data.documentUrl[data.documentUrl.length - 1]
				this.downloadHtmlAsPdf(content.content, content.docVersion)
			}
		},
		downloadHtmlAsPdf(htmlContent: any, fileName: any) {
			// Decode the base64 HTML content
			let decodedHtmlData = atob(htmlContent);

			// Initialize jsPDF with A4 size
			const pdf = new jsPDF({
				format: 'a4',
			});

			// Convert HTML to PDF
			pdf.html(decodedHtmlData, {
				callback: function (pdf) {
					// Create a Blob from the generated PDF
					const blob = pdf.output('blob');
					
					// Create an object URL from the Blob
					const url = URL.createObjectURL(blob);
					pdf.save(fileName);
					// Open the PDF in a new tab
					window.open(url, '_blank');
					
					// Optionally, revoke the object URL after a certain time to free up memory
					setTimeout(() => {
						URL.revokeObjectURL(url);
					}, 100);
				},
				x: 10, // Adjusted margins
				y: 10,
				margin: [10, 10, 10, 10], // Optional: Set margins (top, left, bottom, right)
				autoPaging: 'text',
				width: 190, // Maximum width for A4 page (210mm - 20mm for margins)
				windowWidth: 800 // Optional: Adjust based on your HTML content width
			});
		},
		updateFileName(event: any) {
			// const selectedFile = event.target.files[0];
			// this.newDocumentObj.file = selectedFile;
			let file = this.$refs.fileInput.files[0];

			if (file && !file.type.match('application/pdf') && !file.name.endsWith('.doc') && !file.name.endsWith('.docx')) {
				alert('Please select a PDF, DOC, or DOCX file.');
				return;
			} else {
				this.fileChanged = true;
				this.newDocumentObj.file = file;
			}
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		async saveButtonPermissionPopUp() {
			// if (!this.v$.documentDropdown.$invalid) {
			this.clickOnButton = true;
			let presentDocumentDataObj = this.allDocumentsData.find((dataObj: any) => {
				return dataObj._id == this.permissionsData._id;
			});
			let presentDocumentData :any = {...presentDocumentDataObj}
			const updatedFinalJsonData = this.finalJsonData.map((entry: any) => {
				console.log("entry", entry)
				const updatedEntry = { ...entry };

				if (entry.region) {
					const region = this.regionsArray.find((r: any) => r.value == entry.region.value);
					if (region) {
						updatedEntry.region = region.value;
					}
				}
				if (entry.entity) {
					const entity = this.permissionEntitiesArray.find((e: any) => e.value == entry.entity.value);
					
					if (entity) {
						updatedEntry.entity = entity.value;
					}
				}

				if(entry.teams){
					updatedEntry.teams = entry.teams.map((obj: any)=> obj.value)
				}
				if(entry.users){
					updatedEntry.users = entry.users.map((obj: any)=> obj.value)
				}
				console.log("updatedEntry", updatedEntry)
				return updatedEntry;
			});
			presentDocumentData.permissions = updatedFinalJsonData
			// presentDocumentData.permissions.push(updatedFinalJsonData);
			console.log("presentDocumentData", presentDocumentData)
			console.log("updatedFinalJsonData", updatedFinalJsonData)

			let docId = presentDocumentData._id;
			try{
				await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/update`, _.omit(presentDocumentData, '_id')).then((result: any) => {
					toast.info(`Uploaded succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
				});
			} catch(err){
				toast.error(`Error`, {
					closeOnClick: true,
					closeButton: 'button'
				});
			} finally {
				this.clickOnButton = false;
			}
			
			this.popupStatus.permissionsPopUp = false;
			this.finalJsonData = [];
			this.finalSubmittedData1 = [];
			this.buttonStatus = false;
			this.dataAdded = false
			// }
		},
		cancelFormPermissionPopUp() {
			this.popupData = {};
			(this.popupData.type = ''), (this.popupData.target_audience = ''), (this.popupData.entity = ''), (this.popupData.teams = ''), (this.popupData.users = '');
			this.popupData_dummy = {};
			this.popupData_dummy.type = '';
			this.popupData_dummy.target_audience = '';
			this.popupData_dummy.entity = '';
			this.popupData_dummy.teams = '';
			this.popupData_dummy.users = '';
			this.v$.$reset();
		},
		removeSubmittedData(index: any) {
			this.finalJsonData.splice(index, 1);
			this.dataAdded = true
			if (this.finalJsonData.length == 0) {
				
				this.dataSubmitted = false;
			}
		},
		showPopupByAdd() {
			this.showPopUp = !this.showPopUp;
			this.editDocument = false
			// let uuid = uuidv4();
            // const truncatedUuid = uuid.replace(/-/g,'').substring(0, 8).toUpperCase();
			// this.newDocumentObj.documentId = truncatedUuid;
			// this.newDocumentObj.author = { label: this.userInfo.name, value: this.userInfo._id };
			// this.dummy.documentId = truncatedUuid;
			this.newDocumentObj = {documentId: '', documentName: '', classification: '', version: '', type: '', statusCode: '', documentOwner: '', file: null, folder: '', reviewPeriod: ''}
			this.newDocumentObj.author = this.userInfo._id;
			this.dummy = { ...this.newDocumentObj };
			this.popupDocumentName = 'New Document';
		},
		onCancelButtonAddPopUp(status: any) {
			this.v$.$reset();
			if (status == 'close') {
				delete this.newDocumentObj._id;
				delete this.dummy._id;
				this.showPopUp = false;
				this.finalSubmittedData1 = [];
				// this.newDocumentObj = {};
				// this.dummy = {};
				Object.keys(this.newDocumentObj).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.newDocumentObj[prop]) ? (this.newDocumentObj[prop] = []) : (this.newDocumentObj[prop] = '');
					}
				});
				Object.keys(this.dummy).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.dummy[prop]) ? (this.dummy[prop] = []) : (this.dummy[prop] = '');
					}
				});
				this.displayErrorMessageOfDocumentName = false
			this.displayErrorMessage = false
			} else if (status == 'edit') {
				this.newDocumentObj = { ...this.dummy };
				this.displayErrorMessageOfDocumentName = false

				this.displayErrorMessage = false
				// if(this.dummy.documentUrl[0].url){
				// 	let docUrl = this.dummy.documentUrl[0].url;
				// 	let fileName = docUrl.split('/').pop();

				// 	let fileExtension = fileName.split('.').pop();
				// 	// let id=uuidv4();
				// 	this.dummy.file = `${this.dummy.documentId}-${this.dummy.documentName}.${fileExtension}`;
				// 	this.newDocumentObj.file = this.dummy.file;
				// }
			} else if (status == 'add') {
				delete this.newDocumentObj._id;
				delete this.dummy._id;
				Object.keys(this.newDocumentObj).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.newDocumentObj[prop]) ? (this.newDocumentObj[prop] = []) : (this.newDocumentObj[prop] = '');
					}
				});
				Object.keys(this.dummy).forEach((prop) => {
					if (prop != 'documentId') {
						Array.isArray(this.dummy[prop]) ? (this.dummy[prop] = []) : (this.dummy[prop] = '');
					}
				});
				this.displayErrorMessageOfDocumentName = false
			this.displayErrorMessage = false
				// this.dummy = {};
			}
		},
		CancelButtonPermissionPopUp(action: any) {
			if (action === 'close') {
				this.buttonStatus = false;
				this.dataSubmitted = false;
				this.finalSubmittedData1 = [];
				this.cancelFormPermissionPopUp();
				this.popupStatus.permissionsPopUp = false;
				// this.submittedData = [];
				// this.popupData = {};
				// (this.popupData.type = ''), (this.popupData.target_audience = ''), (this.popupData.entity = ''), (this.popupData.teams = ''), (this.popupData.users = '');
				 this.getAllRegions();
				 this.getAllEntities();
				this.finalJsonData = [];
			} else if (action == 'cancel') {
				// this.popupStatus.permissionsPopUp = false;
				this.finalJsonData = [...this.dummyFinalJson];
				this.buttonStatus = false
				// this.cancelFormPermissionPopUp();
				// this.submittedData = [];
			} else {
				this.popupData = {};
				(this.popupData.type = ''), (this.popupData.target_audience = ''), (this.popupData.entity = ''), (this.popupData.teams = ''), (this.popupData.users = '');
				// this.submittedData = {};
				this.dataSubmitted = false;
			}
		},
		resetBtn() {
			this.submittedData = [];
			this.popupData = { ...this.dummy };
			this.v$.$reset;
		},
		closeArchivePopUp() {
			this.archiveData = [];
			this.archiveDocumentComment = '';
			this.popupStatus.archivePopUp = false;
			this.dynamicPopUp = '';
			this.displayErrorMessage = false
		},
		resetComments() {
			this.archiveDocumentComment = '';
			this.displayErrorMessage = false
		},
		async saveComments() {
		console.log("this.v$.$touch()2",this.v$.archiveDocumentComment.maxLength.$invalid);
		// console.log("this.v$.$touch()3",this.v$.archiveDocumentComment.required.$invalid);
			this.v$.$touch();
			if (!this.v$.archiveDocumentComment.$invalid && this.displayErrorMessage == false ) {
			
				this.clickOnButton = true;
				const comment = this.archiveDocumentComment;
				let commentObj = {
					archiveComments: comment
				};
				let docId = this.archiveData[0]._id;
				try{
					await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/archive`, commentObj).then(async (res: any) => {
						toast.info(`Saved succesfully`, {
							closeOnClick: true,
							closeButton: 'button'
						});
						await this.getAllDocumentsList();
					});
				} catch(err){
					toast.error(`Error`, {
						closeOnClick: true,
						closeButton: 'button'
					});
				} finally {
					this.clickOnButton = false;
				}
				
				this.archiveDocumentComment = '';
				this.popupStatus.archivePopUp = false;
			} else {
				console.log('enter comments');
			}
			this.v$.$reset();
		},

		importDoc() {
			this.$refs.file.click();
		},
		async getAllDocumentsList() {
			this.dataLoading = true;
			this.allDocumentsData = [];
			await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/documents/getAll`).then((res: any) => {
				// this.allDocumentsData = res.data;
				res.data.forEach((eachDoc: any) => {
					if (this.userInfo.currentRole == 10207) {
						this.allDocumentsData.push(eachDoc);
					} else if (this.userInfo.currentRole == 10208 && eachDoc.author == this.userInfo._id) {
						this.allDocumentsData.push(eachDoc);
					}
				});
				this.foldersDataArray = [
					...new Set(
						Object.values(res.data).map((x: any) => {
							x["folder"];
							return x["folder"];
						})
					)
				]
				// this.$store.dispatch('updateDotsLoading', false);
				this.dataLoading = false;
			});
			for(let i = 0; i < this.allDocumentsData.length ;i++){
				this.allDocumentsIds.push(this.allDocumentsData[i].documentId)
				this.allDocumentsNames.push(this.allDocumentsData[i].documentName)
			}
		},
		groupBy(arr: any[], key: string | number) {
			return arr.reduce((group: any, product: any) => {
				const { folder } = product;
				group[folder] = group[folder] ?? [];
				group[folder].push(product);
				return group;
			}, {});
		},
		async getallTeamMembers() {
			this.allUserArray = [];
			this.optionsUserArray = [];
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
				this.orgUsersList = this.orgUsersList.filter((user: any) => {
					return user.isActive == true;
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
					let obj: any = { label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id ,entity: this.orgUsersList[i].entity};
					// this.allTeamMembers.push(obj);
					if (this.orgUsersList[i]._id !== this.userInfo._id) {
						this.optionsUserArray.push(obj);
					}
					this.allUserArray.push(obj);
				}
			});
		},
		async uploadEncodedDataFormat(data: any) {
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		async uploadToS3AndGetLink(file: any) {
			const bufferData = await this.uploadEncodedDataFormat(file);
			let fileExtension = file.name.split('.').pop();
			// let id=uuidv4();


			let payload = { fileData: bufferData, name: `${this.newDocumentObj.documentId}-${this.newDocumentObj.documentName}.${fileExtension}`, folderName: this.newDocumentObj.folder, fileType: file.type };
			try {
				await this.$http.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/test/file/uploading`, payload).then((response: any) => {
					if (response.status == 200) {
						this.uploadedDocumentUrl = response.data;
					} else {
						console.log('response status', response.status);
					}
				});
			} catch (err) {
				console.error(err);
			}
			return this.uploadedDocumentUrl;
		},
		async saveButtonAddPopUp() {
			this.v$.$touch();
			if (!this.v$.newDocumentObj.$invalid && this.checkDocumentId == false && this.checkDocumentName == false && this.displayErrorMessage == false && this.displayErrorMessageOfDocumentName == false) {
				this.clickOnButton = true;
				let copyOfnewDocumentObj:any = {...this.newDocumentObj}
				this.dataLoading = true
				// this.$store.dispatch('updateDotsLoading', true);
				let file = this.$refs.fileInput.files[0];
				if(file){
					let camedocumentUrl = await this.uploadToS3AndGetLink(file);
					copyOfnewDocumentObj.documentUrl = [
						{
							url: camedocumentUrl.url,
							docVersion: copyOfnewDocumentObj.version,
							versionId: camedocumentUrl.versionId,
							createdBy: this.userInfo._id,
							createdAt: new Date(),
							statusCode: 10411
						}
					];
				} else {
					copyOfnewDocumentObj.documentUrl = [
						{
							docVersion: copyOfnewDocumentObj.version,
							createdBy: this.userInfo._id,
							createdAt: new Date(),
							statusCode: 10411
						}
					];
				}
				copyOfnewDocumentObj.statusCode = 10411;
				copyOfnewDocumentObj.createdBy = this.userInfo._id;
				copyOfnewDocumentObj.createdAt = new Date();
				copyOfnewDocumentObj.documentOwner = copyOfnewDocumentObj.documentOwner.value;
				copyOfnewDocumentObj.author = copyOfnewDocumentObj.author;
				// delete copyOfnewDocumentObj.file;
				try{
					await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/documents/create`, [_.omit(copyOfnewDocumentObj, 'file')]).then(async (res: any) => {
					toast.info(`Saved succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
					if (res.status == 200) {
						await this.getAllDocumentsList();
						this.dataLoading = false;
						// this.$store.dispatch('updateDotsLoading', false);
					} else {
						console.log('res status', res.status);
					}
					this.showPopUp = false;
				});
				} catch(err){
					toast.error(`Error`, {
						closeOnClick: true,
						closeButton: 'button'
					});
				} finally {
					this.clickOnButton = false;
				}
				
				// this.showPopUp = false;
				this.v$.$reset();
				Object.keys(this.newDocumentObj).forEach((prop) => {
					Array.isArray(this.newDocumentObj[prop]) ? (this.newDocumentObj[prop] = []) : (this.newDocumentObj[prop] = '');
				});
				this.dataLoading = false
			} else {
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async updateButtonEditPopUp(docObject: any) {
			this.clickOnButton = true;
			let docObj:any = {...docObject}
			try {
				let presentDocumentData = this.allDocumentsData.find((dataObj: any) => {
					return dataObj._id == docObj._id;
				});
				let docId = docObj._id;
				delete docObj._id;
				delete docObj.file;
				docObj.updatedAt = new Date();
				docObj.updatedBy = this.userInfo._id;
				docObj.documentOwner = docObj.documentOwner.value;
				docObj.author = docObj.author.value;

				docObj.statusCode = presentDocumentData.statusCode;
				if (this.fileChanged == true) {
					let file = this.$refs.fileInput.files[0];
					if(file){
						let camedocumentUrl = await this.uploadToS3AndGetLink(file);
						docObj.documentUrl = [
							...docObj.documentUrl,
							{
								url: camedocumentUrl.url,
								docVersion: this.newDocumentObj.version,
								versionId: camedocumentUrl.versionId,
								updatedAt: new Date(),
								updatedBy: this.userInfo._id,
								statusCode: 10411
							}
						];
						docObj.isFileChanged = 1;
						docObj.statusCode = 10411;
						// this.$store.dispatch('updateDotsLoading', true);
						// this.dataLoading = true
					}
				}
				if(docObj.documentUrl && docObj.documentUrl.length ==0){
					delete docObj.documentUrl
				}
				try{
					await this.$http.post(`${process.env.VUE_APP_DMS_API_URL}/api/org/document/${docId}/update`, docObj).then((result: any) => {
						toast.info(`Uploaded succesfully`, {
							closeOnClick: true,
							closeButton: 'button'
						});
						this.fileChanged = false;
					});
				} catch(err){
					toast.error(`Error`, {
						closeOnClick: true,
						closeButton: 'button'
					});
				} finally {
					this.clickOnButton = false;
				}
				
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButton = false;
			}
			this.showPopUp = false;
			await this.getAllDocumentsList();
			this.dataLoading = false;
			// this.$store.dispatch('updateDotsLoading', false);
		},
		formAddPermissionPopUp() {
			console.log("this.popupData",this.popupData)
			this.buttonStatus = true;
			let type1 = this.popupData.type || '';
			let region1 = this.popupData.region || '';
			let entity_id = this.popupData.entity || '';
			let region_id = this.popupData.region || '';
			let target_audience1 = this.popupData.target_audience || '';
			let teams1 = this.popupData.teams || '';
			let users1 = this.popupData.users || '';
			console.log("entity_id",entity_id)
			console.log("region_id",region_id)
			// this.entitesDataArray.find((data: any) => {
			// 	if (data.entityName == this.popupData.entity) {
			// 		entity_id = data._id;
			// 	}
			// });
			// this.regionDataArray.find((data: any) => {
			// 	if (data.region == this.popupData.region) {
			// 		region_id = data._id;
			// 	}
			// });
			// let entity1 = this.popupData.entity || '';

			this.v$.$touch();
			
			if (!this.v$.popupData.$invalid) {
				console.log("this.popupData.type",this.popupData.type)
				if (this.popupData.type == 1) {
					let existingGlobalIndex = this.finalJsonData.findIndex((data: any) => data.type === type1 && data.target_audience === target_audience1);
					if (existingGlobalIndex !== -1) {
						if (this.finalJsonData[existingGlobalIndex].teams) {
							if (!this.finalJsonData[existingGlobalIndex].teams.includes(teams1) && users1 == '') {
								this.finalJsonData[existingGlobalIndex].teams.push(teams1);
								// this.finalJsonData[existingGlobalIndex].users = [];
							}
						}
						if (this.finalJsonData[existingGlobalIndex].users) {
							if (!this.finalJsonData[existingGlobalIndex].users.includes(users1) && teams1 == '') {
								this.finalJsonData[existingGlobalIndex].users.push(users1);
								// this.finalJsonData[existingGlobalIndex].teams = [];
							}
						}
						if (this.finalJsonData[existingGlobalIndex].target_audience == 3) {
							this.entitesDataArray.find((data: any) => {
								if (data._id == entity_id) {
									region1 = data.region;
								}
							});
							let entity = {
								type: type1,
								target_audience: target_audience1
							};
							this.finalJsonData.push(entity);
						}
					} else {
						if (users1 == '' && teams1 != '') {
							let global = {
								type: type1,
								target_audience: target_audience1,
								teams: [teams1]
							};
							this.finalJsonData.push(global);
							this.global = {};
						} else if (teams1 == '' && users1 != '') {
							let global = {
								type: type1,
								target_audience: target_audience1,
								users: [users1]
							};
							this.finalJsonData.push(global);
							this.global = {};
						} else if (teams1 == '' && users1 == '') {
							let global = {
								type: type1,
								target_audience: target_audience1
							};
							this.finalJsonData.push(global);
							this.global = {};
						}
					}
				}

				if (this.popupData.type == 2) {
					// console.log("this.finalJsonDatathis.finalJsonDatathis.finalJsonData",this.finalJsonData)
					// (data.entity.value === entity_id.value || data.region.value === region_id.value)
					let existingGlobalIndex : any
					// if(Object.keys(this.finalJsonData).includes('entity')){
						// console.log("(data.entity.value === entity_id.value || data.region.value === region_id.value)",(this.finalJsonData.entity.value === entity_id.value || this.finalJsonData.region.value === region_id.value))
						existingGlobalIndex = this.finalJsonData.findIndex((data: any) => data.type === type1 && data.target_audience === target_audience1 && data.entity.value === entity_id.value && data.region.value === region_id.value);
					// } else {
					// 	console.log("intoo else")
					// 	existingGlobalIndex = this.finalJsonData.findIndex((data: any) => data.type === type1 && data.target_audience === target_audience1);
					// }
					
					console.log("existingGlobalIndex",existingGlobalIndex)
					console.log("this.finalJsonData",this.finalJsonData)
					if (existingGlobalIndex !== -1) {
						if (this.finalJsonData[existingGlobalIndex].teams) {
							if (!this.finalJsonData[existingGlobalIndex].teams.includes(teams1.value) && users1 == '') {
								this.finalJsonData[existingGlobalIndex].teams.push(teams1);
								// this.finalJsonData[existingGlobalIndex].users = [];
							}
						}
						if (this.finalJsonData[existingGlobalIndex].users) {
							if (!this.finalJsonData[existingGlobalIndex].users.includes(users1) && teams1 == '') {
								this.finalJsonData[existingGlobalIndex].users.push(users1);
								// this.finalJsonData[existingGlobalIndex].teams = [];
							}
						}
						if (this.finalJsonData[existingGlobalIndex].target_audience == 3) {
							this.entitesDataArray.find((data: any) => {
								if (data._id == entity_id) {
									region1 = data.region;
								}
							});
							let entity = {
								type: type1,
								region: region1,
								entity: entity_id,
								target_audience: target_audience1
							};
							this.finalJsonData.push(entity);
						}
					} else {
						
						if (users1 === '' && teams1 != '') {
							let region = {
								type: type1,
								region: region_id,
								entity: entity_id,
								target_audience: target_audience1,
								teams: [teams1]
							};
							this.finalJsonData.push(region);
						} else if (users1 != '' && teams1 == '') {
							let region = {
								type: type1,
								region: region_id,
								entity: entity_id,
								target_audience: target_audience1,

								users: [users1]
							};
							console.log("regionregionregionregion",region)
							this.finalJsonData.push(region);
						} else if (users1 == '' && teams1 == '') {
							let region = {
								type: type1,
								region: region_id,
								entity: entity_id,
								target_audience: target_audience1
							};
							this.finalJsonData.push(region);
						}
					}
				}

				if (this.popupData.type == 3) {
					let existingGlobalIndex = this.finalJsonData.findIndex((data: any) => data.type === type1 && data.target_audience === target_audience1 && data.entity.value === entity_id.value);
					console.log("existingGlobalIndex",existingGlobalIndex)
					if (existingGlobalIndex !== -1) {
						
						if (this.finalJsonData[existingGlobalIndex].teams) {
							if (!this.finalJsonData[existingGlobalIndex].teams.includes(teams1) && users1 == '') {
								this.finalJsonData[existingGlobalIndex].teams.push(teams1);
								// this.finalJsonData[existingGlobalIndex].users = [];
							}
						}
						if (this.finalJsonData[existingGlobalIndex].users) {
							if (!this.finalJsonData[existingGlobalIndex].users.includes(users1) && teams1 == '') {
								this.finalJsonData[existingGlobalIndex].users.push(users1);
								// this.finalJsonData[existingGlobalIndex].teams = [];
							}
						}
						if (this.finalJsonData[existingGlobalIndex].target_audience == 3) {
							this.entitesDataArray.find((data: any) => {
								if (data._id == entity_id) {
									region1 = data.region;
								}
							});
							let entity = {
								type: type1,
								region: region1,
								entity: entity_id,
								target_audience: target_audience1
							};
							this.finalJsonData.push(entity);
						}
					} else {
						if (users1 === '' && teams1 != '') {
							this.entitesDataArray.find((data: any) => {
								if (data._id == entity_id) {
									region1 = data.region;
								}
							});
							let entity = {
								type: type1,
								region: region1,
								entity: entity_id,
								target_audience: target_audience1,
								teams: [teams1]
							};
							this.finalJsonData.push(entity);
						} else if (teams1 === '' && users1 != '') {
							this.entitesDataArray.find((data: any) => {
								if (data._id == entity_id) {
									region1 = data.region;
								}
							});
							let entity = {
								type: type1,
								region: region1,
								entity: entity_id,
								target_audience: target_audience1,

								users: [users1]
							};
							this.finalJsonData.push(entity);
						} else if (teams1 == '' && users1 == '') {
							this.entitesDataArray.find((data: any) => {
								if (data._id == entity_id) {
									region1 = data.region;
								}
							});
							let entity = {
								type: type1,
								region: region1,
								entity: entity_id,
								target_audience: target_audience1
							};
							this.finalJsonData.push(entity);
						}
					}
				}

				this.finalJsonData.map((data: any) => {
					console.log("data",data)
					if (Object.keys(data).includes('entity')) {
						console.log("this.entitesDataArray",this.entitesDataArray)
						this.entitiyArray.forEach((eachEntity: any) => {
							if (eachEntity.value === data.entity) {
								data.entity = eachEntity;
							}
						});
					}
					if (Object.keys(data).includes('region')) {
						console.log("this.regionDataArray",this.regionDataArray)
						this.regionsArray.forEach((eachregion: any) => {
							if (eachregion.value === data.region) {
								data.region = eachregion;
							}
						});
					}
					if (Object.keys(data).includes('teams')) {

						this.teamsDataArray.forEach((eachTeam: any) => {
							if (eachTeam._id === data.teams[0]) {
								data.teams = [];
								data.teams.push(eachTeam.teamName);
							}
						});
					}
					if (Object.keys(data).includes('users')) {
						this.orgUsersList.forEach((eachUser: any) => {
							if (eachUser._id == data.users[0]) {
								data.users = [];
								data.users.push(eachUser.name);
							}
						});
					}
				});
console.log("this.finalJsonDatalkasj1",this.finalJsonData)
				
				this.dataSubmitted = true;
				this.popupData = {
					type: '',
					target_audience: '',
					region: '',
					entity: '',
					teams: '',
					users: ''
				};
				this.popupData_dummy = {
					type: '',
					target_audience: '',
					region: '',
					entity: '',
					teams: '',
					users: ''
				};

				this.v$.$reset();
				this.dataAdded = true
			} else {
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		}
	}
});
</script>


<style scoped>
.taskStatusOptions{
  background-color: white;
  color: rgba(77, 77, 77, var(--tw-text-opacity))
}
.taskStatusOptionsForNotStarted{
  background-color: #e9e9e9;
  color: rgba(77, 77, 77, var(--tw-text-opacity))
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.multiselect__tags {
	border: 0px !important;
}
.placeholder-styles {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
}
.uploadedFileNameStyles{
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	color: #4D4D4D;
}
.btnprimary1 {
	cursor: pointer;
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-family: Open Sans;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.placeholder-styles {
	padding-top: 5px;
	padding-left: 0.5rem;
	font-size: 14px !important;
	color: #9ca3af;
}
.label {
	font-size: 12px !important;
}
.controllabel {
	font-size: 12px !important;
	font-weight: 600 !important;
}
</style>

