import archiveDocuments from '@/features/archiveDocuments.vue';
import dashboard from '@/features/dashboard.vue';
import documentsList from '@/features/documentsList.vue';
import editor from '@/features/editor.vue';
import fileslist from '@/features/fileslist.vue';
import historyPage from '@/features/historyPage.vue';
import myDocuments from '@/features/myDocuments.vue';
import defaultLayout from "@/layouts/defaultLayout.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import documents from "@/features/documents.vue"
import store from '@/store';
import approvals from '@/features/approvals.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'default',
        meta: {
            title: "myDocuments",
            layout: defaultLayout,
        },
        redirect: { name: 'myDocuments' }
    },
    {
        path: '/admin',
        name: 'admin',
        meta: {
            title: "myDocuments",
            layout: defaultLayout,
        },
        redirect: { name: 'myDocuments' }
    },
    {
        path: '/history',
        name: 'historyPage',
        meta: {
            title: "history",
            layout: defaultLayout,
        },
        component: historyPage
    },
    {
        path: '/documents',
        name: 'documents',
        meta: {
            title: "documents",
            layout: defaultLayout,
        },
        component: documents
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        component: dashboard
    }, {
        path: "/documentslist",
        name: "documentslist",
        meta: {
            title: "Documentslist",
            layout: defaultLayout
        },
        component: documentsList,
    }, {
        path: "/fileslist",
        name: "fileslist",
        meta: {
            title: "fileslist",
            layout: defaultLayout
        },
        component: fileslist,
    },
    {
        path: "/archivedocuments",
        name: "archivedocuments",
        meta: {
            title: "Archivedocuments",
            layout: defaultLayout
        },
        component: archiveDocuments,
    },
    {
        path: "/myDocuments",
        name: "myDocuments",
        meta: {
            title: "myDocuments",
            layout: defaultLayout
        },
        component: myDocuments,
    },
    {
        path: '/document/:id/edit',
        name: 'editor',
        meta: {
            title: "editor",
            layout: defaultLayout,
        },
        component: editor
    },
    {
        path: '/approvals',
        name: 'approvals',
        meta: {
            title: "approvals",
            layout: defaultLayout,
        },
       component:approvals
    },
    {
        path: "/editor/document/:documentId/edit/:type",
        name: "editor",
        meta:{
            title: "editor",
            layout:defaultLayout
        },
        component: editor
    },

]
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to, from, next) => {
    store.state.isLoading = true;
    if(to.meta.title){
        document.title=`DMS - ${to.meta.title}`
    }
    else{
        document.title=`DMS`
    }
    console.log('Router check triggered', to)
    if (to.path == '/myDocuments' || to.path == '/admin' || to.path == '/') {
        console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_DMS_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        await normalAuthenticationCheck(next);
    } else {
        await normalAuthenticationCheck(next);
    }
    // next();
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if(!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_DMS_WEBAPP_URL}`;
    } else{
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if(!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else{
            next();
        }
    }
}



export default router;