<template>
  <div class="bg-white">
    <div v-if="displayErrorMessage == true">
      <div class="text-red-500 text-xs">Character Limit is exceeded</div>
    </div>
    <div :class="this.$route.path.includes('myDocuments') || this.$route.path.includes('/edit/approvals') ? 'simpleCommentBox' : ''" @input ="updateContent" @paste="updateContent" ref="editorContainer"></div>
    <div v-if="this.$route.path.includes('myDocuments') || this.$route.path.includes('/edit/approvals')">
      <div class="block2 flex justify-end p-2">
        <img src="@/assets/Send.svg" style="width: 20px" class="w-4 h-4 mb-0.5 mr-2.5s" :class="this.content.trim() === '<p><br></p>' || this.content.replace(/<p>\s*<\/p>/g, '').trim() === '' ? 'cursor-not-allowed disabled' : 'cursor-pointer'" @click="addComment" />
      </div>
    </div>
    <div v-else class="flex justify-end mt-3">
      <button @click="addComment" class="mr-2" :class="disableStatus() ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
      <button @click="clearComment" :class="disableStatus() ? 'btnprimary' : 'btndisabled pointer-events-none'">Cancel</button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { emitter } from '@/main';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default defineComponent({
  name: 'QuillEditor',
  data() {
    return {
      quill: null,
      content: '<p><br></p>',
      dummyContent: '<p><br></p>',
    };
  },
  mounted() {
    const isEditable = !(this.$route.path.includes('myDocuments') || this.$route.path.includes('/edit/approvals'));
    let quillOptions
    if (isEditable) {
    quillOptions = {
      theme: 'snow',
      placeholder: 'Compose an epic...',
    };
    console.log("isEditable", isEditable)
    
      quillOptions.modules = {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
      };
    } else {
      quillOptions = {
      placeholder: 'Add Comments...',
    };
    }

    let quillShortEditor = new Quill(this.$refs.editorContainer, quillOptions);
    this.quill = quillShortEditor;
    this.quill.root.innerHTML = this.content;
    this.dummyContent = this.content;
    
    this.quill.on('text-change', () => {
      this.content = this.quill.root.innerHTML;
      this.$emit('input', this.content);
    });
  },
  watch: {
    value(val) {
      if (val !== this.quill.root.innerHTML) {
        this.quill.root.innerHTML = val;
      }
    },
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    displayErrorMessage() {
        // Check if content exceeds character limit
        return this.content.length > 520;
    }
},
  methods: {
    updateContent(event) {
        // Capture the content from the editor container
        const rawContent = this.$refs.editorContainer.innerHTML;

        // Clean-up: Remove empty paragraphs and trim unnecessary spaces
        this.content = rawContent.replace(/<p>\s*<\/p>/g, '').trim();
    },
    disableStatus() {
      return this.content !== this.dummyContent;
    },
    addComment() {
      if(this.displayErrorMessage == false){
        if (this.content.trim() === '<p><br></p>' || this.content.replace(/<p>\s*<\/p>/g, '').trim() === '') {
          console.log('Cannot add empty comment');
          return;
        }
        let commentUpdate = {
          comments: this.content,
        };
        emitter.emit('commentUpdate', commentUpdate);
        this.clearComment();
      }
      
    },
    clearComment() {
      this.content = '<p><br></p>';
      this.dummyContent = '<p><br></p>';
      this.quill.root.innerHTML = this.content;
    },
  },
});
</script>

<style scoped>
/* Add any custom styles for your editor here */
.simpleCommentBox {
  border-top: 1px solid rgba(164,164,164,0.3);
  /* border: 1px solid rgba(164,164,164,0.3); */
  height: 100px;
}
</style>
