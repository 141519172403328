<template>
	<div class="container mx-auto px-4">
		<div class="w-4/5">
			<div class="card mt-4 m-4">
				<div class="mx-2.5 mb-6">
					<div class="mt-5 mx-5 pt-5 flex flex-row borderBottom overflow-hidden justify-between cursor-pointer font-semibold">
						<div class="pb-1 adjust" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
							<div>
								<div ref="actualIndex">{{ tab.displayName }}</div>
								<div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex justify-center">{{ selectedTabObj.content }}</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	data(): any {
		return {
			tabHead: [
				{ displayName: 'Document', name: 'document', id: 1, content: 'content of Tab 1' },

				{ displayName: 'Files', name: 'files', id: 2, content: 'content of Tab 2' },

				{ displayName: 'Approvals', name: 'approvals', id: 3, content: 'content of Tab 3' },

				{ displayName: 'Work History', name: 'workhistory', id: 4, content: 'content of Tab 4' }
			],
			selectedTabIndex: 1,
			selectedTabObj: { displayName: 'Document', name: 'document', id: 1, content: 'content of Tab 1' }
		};
	},
	methods: {
		toggleTabs(obj: any) {
			this.selectedTabIndex = obj.id;
			this.selectedTabObj = obj;
		}
	}
});
</script>
<style scoped>
</style>
